import Close from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import startSmiley from '../../../assets/images/star-smiley.png'
import { updateCustomerStartMeetingResponse } from '../../../utils'

const CustomerMeetingConfirmationModal = ({ customerMeetConfirmModal, setModalAction, job, socket, startBilledMeeting, user }) => {
	const [countdown, setCountdown] = useState(30)

	const continueWithMeeting = () => {
		setModalAction('resetAll')
		startBilledMeeting(false)
		let data = { meetingStatus: 'meetingHasStarted', jobId: job.id }
		socket.emit('meeting-started-by-customer', data)
		updateCustomerStartMeetingResponse(job.id, 'Start Billed Meeting', user.id)
	}

	const handleClose = () => {
		setModalAction('resetAll')
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setModalAction('resetAll')
			updateCustomerStartMeetingResponse(job.id, 'automatically Start Billed Meeting after 30 seconds', user.id)
		}, 30000)

		const countdownIntervalId = setInterval(() => {
			setCountdown((prevCountdown) => prevCountdown - 1)
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
			clearInterval(countdownIntervalId)
		}
	}, [])

	return (
		<>
			<Modal visible={customerMeetConfirmModal} width={800} closable={false} footer={null} className={'customer-meeting-confirmation-modal'}>
				{/* <div className="cross-btn-div">
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</div> */}
				<div className="customer-meeting-confirmation-modal-heading">
					<img src={startSmiley} className="customer-meeting-confirmation-modal-image" />
					<span className="customer-meeting-confirmation-modal-heading-text">Let's Solve This!</span>
				</div>
				<div className="text-center">
					<span className="customer-meeting-confirmation-modal-text-span">
						Your Geek is experienced with jobs like yours and may be able to help! Your paid meeting will now begin. Enjoy a wonderful
						Geeker experience!
					</span>
				</div>
				<div className="text-center">
					<span className="customer-meeting-confirmation-modal-text-span">
						<i>*By continuing, you understand you're paying for your Geek's time, and a solution can't be guaranteed.</i>
					</span>
				</div>
				<Button variant="contained" className="customer-meeting-confirmation-modal-button" onClick={continueWithMeeting}>
					<span>Continue ({countdown})</span>
				</Button>
			</Modal>
		</>
	)
}

export default CustomerMeetingConfirmationModal
