import React from 'react'
import TechTutorial from '../Documents/Techtutorial'
import { Modal } from 'antd'
const TechnicianHowToInformation = (props) => {
	return (
		<>
			<Modal
				title={'How to '}
				visible={props.showTut}
				maskClosable={true}
				width={'fit-content'}
				onCancel={props.handleShowTut}
				style={{
					height: '300px',
					overFlowY: 'scroll ',
				}}
				footer={false}
				className="scroll-height"
				wrapClassName=" col-xs-12 col-md-8 col-lg-6  mx-auto"
			>
				<TechTutorial />
			</Modal>
		</>
	)
}

export default TechnicianHowToInformation
