import apiClient from './index'

export async function create(data) {
	// body...
	return apiClient.post('/notification', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

export async function findNotificationByParams(data) {
	return apiClient.post('/notification/notify', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

export async function updateByParams(data) {
	return apiClient.post('/notification/update', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
