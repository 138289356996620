import { Tooltip } from 'react-bootstrap'
import React from 'react'
export const getTooltipComponent = (message, showMessage) => {
	return showMessage ? (
		<Tooltip id="tooltip" className="toop-tip-design">
			<>
				<p className="body-tooltip-style mb-2">{message}</p>
			</>
		</Tooltip>
	) : (
		<></>
	)
}
