import Button from '@mui/material/Button'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { updateJob } from '../../../api/meeting.api'
import maybeImg from '../../../assets/images/maybe.png'
import noImg from '../../../assets/images/no.png'
import timespace from '../../../assets/images/timespace.png'
import yesImg from '../../../assets/images/yes.png'
import { useJitsiMeet } from '../../../context/jitsiMeetContext'
import './MeetingConfirmationModal.css'

function MeetingConfirmationModal(props) {
	const [selectedButton, setSelectedButton] = useState(null)
	const { setOpenBackDrop, handleBackDropChange, isBackDropOpen } = useJitsiMeet()

	const messageYes = "Tell the customer you can solve their issue and how long you think it'll take. Start the job."
	const messageNo = 'Tell the customer why you cannot help. If a transfer is necessary, explain the transfer. Otherwise, nicely end the call.'
	const messageNoMayBe = "Tell the customer you're not sure if you can solve this. Ask if they want to end session or continue."

	const handleContinueMeeting = async () => {
		// console.log('selectedOpt', selectedButton)
		props.socket.emit('tech-response-for-helping-customer', { selectedOpt: selectedButton, jobId: props.job.id })
		let dataToUpdate = {
			tech_solved_issue: selectedButton,
		}
		await updateJob(props.job.id, {
			dataToUpdate: dataToUpdate,
			cuId: props?.user?.id,
		})
		if (selectedButton == 'Yes' || selectedButton == 'mayBe') {
			props.setMeetingStartedByTech(true)
		}
		props.setShowFiveMinModal(false)
		// setOpenBackDrop(true)
		handleBackDropChange('jobId', props.job.id)
		handleBackDropChange('isBackdropVisible', true)
	}

	const handelYes = () => {
		setSelectedButton('Yes')
	}
	const handelNo = () => {
		setSelectedButton('No')
	}
	const handelMaybe = () => {
		setSelectedButton('mayBe')
	}

	return (
		<Modal
			title=""
			zIndex={10}
			visible={props.showFiveMinModal}
			// width={1108}
			style={{ justifyContent: 'center', display: 'flex' }}
			closable={false}
			footer={null}
			className="main-div-modal-five-minute"
		>
			<div className="modal-five-minute">
				<span className="modal-five-minute-text-upper nova-font">Are you able to assist the customer with their issue?</span>
				<span className="modal-five-minute-text-lower nova-font">Select an option for further instructions.</span>
			</div>
			<div className="modal-five-minute-btns">
				<Button variant="outlined" onClick={handelYes} className={`five-minute-btn ${selectedButton === 'Yes' ? 'cyan-border' : ''}`}>
					<span className="five-minute-btn-text">
						<span className="five-minute-btn-text-two">
							<img src={yesImg} alt="some caption" />
							<span className="nova-font span-text">Yes</span>
						</span>
					</span>
				</Button>
				<Button variant="outlined" onClick={handelMaybe} className={`five-minute-btn ${selectedButton === 'mayBe' ? 'cyan-border' : ''}`}>
					<span className="five-minute-btn-text">
						<span className="five-minute-btn-text-two">
							<img src={maybeImg} alt="some caption" />
							<span className="nova-font span-text">Confirm with customer</span>
						</span>
					</span>
				</Button>
				<Button variant="outlined" onClick={handelNo} className={`five-minute-btn ${selectedButton === 'No' ? 'cyan-border' : ''}`}>
					<span className="five-minute-btn-text">
						<span className="five-minute-btn-text-two">
							<img src={noImg} alt="some caption" />
							<span className="nova-font span-text">No</span>
						</span>
					</span>
				</Button>
			</div>
			{selectedButton !== null && (
				<div className="action-btn-five-minute">
					<span className="action-btn-five-minute-span nova-font">
						{selectedButton === 'Yes'
							? messageYes
							: selectedButton === 'mayBe'
							? messageNoMayBe
							: selectedButton === 'No'
							? messageNo
							: messageYes}
					</span>

					<Button variant="outlined" className="action-btn-five-minute-btn" onClick={handleContinueMeeting}>
						<span className="action-btn-five-minute-btn-text nova-font">
							{selectedButton === 'Yes'
								? 'Continue Session'
								: selectedButton === 'mayBe'
								? 'Continue Session'
								: selectedButton === 'No'
								? 'Transfer Session'
								: 'Continue Session'}
						</span>
					</Button>
				</div>
			)}
			{props.secModalIsVisible ? (
				<div className="close-sec-modal-div">
					<span onClick={props.handleCloseMeeting} className="close-sec-modal">
						I need more time to decide
					</span>
				</div>
			) : (
				<div className="close-sec-modal-div-two">
					<img src={timespace} />
					<span className="close-sec-modal">5 minute assessment has expired</span>
				</div>
			)}
		</Modal>
	)
}

export default MeetingConfirmationModal
