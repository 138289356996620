import React, { useEffect, useState } from 'react'
import './meeting-onboarding-styles.css'
import SuccessCheckMark from '../../assets/images/SuccessCheckBox.svg'
import FailureCheckMark from '../../assets/images/FailureCheckBox.svg'
import ConnectionIcon from '../../assets/images/connectionIcon.svg'
import { MEETING_ONBOARD_MSG } from '../../constants'
import RocketLaunch from '../../assets/animation/RocketLaunch.json'
import { Player } from '@lottiefiles/react-lottie-player'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import { Button, Spin } from 'antd'
const MeetingOnboarding = ({ user, userType, connectionStatus, job, setStartChat }) => {
	const [onBoardingLoadStep, setOnBoardingLoadStep] = useState({
		rocketLaunch: 'loading',
		introduction: 'toLoad',
		speakerConnection: 'toLoad',
		microphoneConnection: 'toLoad',
		internetConnection: 'toLoad',
		connectionOptions: 'toLoad',
		meetingStartWithPhone: 'toLoad',
	})

	useEffect(() => {
		setTimeout(() => {
			setOnBoardingLoadStep((prev) => ({ ...prev, rocketLaunch: 'loading' }))
			setTimeout(() => {
				if (job?.callStartType == 'Phone' && userType == 'customer') {
					setOnBoardingLoadStep((prev) => ({
						...prev,
						meetingStartWithPhone: 'loading',
						rocketLaunch: 'loaded',
						connectionOptions: 'going-to-load',
					}))
					setTimeout(() => {
						setOnBoardingLoadStep((prev) => ({ ...prev, connectionOptions: 'loading' }))
					}, 3000)
				} else {
					setOnBoardingLoadStep((prev) => ({
						...prev,
						introduction: 'loading',
						rocketLaunch: 'loaded',
						speakerConnection: 'going-to-load',
					}))
					setTimeout(() => {
						setOnBoardingLoadStep((prev) => ({ ...prev, speakerConnection: 'loading', microphoneConnection: 'going-to-load' }))
						setTimeout(() => {
							setOnBoardingLoadStep((prev) => ({ ...prev, microphoneConnection: 'loading', internetConnection: 'going-to-load' }))
							setTimeout(() => {
								setOnBoardingLoadStep((prev) => ({ ...prev, internetConnection: 'loading', connectionOptions: 'going-to-load' }))
								setTimeout(() => {
									setOnBoardingLoadStep((prev) => ({ ...prev, connectionOptions: 'loading' }))
								}, 3000)
							}, 3000)
						}, 3000)
					}, 3000)
				}
			}, 3000)
		}, 1000)
	}, [])

	const chatWithGeek = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
		let randomString = (Math.random() + 1).toString(36).substring(7)
		setStartChat(randomString)
	}

	return (
		<React.Fragment>
			{onBoardingLoadStep.rocketLaunch == 'loading' && <RocketLaunchAnimation />}
			{job?.callStartType == 'Phone' && userType == 'customer' ? (
				onBoardingLoadStep.meetingStartWithPhone == 'loading' && <MeetingStarttedThroughCall />
			) : (
				<>
					{onBoardingLoadStep.introduction == 'loading' && <OnboardingHeading job={job} userType={userType} />}
					<div className="system-verification-style mt-5">
						{onBoardingLoadStep.speakerConnection == 'loading' ? (
							<SystemDetailsVerification systemVerificationData={connectionStatus.speaker} />
						) : onBoardingLoadStep.speakerConnection == 'going-to-load' ? (
							<Spin />
						) : null}
						{onBoardingLoadStep.microphoneConnection == 'loading' ? (
							<SystemDetailsVerification systemVerificationData={connectionStatus.microphone} />
						) : onBoardingLoadStep.microphoneConnection == 'going-to-load' ? (
							<Spin />
						) : null}
						{onBoardingLoadStep.internetConnection == 'loading' ? (
							<SystemDetailsVerification systemVerificationData={connectionStatus.internet} />
						) : onBoardingLoadStep.internetConnection == 'going-to-load' ? (
							<Spin />
						) : null}
					</div>
				</>
			)}
			<div className="system-verification-style mt-4" style={{ fontSize: '1rem', width: '24rem' }}>
				{onBoardingLoadStep.connectionOptions == 'loading' ? (
					<ConnectionOption chatWithGeek={chatWithGeek} userType={userType} />
				) : onBoardingLoadStep.connectionOptions == 'going-to-load' ? (
					<Spin />
				) : null}
			</div>
		</React.Fragment>
	)
}

const RocketLaunchAnimation = () => {
	return <Player autoplay keepLastFrame={true} src={RocketLaunch}></Player>
}

/**
 *
 * @param { user, userType }
 * @returns memoized component
 * @description : This Child component will show onBoarding Heading Messages
 * @author :  Jagroop
 */
const OnboardingHeading = React.memo(({ job, userType }) => {
	const userName = () => {
		if (userType == 'customer') {
			const fullName = job?.technician?.user?.firstName + ' ' + job?.technician?.user?.lastName
			return fullName
		}
		if (userType == 'technician') {
			const fullName = job?.customer?.user?.firstName + ' ' + job?.customer?.user?.lastName
			return fullName
		}
		return userType === 'technician' ? 'Customer' : 'Technician'
	}

	return (
		<>
			<div className="meeting-onboarding-head-style">
				<p>{MEETING_ONBOARD_MSG.TAKEOFF}</p>
				<p>{userName() + MEETING_ONBOARD_MSG.ABOUT_TO_JOIN}</p>
			</div>
		</>
	)
})

/**
 *
 * @param {*} systemVerificationData
 * @returns memoized component
 * @description : This Child component will shows that system, microphone,internet connection verification
 * @author :  Jagroop
 */
const SystemDetailsVerification = React.memo(({ systemVerificationData }) => {
	const { verified, message, type } = systemVerificationData
	return (
		<div className="d-flex">
			{verified ? (
				<img alt={type} src={SuccessCheckMark} className="img-fluid" />
			) : (
				<img alt={type} src={FailureCheckMark} className="img-fluid" />
			)}
			<p className="text-start m-1 p-0 system-verification-style">{message}</p>
			{type == 'connection' ? (
				<span className="m-0">
					<img alt={type} src={ConnectionIcon} className="img-fluid" />
				</span>
			) : (
				<></>
			)}
		</div>
	)
})

const MeetingStarttedThroughCall = () => {
	return (
		<>
			<div className="meeting-onboarding-head-style">
				<p>{MEETING_ONBOARD_MSG.GRAB_PHONE}</p>
				<p>{MEETING_ONBOARD_MSG.GEEKER_CALL + MEETING_ONBOARD_MSG.CONTACT_NUMBER}</p>
				<p className="meeting-onboarding-head-style-2">{MEETING_ONBOARD_MSG.ONE_MINUTE}</p>
			</div>
		</>
	)
}

const ConnectionOption = ({ chatWithGeek, userType }) => {
	return (
		<>
			<p className="m-1 p-0">{MEETING_ONBOARD_MSG.HELP}</p>
			<div className="connection-option-css">
				<Button className="m-2 chat-with-geek-btn-style" onClick={chatWithGeek}>
					<ChatBubbleOutlineIcon />
					{`Chat with ${userType == 'customer' ? 'Geek' : 'Customer'}`}
				</Button>
				<Button className="m-2 call-geek-btn-style" disabled={true} style={{ cursor: 'not-allowed', textDecoration: 'none' }}>
					<CallOutlinedIcon /> {`Call ${userType == 'customer' ? 'Geek' : 'Customer'}`}
				</Button>
			</div>
		</>
	)
}

export default React.memo(MeetingOnboarding)
