import React from 'react';
import { useVolume } from '../../context/VolumeProvider';
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const VolumeControl = () => {
  const { volume, setVolume, isMuted, toggleMute,updateVolume } = useVolume();

  const handleVolumeChange = async (e) => {
    const newVolume = parseFloat(e.target.value);
    console.log('Volume before adjustment:', newVolume);

    if (newVolume < 0.1) {
      setVolume(0.1);
      console.log('Volume adjusted to minimum threshold: 0.1');
      updateVolume(0.1, isMuted); // Update database with adjusted volume
      return;
    }
    setVolume(newVolume);
    console.log('Volume set to:', newVolume);
    if (isMuted && newVolume > 0) {
      toggleMute();
      console.log('Unmuted because volume is greater than 0');
    } else if (newVolume === 0 && !isMuted) {
      toggleMute();
      console.log('Muted because volume is 0');
    }

    updateVolume(newVolume, isMuted); // Update database with new volume
  };

  return (
    <div className="flex items-center" style={{
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
    }}>
      <button onClick={() => { }} className="mr-2" style={{
        outline: 'none',
        border: 'none',
        background: 'transparent',
        width: '30px',
      }}>
        {isMuted ? <FontAwesomeIcon icon={faBellSlash} size="2x" color='gray' /> : <FontAwesomeIcon icon={faBell} size="2x" color='gray' />}
      </button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={isMuted ? 0 : volume}
        onChange={handleVolumeChange}
        className="volume-slider"
        style={{
          width: '100px',
          height: '4px',
          WebkitAppearance: 'none',
          background: `
          linear-gradient(to right,
          #1bd4d5 0%,
          #1bd4d5 ${isMuted ? 0 : (volume * 100)}%,
          #d3d3d3 ${isMuted ? 0 : (volume * 100)}%,
          #d3d3d3 100%)`,
          outline: 'none',
          opacity: '0.7',
          transition: 'opacity .2s',
        }}
      />
    </div>
  );
};

export default VolumeControl;