import React, { useCallback, useState } from 'react'
import * as NotificationApi from '../api/notification.api'
import useSound from 'use-sound'
import { useTools } from './toolContext'
import notificationSound from '../assets/sounds/notification.mp3'
import { useVolume } from './VolumeProvider'
const NotificationContext = React.createContext({})
// let browserNotificationShown = []
function NotificationProvider(props) {
	const { effectiveVolume } = useVolume();
  const [play] = useSound(notificationSound, { volume: effectiveVolume })
	const { browserNotificationShown } = useTools()
	const [allNotifications, setallNotifications] = useState()
	const fetchNotifications = useCallback(async (data = {}) => {
		try {
			const res = await NotificationApi.findNotificationByParams(data)
			let notification_data = res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

			if (
				notification_data[0] &&
				!notification_data[0]['shownInBrowser'] &&
				!notification_data[0]['read'] &&
				!browserNotificationShown.includes(notification_data[0].id)
			) {
				browserNotificationShown.push(notification_data[0].id)
				let body = `${notification_data[0]['title']}`
				var options = {
					body: body,
				}
				NotificationApi.updateByParams({ _id: notification_data[0].id, shownInBrowser: true })
				play()
			}

			setallNotifications(notification_data)
		} catch (err) {
			console.log('error in Notification Provider >>>>> ', err)
		}
	})

	const createNotification = useCallback(async (data = {}) => {
		try {
			await NotificationApi.create(data)
		} catch (err) {
			console.log('error in Notification Provider >>>>> ', err)
		}
	})

	return (
		<NotificationContext.Provider
			value={{
				createNotification,
				fetchNotifications,
				allNotifications,
			}}
			{...props}
		/>
	)
}

function useNotifications() {
	const context = React.useContext(NotificationContext)
	if (context === undefined) {
		throw new Error('useJob must be used within a JobProvider')
	}
	return context
}

export { NotificationProvider, useNotifications }
