import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button, Popover, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import CloseIcon from '../../../assets/images/close.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faPaperPlane, faPhone, faPhoneAlt, faVolumeUp, faShare, faCog } from '@fortawesome/free-solid-svg-icons'
import ConfirmTechMatch from '../../Common/ConfirmTechMatch'
import { openNotificationWithIcon, callOnPhone, muteMicrophone } from '../../../utils'
import mixpanel from 'mixpanel-browser'
import '../../../css/style.css'
import { getTooltipComponent } from '../../ReusableComponents/TooltipComponent'
import { TOOLTIP_MSG_FOR_REMOTE_BTN, TOOLTIP_MSG_FOR_ENABLE_REMOTE_BTN_CUSTOMER } from '../../../constants'
import { Spin,Modal } from 'antd'
import VolumeControl from '../../VolumeControl/VolumeControl'

const CustomerMeetingControls = (props) => {
	const dialInRef = useRef(null)
	const [MainInvitation, setInvitation] = useState(false)
	const [invitedNumber, setInvitedNumber] = useState('')
	const remoteDesktopRef = useRef(null)
	const [disabledCallYourself, setDisabledCallYourself] = useState(false)
	const [disabledCallTechnician, setDisabledCallTechnician] = useState(false)
	const [inviteStatus, setInviteStatus] = useState(false)
	const [showComputerAudioButton, setShowComputerAudioButton] = useState(false)
	const [disableRemoteBtn, setDisableRemoteBtn] = useState(false)
	const [offToolTip, setOffToolTip] = useState(true)
	const [isInvitedPhone, setIsInvitedPhone] = useState(false)
	const [remoteBtnToolTipMsg, setRemoteBtnToolTipMsg] = useState('')
	const [isVolumeOpen, setIsVolumeOpen] = useState(false);
	const volumeControlRef = useRef(null);
	const [showScreenConnectIframe, setShowScreenConnectIframe] = useState(false)


	const handleVolumeClick = () => {
			setIsVolumeOpen(!isVolumeOpen);
	};

	useEffect(() => {
			const handleClickOutside = (event) => {
					if (volumeControlRef.current && !volumeControlRef.current.contains(event.target)) {
							setIsVolumeOpen(false);
					}
			};

			document.addEventListener('mousedown', handleClickOutside);
			return () => {
					document.removeEventListener('mousedown', handleClickOutside);
			};
	}, []);


	useEffect(() => {
		if (props.jitsiApi != null && MainInvitation) {
			jitsiApiInviteWithPhone()
		}
	}, [invitedNumber, isInvitedPhone])

	useEffect(() => {
		if ((props.job.callStartType === 'Phone' || props.job.technician_started_call) && !showComputerAudioButton) {
			setShowComputerAudioButton(true)
		} else {
			setShowComputerAudioButton(false)
		}
	}, [])

	// This UseEffect will disable the Remote Button on technician side untill meeting is started ~ Jagroop
	useEffect(() => {
		if (!props?.job?.meeting_start_time) {
			setDisableRemoteBtn(true)
			setRemoteBtnToolTipMsg(TOOLTIP_MSG_FOR_REMOTE_BTN)
		} else {
			setDisableRemoteBtn(false)
			setRemoteBtnToolTipMsg(TOOLTIP_MSG_FOR_ENABLE_REMOTE_BTN_CUSTOMER)
		}
	}, [props.job])

	/**
	 * call on phone number which added by user
	 * @params : phone_number, extension, type(Customer/Technician/Invite), jitsiApi, setInviteStatus
	 * @response : Jisti api for invite by phone number
	 * @author : Ridhima
	 */
	function jitsiApiInviteWithPhone() {
		const callParams = {
			phone_number: invitedNumber,
			type: 'Invite',
			jitsiApi: props.jitsiApi,
			setInviteStatus: setInviteStatus,
		}
		callOnPhone(callParams)
	}

	/**
	 * Screenshare stop
	 * @params = ''
	 * @response : Stop screenshare
	 * @author : Ridhima
	 */
	/*const stopShareScreen = () => {
		if (props.jitsiApi != null && showStopButton === true) {
			props.jitsiApi.executeCommand('toggleShareScreen')
			setshowStopButton(false)
			props.setIsScreenShared(false)
		}
	}*/

	/**
	 * Handle zoho remote session
	 * @params = ''
	 * @response : hit zoho function in meeting index file
	 * @author : Ridhima,Manibha
	 */
	
	// 	const handleZohoSession = async () => {
	// 		props.setIsRemoteLoading(true)
	// 		props.hitZohoRemoteSession(props.user.email, 'Customer', props.socket, props.job,props.user.userType)
		
	// }

	/**
	 * Handle ScreenConnect remote session
	 * @params = ''
	 * @response : hit ScreenConnect function in meeting index file
	 * @author : Nafees
	 */
	

	const handleZohoSession = async () => {
		console.log('zoho remote session')
		// const data = {}
		// data['jobId'] = props.job.id
		// data['userClicks'] = props.user.userType
		props.setIsRemoteLoading(true)
		// props.socket.emit('EnableRemoteScreenConnect', data)
		// // mixpanel code
		setShowScreenConnectIframe(true)
		props.setIsRemoteLoading(false)

		mixpanel.identify(props.user.email)
		mixpanel.track('Customer - Enable Cutomer Screen Connect')		
	}

	/**
	 * Handle mic of partispant
	 * @params = ''
	 * @response : Handle mic of partispant
	 * @author : Ridhima
	 */
	const handleParticipantMic = () => {
		if (props.jitsiApi != null) {
			muteMicrophone(props.jitsiApi)
		}
	}

	/**
	 * Call Customer
	 * @params : phone_number, extension, type(Customer/Technician/Invite), jitsiApi, setInviteStatus
	 * @response : Jisti api for invite by phone number
	 * @author : Ridhima
	 */
	const callYourself = () => {
		handleParticipantMic()
		setDisabledCallYourself(true)

		const callParams = {
			phone_number: props.job.customer.phoneNumber,
			type: 'Customer',
			jitsiApi: props.jitsiApi,
			setInviteStatus: setInviteStatus,
		}
		callOnPhone(callParams)
		// mixpanel code
		mixpanel.identify(props.user.email)
		mixpanel.track('Customer - Call himself', { JobId: props.job.id })
		// mixpanel code
	}

	/**
	 * Call technician on phone
	 * @params = ''
	 * @response : handle response when customer clicks in use computer Audio
	 * @author : Sahil
	 */

	const handleComputerAudio = () => {
		window.localStorage.setItem('usingComputerAudio', true)
		window.localStorage.setItem('customerCallingHimSelf', false)
		// mixpanel code
		mixpanel.identify(props.user.email)
		mixpanel.track('Customer - Click on computer audio', { JobId: props.job.id })
		// mixpanel code
		window.location.reload()
	}

	/**
	 * Share customer screen
	 * @params : {null}
	 * @author : Sahil
	 **/

	const handleScreenShare = () => {
		if (props.jitsiApi != null) {
			props.jitsiApi.executeCommand('toggleShareScreen')
			// props.setIsScreenShared(!props.isScreenShared)
		}
	}

	/**
	 * Call technician on phone
	 * @params = ''
	 * @response : Will call technician on phone
	 * @author : Ridhima
	 */
	const callTechnician = () => {
		if (props.jitsiApi != null) {
			setDisabledCallTechnician(true)
			const callParams = {
				phone_number: props.job.technician.profile.confirmId.phoneNumber,
				type: 'Technician',
				jitsiApi: props.jitsiApi,
				setInviteStatus: setInviteStatus,
			}
			callOnPhone(callParams)
			// mixpanel code
			mixpanel.identify(props.user.email)
			mixpanel.track('Customer - Call Technician', { JobId: props.job.id })
			// mixpanel code
		}
	}

	/**
	 * After jisti api success
	 * @response : set usestate variable according to type
	 * @author : Ridhima
	 */
	useEffect(() => {
		if (inviteStatus) {
			if (inviteStatus == 'Technician') {
				setDisabledCallTechnician(false)
				setInviteStatus(false)
			}
			if (inviteStatus == 'Customer') {
				setDisabledCallYourself(!disabledCallYourself)
				setInviteStatus(false)
				window.localStorage.setItem('customerCallingHimSelf', false)
			}
			if (inviteStatus == 'Invite') {
				setInvitation(false)
				openNotificationWithIcon('success', 'Success', 'User has been invited on phone.')
			}
		}
	}, [inviteStatus])

	const getTooltip = () => {
		return offToolTip ? (
			<Tooltip id="tooltip" className="toop-tip-design">
				<>
					<p className="head-tooltip-style mt-2">
						Trouble connecting with Phone Audio?
						<span>
							<img src={CloseIcon} className="img-fluid ml-1 cross-image-style" onClick={() => closeTooltip()} />{' '}
						</span>{' '}
					</p>
					<hr></hr>
					<p className="body-tooltip-style mb-2">Try switching to Computer Audio</p>
				</>
			</Tooltip>
		) : (
			<></>
		)
	}

	const closeTooltip = () => {
		setOffToolTip(false)
	}
	return (
		<Row>
			<Col md="12" className="tech-side-meeting-controlles">
				<Row style={{ justifyContent: 'center' }}>
					{!isMobile ? (
						<React.Fragment>
							<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Share your screen.">
								<Row>
									<Col md="12" className="text-center p-0">
										<Button className="meeting-btn" onClick={handleScreenShare}>
											<FontAwesomeIcon icon={faShare} />
										</Button>
									</Col>
									{!props.isScreenShared ? (
										<Col md="12" className="app-link-outer meeting-btn-link text-center p-0 .d-sm-block .d-sm-none">
											<span className="link-span" onClick={handleScreenShare}>
												Share Screen
											</span>
										</Col>
									) : (
										<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
											<span className="link-span" onClick={handleScreenShare}>
												Stop Sharing
											</span>
										</Col>
									)}
								</Row>
							</Col>
						</React.Fragment>
					) : (
						<></>
					)}
					{showScreenConnectIframe && (
				<Modal visible={showScreenConnectIframe} width="500px" footer={null} onCancel={() => setShowScreenConnectIframe(false)}>
					<iframe src="https://geeker.screenconnect.com/?Theme=GeekerMeeting" className="iframe-screenconnect"></iframe>
				</Modal>
			)}
					{!isMobile ? (
						<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getTooltipComponent(remoteBtnToolTipMsg, true)}>
							<Col
								md="2"
								lg="3"
								xs="4"
								className={(disableRemoteBtn ? 'disabled-btn' : '') + 'pb-3 text-center'}
								title={disableRemoteBtn ? '' : 'Give remote access to technician.'}
							>
								<Row>
									<Col md="12" className="text-center p-0">
										{props.isRemoteLoading ? (
											<Spin size="small" />
										) : (
											<Button className="meeting-btn" onClick={handleZohoSession} disabled={disableRemoteBtn}>
												<FontAwesomeIcon icon={faDesktop} />
											</Button>
										)}
									</Col>
									<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
										<span className="link-span" onClick={handleZohoSession} disabled={disableRemoteBtn}>
											Remote
										</span>
									</Col>
								</Row>
							</Col>
						</OverlayTrigger>
					) : (
						<></>
					)}



					{showComputerAudioButton && (
						<OverlayTrigger trigger="hover" defaultShow={offToolTip} placement="top" overlay={getTooltip()}>
							<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Use computer audio">
								<Row>
									<Col md="12" className="text-center p-0">
										<Button
											className="meeting-btn"
											onClick={handleComputerAudio}
											onMouseOver={() => {
												setOffToolTip(true)
											}}
										>
											<FontAwesomeIcon icon={faVolumeUp} />
										</Button>
									</Col>
									<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
										<span className="link-span" onClick={handleComputerAudio}>
											Computer audio
										</span>
									</Col>
								</Row>
							</Col>
						</OverlayTrigger>
					)}
					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Invite user">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button
									className="meeting-btn"
									onClick={() => {
										dialInRef.current.click()
									}}
								>
									<FontAwesomeIcon icon={faPaperPlane} />
								</Button>
							</Col>
							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span
									className="link-span"
									onClick={() => {
										dialInRef.current.click()
									}}
								>
									Invite
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{ justifyContent: 'center' }}>
					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Call yourself on mobile">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button
									className={(disabledCallYourself ? 'disabled-btn' : '') + ' meeting-btn'}
									onClick={() => {
										callYourself()
									}}
									disabled={disabledCallYourself}
									title="Call yourself on phone"
								>
									<FontAwesomeIcon icon={faPhone} />
								</Button>
							</Col>
							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span
									className="link-span"
									onClick={() => {
										callYourself()
									}}
									disabled={disabledCallYourself}
								>
									Call Yourself
								</span>
							</Col>
						</Row>
					</Col>
					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Call customer on mobile">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button
									className={(disabledCallTechnician ? 'disabled-btn' : '') + ' meeting-btn'}
									onClick={() => {
										callTechnician()
									}}
									disabled={disabledCallTechnician}
									title="Call customer on phone"
								>
									<FontAwesomeIcon icon={faPhoneAlt} />
								</Button>
							</Col>
							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span
									className="link-span"
									onClick={() => {
										callTechnician()
									}}
									disabled={disabledCallTechnician}
								>
									Call Technician
								</span>
							</Col>
						</Row>
					</Col>
					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Adjust notification volume">
						<div className="relative" ref={volumeControlRef}>
								<Button
										className="meeting-btn"
										onClick={handleVolumeClick}
								>
										<FontAwesomeIcon icon={faCog} />
								</Button>
								{isVolumeOpen && (
										<div className="volume-control-popup">
												<VolumeControl />
										</div>
								)}
								<div className="app-link-outer meeting-btn-link text-center p-0">
										<span className="link-span">Settings</span>
								</div>
						</div>
        </Col>
				</Row>
			</Col>
			<ConfirmTechMatch
				invited={true}
				dialInRef={dialInRef}
				setInvitation={setInvitation}
				setInvitedNumber={setInvitedNumber}
				remoteDesktopRef={remoteDesktopRef}
				jobId={props.job.id}
				UserType={props.user.userType}
				UserEmail={props.user.email}
				meetingPlatform={props?.job?.meeting_service}
				setIsInvitedPhone={setIsInvitedPhone}
			/>
		</Row>
	)
}

export default CustomerMeetingControls
