import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'

export const checkMediaPermission = async () => {
	try {
		const response = await requestMediaPermissions({ audio: true, video: false })
		return 'permission-given'
		// Media permission granted
	} catch (err) {
		const { type } = err
		if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
			return 'system-permission-denied'
		} else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			return 'user-permission-denied'
		} else if (type === MediaPermissionsErrorType.Prompt) {
			return 'permission-not-allowed'
		} else {
			return 'error-in-accessing-permission'
		}
	}
}

export const checkCameraPermissions=async()=>{
	try {
		const response = await requestMediaPermissions({audio: false, video: true})
		return 'permission-given'
	} catch (error) {
		console.log("Camera Permission is not available", error);
		const {name} = error
		if(name == "NotFoundError"){
			return "Camera Not Available."
		}else{
			return  "Camera Permission not given."
		}
	}
}
