import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './components/Home/'
import Meeting from './components/Meeting/'
import MeetingOver from './components/MeetingOver/'
import InvalidMeeting from './components/InvalidMeeting/'
import NotFound from './components/NotFound'
const Router = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/meeting/:userTypeParams/:jobId" component={Meeting} />
				<Route exact path="/meeting-over/:userType/:jobId" component={MeetingOver} />
				<Route exact path="/invalid-meeting/:userType/:jobId" component={InvalidMeeting} />
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	)
}

export default Router
