import apiClient from "./index";
export async function uploadFile(data,userId) {
    return apiClient.post(`/jobfile?cuId=${userId}`, data).then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
  }
export async function listOfUploadFile(data,userId) {
      return apiClient.post(`/jobfile/list?cuId=${userId}`, data).then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  }

export async function deleteFile(file,userId) {
        return apiClient.post(`/jobfile/deleteFile?cuId=${userId}`, file).then((response) => {
          if (response) {
            return response.data;
          }
          return Promise.reject();
        });
}

export async function downloadFile(file,userId) {
          return apiClient.post(`/jobfile/downloadFile?cuId=${userId}`, file).then((response) => {
            if (response) {
              return response.data;
            }
            return Promise.reject();
          });
 }