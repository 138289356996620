import apiClient from './index'

/**
 * Get job information
 * @params = jobId
 * @response : Will return all the job information of specified jobid
 * @author : Karan
 */
export async function getJobInformation(jobId, cuId) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.get(`/job/${jobId}?cuId=${cuId}`)
			.then((res) => {
				if (res && res.data) {
					response['success'] = true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

/**
 * Update job information
 * @params = jobId (Type: String), data(Type:Object)
 * @response : Will save specified data like notes, software, description
 * @author : Karan
 */
export async function updateJob(jobId, data) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.put(`/job/update/${jobId}`, data)
			.then((res) => {
				if (res && res.data) {
					response['success'] = true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

/**
 * Fetch jobs information
 * @params = jobId (Type: String), data(Type:Object)
 * @response : Will save specified data like notes, software, description
 */
export async function findJobByParams(data, pagination = { page: 1, pageSize: 10 }) {
	return apiClient.post('/job/fetchjob', data, { params: pagination }).then((response) => {
		if (response) {
			return { jobs: response.data, totalPages: response.data.totalCount }
		}
		return Promise.reject()
	})
}

export async function sendDataSession(user_email, cuId) {
	return apiClient.get(`/job/sendInvitation/${user_email}?cuId=${cuId}`).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

// Function to create a session
export async function createSession(data,cuId) {
	console.log('Creating session 2',data);
	return apiClient.post(`/job/createSession?cuId=${cuId}`, data).then((response) => {
	  if (response) {
		console.log('Creating session 3',data);

		return response.data;
	  }
	  return Promise.reject();
	});
	
}


/** Get total paid jobs of customer
 * @params = jobId (Type: String), customerId (Type: String)
 * @response : Will return total paid jobs of customer
 * @author : Karan
 */
export async function getTotalPaidJobs(jobId, customerId, cuId) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.post('/job/getTotalPaidJobs', { jobId: jobId, customerId: customerId, cuId })
			.then((res) => {
				if (res && res.data) {
					response['success'] = true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

export async function removeTechnicianNotesById(techNoteId, cuId) {
	return apiClient.delete(`/job/removeTechnicianNotesById/${techNoteId}?cuId=${cuId}`).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
