import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import './SharingYourScreenModal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'

const SharingYourScreenModal = ({ job, setOpenShareScreenModal, openShareScreenModal, jitsiApi }) => {
	const [technicianName, setTechnicianName] = useState()

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		// width: 600,
		gap: '14px',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	}

	useEffect(() => {
		if (openShareScreenModal) {
			setTechnicianName(job?.technician?.user?.firstName + ' ' + job?.technician?.user?.lastName)
		}
	}, [job, openShareScreenModal])

	const handleClose = () => {
		setOpenShareScreenModal(false)
	}

	const shareScreen = () => {
		if (jitsiApi != null) {
			jitsiApi.executeCommand('toggleShareScreen')
		}
		setOpenShareScreenModal(false)
	}

	return (
		<>
			<div>
				<Modal
					open={openShareScreenModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style} className="resp-css-box">
						<div className="close-icon">
							<CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
						</div>
						<div className="content-container">
							<Typography id="modal-modal-title" variant="h6" component="h2">
								<div className="text-div-1 mg-b-10">
									<div className="end-the-job-div-share">
										<span className="close-icon-btn-text-share" id="automation-end-meeting-btn">
											Start securely sharing your screen with {` `}
											<i>{technicianName}</i> {` `}?
										</span>
									</div>
									<small style={{
										fontSize: '16px',
										marginTop: '10px',
										color: '#2F3F4C',
									}}>Sharing your screen will help resolve your issue faster.</small>
								</div>
							</Typography>
							<Typography id="modal-modal-description" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<div className="mg-b-10 resp-css">
									<div className="button-container">
									<Button variant="outlined" className='border-color-btn' onClick={handleClose}>
											<span className='ok-span'>
												I’ll do this later
											</span>
										</Button>
										<Button variant="text" className="later-btn" onClick={shareScreen}>
											<span className='later-span'>
												<FontAwesomeIcon icon={faShare} />
												{" "}
												Yes
											</span>
										</Button>
									</div>
								</div>
							</Typography>
						</div>
					</Box>
				</Modal>
			</div>
		</>
	)
}

export default SharingYourScreenModal