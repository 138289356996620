import '../../logrocketSetup'
import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import Header from '../Common/Header'
import { redirectToApp } from '../../utils'
// import { ErrorBoundary } from '@rollbar/react'

const Home = () => {
	return (
		// <ErrorBoundary>
		<Row>
			<Col lg="12" className="text-center" style={{ height: '100vh' }}>
				<Row>
					<Col md="4" lg="4" className="m-auto text-center pt-5">
						<Header />
					</Col>

					<Col lg="12" className="mt-5">
						<h2 className="">
							<span>Welcome to Geeker.</span>
							<span className="mt-4 d-block w-100">
								<Button onClick={() => redirectToApp('dashboard')}>Click here </Button> to open dashboard.
							</span>
						</h2>
					</Col>
				</Row>
			</Col>
		</Row>
		// </ErrorBoundary>
	)
}

export default Home
