import React from 'react'
import { JitsiMeetProvider } from './jitsiMeetContext'
import { NotificationProvider } from './notificationContext'
import { VolumeProvider } from './VolumeProvider'

function AppProviders({ children }) {
	return (
		<JitsiMeetProvider>
			{' '}
			<VolumeProvider>
				<NotificationProvider> {children} </NotificationProvider>{' '}
			</VolumeProvider>
		</JitsiMeetProvider>
	)
}

export default AppProviders
