import apiClient from './index'

/**
 *
 * @param {*} jobId
 * @param {*} data
 * @returns response
 * @description : This API will update the job
 */

export async function updateJob(jobId, data) {
	return apiClient.put(`/job/update/${jobId}`, data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

/**
 *
 * @param {*} jobId
 * @param {*} data
 * @returns response
 * @description : This API  is used to get Job Details
 */

export async function getJobData(jobId, cuId) {
	return apiClient.get(`/job/${jobId}?cuId=${cuId}`).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
