import React, { useState } from 'react'
import { Modal as AntModal, Typography, Form, Input, Spin, Button } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WelcomePartnerPopup = ({ partnerData, onClose, isOpen, customer }) => {
	const { brandName, coBrandingLogoUrl, webUrl } = partnerData
	const customerFullName = `${customer.firstName} ${customer.lastName}`


	const handleStartMeetingButtonClick = () => {
		sessionStorage.setItem('dontShowWelcomePartnerPopup', true)
		onClose()
	}

	if (sessionStorage.getItem('dontShowWelcomePartnerPopup')) {
		return null
	}

	return (
		<Modal
			title={
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px' }}>
					<img src={coBrandingLogoUrl} alt="Geeker + Positive Logo" style={{ height: '100px' }} />
					<span style={{ marginTop: '10px', fontSize: '18px' }}>New customer! 🥳 </span>
				</div>
			}
			visible={isOpen}
			closable={false}
			footer={null}
			width={450}
		>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '10px',
						fontSize: '16px',
					}}
				>
					<Typography.Text style={{ textAlign: 'center', marginBottom: '16px' }}>
						Please welcome {customerFullName} and start their call with a warm welcome!
					</Typography.Text>
					<div style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
						<Typography.Text style={{ textAlign: 'center' }}>
							<strong>Example:</strong> "Hi {customer.firstName}! Is this your first call? Let me welcome you to Geeker, and thanks for joining us from {brandName}! I hope you have a great experience today."
						</Typography.Text>
					</div>
				</div>
				<Button type="primary" block onClick={handleStartMeetingButtonClick} style={{ marginTop: '20px', width: '50%', paddingBottom: '47px' }}>
					Start Meeting
				</Button>
			</div>
		</Modal>
	)
}

const Modal = styled(AntModal)`
	.ant-modal-content {
		border-radius: 10px;
	}
	.ant-typography {
		margin-bottom: 8px;
	}
`

WelcomePartnerPopup.propTypes = {
	partnerData: PropTypes.shape({
		brandName: PropTypes.string.isRequired,
		coBrandingLogoUrl: PropTypes.string.isRequired,
		webUrl: PropTypes.string.isRequired,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
}

export default WelcomePartnerPopup
