import React, { useState, useEffect } from 'react'
import { Col, Alert, Button,OverlayTrigger, Tooltip } from 'react-bootstrap'
import { updateJob, getJobInformation } from '../../api/meeting.api'
import { takeChargeFromCustomer } from '../../api/stripe.api'
import { openNotificationWithIcon, isLiveUser } from '../../utils'
import { checkCustomerHaveCard } from '../../services/FreeMinutePopupService'
import { Modal, Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import mixpanel from 'mixpanel-browser'
import { JobTags,DISCOUNT_COUPON} from '../../constants'
import { CalculateDiscount } from '../../constants/calculateDiscount'
import * as JobCycleApi from '../../api/jobCycle.api'
import * as CustomerApi from '../../api/customer.api'
let liveUser = true

const LongJobDetails = (props) => {
	const [showApproveButtons, setShowApproveButtons] = useState(false)
	const [longJobHours, setLongJobHours] = useState(false)
	const [longJobCost, setLongJobCost] = useState(false)
	const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState(false)
	const [showLongJobDetails, setShowLongJobDetails] = useState(false)
	const [hoursValue, setHoursValue] = useState(2)
	const [fillHourSection, showFillHourSection] = useState(false)
	const [showLongJobButton, setShowLongJobButton] = useState(props.job.jobDuration == 'more than 2 hours' ? false : true)
	const [showWaitMessage, setShowWaitMessage] = useState(false)
	const [showApprovalMessage, setShowApprovalMessage] = useState(false)
	const [showRejectedMessage, setShowRejectedMessage] = useState(false)
	const [showWaitToCustMessage, setShowWaitToCustMessage] = useState(false)
	const [calculatedCost, setCalculatedCost] = useState(props.job.software.hourlyRate * 2)
	const [radioValue, setRadioValue] = useState('fixed')
	const [showContinueWithMinuteMessage, setShowContinueWithMinuteMessage] = useState(false)
	const [showContinueWithHours, setShowContinueWithHours] = useState(false)
	const [meetingWithMinutes, setMeetingWithMinutes] = useState(false)
	const [showContinueWithMinutes, setShowContinueWithMinutes] = useState(false)
	const [estimatedTime, setEstimatedTime] = useState(2)
	const [discountedCost, setDiscountedCost] = useState(0)
	const [isButtonDisabled, setIsButtonDisabled] = useState(false)
	const [isFixedJobApproved, setIsFixedJobApproved] = useState('')
	const [isFullDiscountCoupon, setIsFullDiscountCoupon] = useState(false)

	useEffect(() => {
		if (
			props.job.long_job_with_minutes === 'yes' &&
			(props.job.customer_approved_long_job === undefined || props.job.customer_approved_long_job === null)
		) {
			setMeetingWithMinutes(true)
			if (props.user.userType === 'technician') {
				setShowLongJobButton(false)
				setShowWaitMessage(true)
			}
			if (props.user.userType === 'customer') {
				setShowLongJobDetails(true)
				setShowApproveButtons(true)
				setShowContinueWithMinuteMessage(true)
			}
		}

		if (props.job.long_job_with_minutes == 'yes' && props.job.customer_approved_long_job === 'yes') {
			if (props.user.userType === 'technician') {
				props.setShowTransferButton(false)
			}
			setShowLongJobDetails(true)
			setShowContinueWithMinutes(true)
		}

		if (props.job.long_job_with_minutes == 'yes' && props.job.customer_approved_long_job === 'no') {
			if (props.user.userType === 'technician') {
				setShowRejectedMessage(true)
				setShowContinueWithMinuteMessage(false)
				setShowApprovalMessage(false)
				setShowWaitMessage(false)
				setShowLongJobButton(true)
				openNotificationWithIcon('error', 'Error', 'Project hours approval has been declined by the client.')
			}
			if (props.user.userType === 'customer') {
				setShowWaitToCustMessage(true)
			}
		}
		if (
			props.job.long_job_cost &&
			props.job.long_job_hours &&
			(props.job.long_job_with_minutes == 'no' || props.job.long_job_with_minutes == 'undefined')
		) {
			if (props.user.userType === 'technician') {
				setShowLongJobButton(false)
				setShowWaitMessage(true)
				showLongJobHourDetails()
			}

			if (props.user.userType === 'customer') {
				setShowApproveButtons(true)
				showLongJobHourDetails()
			}
		}
		if (
			props.job.is_transferred &&
			(props.job.customer_approved_long_job === 'no' ||
				props.job.customer_approved_long_job === undefined ||
				props.job.customer_approved_long_job === null)
		) {
			setShowWaitMessage(false)
			setShowRejectedMessage(false)
			setShowWaitToCustMessage(false)
			setShowApproveButtons(false)
			setShowLongJobDetails(false)
			setShowLongJobButton(true)
		}
		if (props.job.customer_approved_long_job != undefined && props.job.customer_approved_long_job === 'yes') {
			props.setShowEndmeetingButton(false)
			setShowLongJobButton(false)
			if (props.job.long_job_with_minutes != undefined) {
				setShowContinueWithMinutes(true)
			} else {
				setShowContinueWithHours(true)
			}
		}
		if (props.job.is_long_job && props.job.long_job_cost && props.job.long_job_hours > 0 && props.user.userType === 'customer') {
			if (props.job.customer_approved_long_job === 'no') {
				setShowWaitToCustMessage(true)
			}
			if (props.job.customer_approved_long_job === undefined) {
				setShowWaitToCustMessage(true)
			}
			if (props.job.customer_approved_long_job === 'yes') {
				setShowContinueWithMinutes(false)
				showLongJobHourDetails()
				setShowApproveButtons(false)
			}
		}

		if (props.job.is_long_job && props.job.long_job_cost && props.job.long_job_hours > 0 && props.user.userType === 'technician') {
			if (props.job.customer_approved_long_job === 'yes') {
				props.setShowTransferButton(false)
				props.setEnableStartPauseTimerButton(false)
				showFillHourSection(false)
				setShowWaitMessage(false)
				setShowLongJobButton(false)
				setShowApprovalMessage(false)
				if (props.job.long_job_with_minutes === 'yes') {
					setShowContinueWithMinutes(true)
				} else {
					showLongJobHourDetails()
					setShowContinueWithMinutes(false)
				}
			} else if (props.job.customer_approved_long_job === 'no') {
				showFillHourSection(true)
				setShowWaitMessage(false)
				setShowLongJobButton(true)
				setShowRejectedMessage(true)
				setShowContinueWithMinuteMessage(false)
			} else if (props.job.customer_approved_long_job === undefined) {
				showFillHourSection(false)
				setShowWaitMessage(true)
				setShowLongJobButton(false)
			}
		}

		if (props.job.jobDuration == 'more than 2 hours' && props.job.long_job_with_minutes == undefined) {
			console.log('inside if part ..... more than 2 hours')
			showFillHourSection(true)
		}
		
	}, [props.job])
	useEffect(()=>{
		if(props.user.userType == 'technician' && props.job.discount_type == "percentage" && props.job.coupon_code_discount == DISCOUNT_COUPON){
			setIsFullDiscountCoupon(true);
		}
	},[props.job])

	useEffect(() => {
		if (props.newCardAdded && props.approveClicked) {
			changeJobtoLongJob()
		}
	}, [props.newCardAdded])

	useEffect(() => {
		if (props.socket) {
			/**
			 * This socket is sent by technician to client while sending Project hours estimation.
			 * @params =  data of the socket
			 * @response : no response
			 * @author : Manibha
			 */
			props.socket.on('Submitted:hours', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					if (socketData.long_job_with_minutes !== undefined) {
						setMeetingWithMinutes(true)
						setShowContinueWithMinuteMessage(true)
						setShowContinueWithHours(false)
						setShowApproveButtons(true)
						setShowLongJobDetails(true)
						setShowWaitToCustMessage(false)
					} else {
						setMeetingWithMinutes(false)
						setShowContinueWithMinuteMessage(false)
						setShowContinueWithHours(true)
						setLongJobHours(socketData.hoursValue)
						setLongJobCost(socketData.cost)
						setEstimatedDeliveryTime(socketData.estimated_time)
						setShowApproveButtons(true)
						setShowLongJobDetails(true)
						setShowWaitToCustMessage(false)
					}
					if (props.job && props.job.coupon_code_discount) {
						const discountData = {
							totalCost: parseInt(props.job.software.hourlyRate) * socketData.hoursValue,
							discountedValue: props.job && props.job.coupon_code_discount ? parseInt(props.job.coupon_code_discount) : 0,
							discountType: props.job && props.job.discount_type ? props.job.discount_type : 'NA',
						}
						const calculateCost = CalculateDiscount(discountData.totalCost, discountData.discountedValue, discountData.discountType)
						setDiscountedCost(calculateCost)
					}
				}
			})

			/**
			 * This socket is sent by client to technician while replying to the project hours estimation.
			 * @params =  data of the socket
			 * @response : no response
			 * @author : Manibha
			 */
			props.socket.on('Submitted:confirmedHours', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id && props.user.userType === 'technician') {
					if (socketData.long_job_confirmed === 'yes') {
						console.log('>>>>>>>>>>>>>  project confirmed   >>>>>>>>>')
						if (socketData.meetingWithMinutes == undefined || socketData.meetingWithMinutes == false) {
							setShowContinueWithMinutes(false)
							props.setIntialTimeVal(0)
							setShowContinueWithHours(true)
							setShowContinueWithMinuteMessage(false)
							props.setEnableStartPauseTimerButton(false)
						} else {
							setShowContinueWithMinuteMessage(false)
							setMeetingWithMinutes(true)
							setShowContinueWithMinutes(true)
						}

						props.setShowTransferButton(false)

						setShowWaitMessage(false)
						setShowApprovalMessage(true)
						setShowRejectedMessage(false)
						props.setShowEndmeetingButton(false)
						openNotificationWithIcon(
							'success',
							'Success',
							`Your request to convert to a project was confirmed! Please click the "back to dashboard" button below. This will take you back to the dashboard, where you'll be able to chat with your customer, and get the required information necessary to complete the request.`,
						)
						const jobInfoResponse = await getJobInformation(socketData.jobId, props?.user?.id)
						const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
						if (jobInfo.id != undefined) {
							setLongJobHours(jobInfo.long_job_hours)
							setLongJobCost(jobInfo.long_job_cost)
							setEstimatedDeliveryTime(jobInfo.estimated_time)
							setShowLongJobDetails(true)
							// // Checking if total discounted cost is availale . If available the send it to the state
							// const cost = jobInfo && jobInfo.total_discounted_cost && jobInfo.total_discounted_cost!=undefined ? jobInfo.total_discounted_cost : 0
							// setDiscountedCost(cost)
						}
					} else if (socketData.long_job_confirmed === 'no') {
						// console.log('LongjobDetails::customer did no>>>>>>>>>>>>>')
						setShowRejectedMessage(true)
						setShowContinueWithMinuteMessage(false)
						setShowApprovalMessage(false)
						setShowWaitMessage(false)
						setShowLongJobButton(true)
						if (socketData.meetingWithMinutes == undefined) {
							showFillHourSection(true)
						}
						openNotificationWithIcon('error', 'Error', 'Project hours approval has been declined by the client.')
					}
				}
			})
		}
	}, [props.socket])

	/*useEffect(() =>{long_job_hours
		if(props.job &&  props.job.coupon_code_discount && longJobCost ){
			const discountData = {
				totalCost : parseInt(props.job.software.hourlyRate) * socketData.hoursValue,
				discountedValue : props.job && props.job.coupon_code_discount ?parseInt(props.job.coupon_code_discount) : 0,
				discountType :  props.job && props.job.discount_type ? props.job.discount_type : "NA"
			}
			console.log("discountData", discountData)
			const calculateCost = CalculateDiscount(discountData.totalCost , discountData.discountedValue , discountData.discountType)
			setDiscountedCost(calculateCost)
		}else{
			setDiscountedCost(0)
		}
	},[longJobCost])*/

	useEffect(() => {
		;(async () => {
			liveUser = await isLiveUser(props.job)
		})()
	})

	useEffect(()=>{
		if(radioValue === 'timely'){
			setHoursValue(2)
		}
	},[radioValue])


	/**
	 * Function will show the project with hours section.
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */
	const showLongJobHourDetails = () => {
		setShowLongJobDetails(true)
		setShowContinueWithHours(true)
		setLongJobHours(props.job.long_job_hours)
		setLongJobCost(props.job.long_job_cost)
		setEstimatedDeliveryTime(props.job.estimated_time)

		if (props.job && props.job.coupon_code_discount) {
			const discountData = {
				totalCost: parseInt(props.job.software.hourlyRate) * parseInt(props.job.long_job_hours),
				discountedValue: parseInt(props.job.coupon_code_discount),
				discountType: props.job.discount_type ? props.job.discount_type : 'NA',
			}

			const calculateCost = CalculateDiscount(discountData.totalCost, discountData.discountedValue, discountData.discountType)
			setDiscountedCost(calculateCost)
		}
	}

	/**
	 * Function will run if customer confirms the approval of hours send by technician.It will take charge from customer if the job is of fixed hours.
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */
	const changeJobtoLongJob = async () => {
		if (!meetingWithMinutes) {
			const charge = await takeChargeFromCustomer({
				job_id: props.job.id,
				user_id: props?.job?.customer?.user?.id,
				liveUser: liveUser,
				cuId: props.user?.id,
			})
			console.log('LongjobDetails::takeChargeFromCustomer>>>>>>>>', charge)
			if (charge?.data?.success && charge?.data?.status == 'Successful') {
				convertToLongJob()
			} else {
				setIsButtonDisabled(false)
				openNotificationWithIcon(
					'error',
					'Error',
					'Your card transaction has been failed.Try adding another card in dashboard settings tab and make it default.You can come back to this meeting then and try approving the project again.',
				)
				setIsFixedJobApproved('If you have changed the card please refresh the page and then try again')
			}
		} else {
			convertToLongJob()
		}
	}

	/**
	 * Function will run if customer confirms the approval of hours and money is deducted from customer's account if the job is of fixed hours
	 * It also sends socket to technician telling him customer has approved project.
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */
	const convertToLongJob = async () => {
		// console.log('set Continue with minutes ::::::: ', showContinueWithMinutes)
		setShowContinueWithMinuteMessage(false)
		if (!meetingWithMinutes) {
			props.setIntialTimeVal(0)
			setShowContinueWithHours(true)
		} else {
			setShowContinueWithMinutes(true)
		}

		const dataToUpdate = {
			customer_approved_long_job: 'yes',
			status: 'long-job',
		}

		const updateStatus = updateJob(props.job.id, {
			dataToUpdate: dataToUpdate,
			cuId: props.user?.id,
		})

		const data = {}
		data['jobId'] = props.job.id
		data['long_job_confirmed'] = 'yes'
		data['customer_id'] = props.job.customer.user.id
		data['technician_id'] = props.job.technician.user.id
		data['software_name'] = props.job.software.name
		data['meetingWithMinutes'] = meetingWithMinutes
		data['customer_phonenumber'] = props.job.customer.phoneNumber
		data['tag'] = props?.job?.technician?.tag
		try {
			data['technician_toggle'] = props.job.technician['profile']['alertPreference']['settings']['Job']['Text']['toggle']
			data['technician_phonenumber'] = props.job.technician['profile']['alertPreference']['settings']['Job']['Text']['value']
		} catch (err) {
			// console.log('LongjobDetails::did not find any number to send Project text')
			data['technician_phonenumber'] = ''
			data['technician_toggle'] = ''
		}
		props.socket.emit('SendHours:confirmed', data)
		props.setShowEndmeetingButton(false)
		setShowApproveButtons(false)
		setShowWaitToCustMessage(false)
		openNotificationWithIcon(
			'success',
			'Success',
			`${props.user.firstName}, your request to convert to a project was confirmed! Please click the "back to dashboard" button below. This will take you back to the dashboard, where you'll be able to communicate with your technician. Looking forward to solving your request!`,
		)

		if (!meetingWithMinutes) {
			props.socket.emit('Pause:timer', data)
		}
		// mixpanel code
		mixpanel.identify(props.job.customer.user.email)
		mixpanel.track('Meeting converted to project', { Data: data })
		// mixpanel code
	}

	/**
	 * Function will run if customer click on reject button to reject the hours sent by technician.
	 * It also sends socket to technician for rejection by customer.
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */
	const rejectLongJob = async () => {
		setIsButtonDisabled(true)
		console.log('LongjobDetails::rejectLongJob>>>>>>>>>')
		const data = {}
		data['jobId'] = props.job.id
		data['long_job_confirmed'] = 'no'

		const updateStatus = updateJob(props.job.id, {
			dataToUpdate: { customer_approved_long_job: 'no' },
			cuId: props.user?.id,
		})
		props.socket.emit('SendHours:confirmed', data)
		setShowWaitToCustMessage(true)
		setShowApproveButtons(false)
		setShowContinueWithMinuteMessage(false)
		openNotificationWithIcon('success', 'Success', 'Project has been rejected.')
		await JobCycleApi.create(JobTags.CUSTOMER_REJECT_LONG_JOB, props.job.id)

		setTimeout(() => {
			setIsButtonDisabled(false)
		}, 2000)
	}

	/**
	 * Function will run if customer click on approve button to approve the hours sent by technician.
	 * It also confirms the approval.It calls a function further if customer confirms yes to approval.
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */
	const approveLongJob = () => {
		if (props.user) {
			mixpanel.identify(props.user.email)
			mixpanel.track('Customer- Click on Approve button to approve project', { JobId: props.job.id })
		}
		Modal.confirm({
			title: 'Do you want to convert this job into a project?',
			okText: 'Yes',
			cancelText: 'No',
			className: 'app-confirm-modal',
			async onOk() {
				setIsButtonDisabled(true)
				props.setApproveClicked(true)

				let ownerId = props?.job?.customer?.user?.ownerId
				let customerId = props?.job?.customer?.id
				if (ownerId) {
					const ownerCustomerDetails = await CustomerApi.getCustomer(ownerId, props?.job?.customer?.user?.id)
					customerId = ownerCustomerDetails.id
				}
				const haveCard = await checkCustomerHaveCard(
					customerId,
					props?.job?.customer?.user?.id ? props?.job?.customer?.user?.id : props?.job?.customer?.user,
				)
				console.log('LongjobDetails::haveCard>>>>>>>>>>>>>>>>>', haveCard)

				if (haveCard) {
					setIsButtonDisabled(true)
					changeJobtoLongJob()
					if (props.user) {
						mixpanel.identify(props.user.email)
						mixpanel.track('Customer- Click on Yes button and approve Project', { JobId: props.job.id })
					}
					await JobCycleApi.create(JobTags.CUSTOMER_APPROVE_LONG_JOB, props.job.id)
				} else {
					if (ownerId) {
						props.openAddCardModal(true)
						setIsButtonDisabled(false)
						openNotificationWithIcon('error', 'Error', 'Sorry. You do not have card.')
					} else {
						openNotificationWithIcon('error', 'Error', 'Please contact your organisation owner to add card to proceed further.')
					}
				}
			},
		})
	}

	/**
	 * Function will run on value change of fill hour input. It checks the validation of input.
	 * @params =  target event
	 * @response : no response
	 * @author : Manibha
	 */

	const changeHourValue = (event) => {
		let newHrValue = parseFloat(event.target.value)
		if (newHrValue < 2) {
			setHoursValue(2)
			return openNotificationWithIcon('error', 'Error', 'Please input a number greater than 1.')
		}
		if (Number.isInteger(newHrValue) === false) {
			return openNotificationWithIcon('error', 'Error', 'Please input a whole number greater than 1.')
		}
		if (newHrValue > 1 && Number.isInteger(newHrValue)) {
			let cost = parseFloat(props.job.software.hourlyRate) * newHrValue
			setHoursValue(newHrValue)
			setCalculatedCost(cost)
			if (props.user) {
				mixpanel.identify(props.user.email)
				mixpanel.track(`Technician - Selected ${newHrValue} hour's for fixed hour Project`, { JobId: props.job.id })
			}
		}
	}

	/**
	 * It checks the validation of input.
	 * @params =  target event
	 * @response : no response
	 * @author : Utkarsh Dixit
	 */
	const checkEstimtedTime = (event) => {
		let estTime = parseFloat(event.target.value)
		if (estTime < 2) {
			setEstimatedTime(2)
			return openNotificationWithIcon('error', 'Error', 'Please input a number greater than 1.')
		}
		if (Number.isInteger(estTime) === false) {
			return openNotificationWithIcon('error', 'Error', 'Please input a whole number greater than 1.')
		}
		if (estTime > 1 && Number.isInteger(estTime)) {
			setEstimatedTime(estTime)
		}
	}

	/**
	 * Function will send the hours filled by technician to customer using socket
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */

	const sendHoursToCustomer = () => {
		if (props.user) {
			mixpanel.identify(props.user.email)
			mixpanel.track('Technician - Click on Submit for Project', { JobId: props.job.id })
		}
		if (estimatedTime >= hoursValue) {
			Modal.confirm({
				title: `${
					radioValue === 'timely'
						? 'Are you sure you want to submit?'
						: `Are you sure you want to submit? The time you submitted for the job length is ${hoursValue} hours.`
				}`,
				okText: 'Confirm',
				cancelText: 'Go back',
				className: 'app-confirm-modal',
				async onOk() {
					setIsButtonDisabled(true)
					localStorage.removeItem('longJobPopUp')
					if (props.user && radioValue) {
						mixpanel.identify(props.user.email)
						mixpanel.track(
							`Technician - Click on Yes button to convert job into Project ${
								radioValue == 'timely' ? 'per six minute' : 'fixed hours'
							}`,
							{ JobId: props.job.id },
						)
					}
					if (radioValue === 'timely') {
						const data = {}
						data['jobId'] = props.job.id
						data['long_job_with_minutes'] = 'yes'
						data['estimated_time'] = estimatedTime
						props.socket.emit('SendHours:submitted', data)
						showFillHourSection(false)
						setShowLongJobButton(false)
						setShowWaitMessage(true)
						setShowRejectedMessage(false)
						setShowApprovalMessage(false)
					} else {
						// The below Object is required when Promocode is Applied
						const discountData = {
							totalCost: parseInt(props.job.software.hourlyRate) * hoursValue,
							discountedValue: props.job && props.job.coupon_code_discount ? parseInt(props.job.coupon_code_discount) : 0,
							discountType: props.job && props.job.discount_type ? props.job.discount_type : 'NA',
						}
						const calculateCost = CalculateDiscount(discountData.totalCost, discountData.discountedValue, discountData.discountType)
						setDiscountedCost(calculateCost)
						const data = {}
						data['jobId'] = props.job.id
						data['hoursValue'] = hoursValue
						data['cost'] = parseInt(props.job.software.hourlyRate) * hoursValue
						data['estimated_time'] = estimatedTime
						data['total_discounted_cost'] = calculateCost
						props.socket.emit('SendHours:submitted', data)
						showFillHourSection(false)
						setShowLongJobButton(false)
						setShowWaitMessage(true)
						setShowRejectedMessage(false)
						setShowApprovalMessage(false)
					}
					let lifeCycleTag = ''
					if (radioValue == 'timely') {
						lifeCycleTag = JobTags.TECHINCIAN_SELECT_MINUTES_LONG_JOB
					} else {
						lifeCycleTag = JobTags.TECHINCIAN_SELECT_FIXED_LONG_JOB
					}
					await JobCycleApi.create(lifeCycleTag, props.job.id)
				},
			})
			setTimeout(() => {
				setIsButtonDisabled(false)
			}, 3000)
		} else {
			openNotificationWithIcon('error', 'Error', 'Total project hours cannot be greater than the estimated delivery time.')
		}
	}

	/**
	 * Function will open the input for technician to fill hours
	 * @params =  no params
	 * @response : no response
	 * @author : Manibha
	 */
	const openFillHourSection = async () => {
		if (props.user) {
			mixpanel.identify(props.user.email)
			mixpanel.track('Technician - Click on Conevert to Project', { JobId: props.job.id })
		}
		showFillHourSection(true)
	}

	/**
	 * Function will set the value of project type selected by customer in a variable
	 * @params =  event target radio btn
	 * @response : no response
	 * @author : Manibha
	 */

	const onRadioChange = async (e) => {
		setRadioValue(e.target.value)
	}

	return (
		<Col xs={12}>
			{showWaitMessage && props.user.userType === 'technician' && (
				<Alert variant="info" className="w-100 mt-3">
					Estimates for Project has been sent to the customer. Waiting for the reply.
				</Alert>
			)}

			{showRejectedMessage && props.user.userType === 'technician' && (
				<Alert variant="danger" className="w-100 mt-3">
					Customer rejected your Project estimates.
				</Alert>
			)}

			{showApprovalMessage && props.user.userType === 'technician' && (
				<Alert variant="success" className="w-100 mt-3">
					Project has been confirmed.
				</Alert>
			)}

			{showWaitToCustMessage && props.user.userType === 'customer' && (
				<Alert variant="info" className="w-100 mt-3">
					Project estimation is declined by you. Technician will review the estimation. Please wait for technician reply.
				</Alert>
			)}

			{showApproveButtons && showContinueWithHours && props.user.userType === 'customer' && (
				<Alert variant="info" className="w-100 mt-3">
					{props.user.firstName}, please review the Project estimate below. Once approved, payment will be automatically approved through
					secure payment systems. Don't worry: this will be refunded if your technician is unable for some reason to solve your request.
				</Alert>
			)}

			{showLongJobDetails && (
				<>
					{(props.user.userType === 'customer' || props.user.userType === 'technician') && (
						<>
							{props.job.customer_approved_long_job != undefined && props.job.customer_approved_long_job === 'yes' && (
								<h5 className="mt-5">Project estimations confirmed :</h5>
							)}
							{(props.job.customer_approved_long_job === undefined ||
								(props.job.customer_approved_long_job != undefined && props.job.customer_approved_long_job === 'no')) && (
								<h5 className="mt-5">Project price summary :</h5>
							)}

							{showContinueWithMinuteMessage && props.user.userType === 'customer' && (
								<Alert variant="info" className="w-100 mt-3">
									Technician wants to convert this job to Project. Total cost of this job will be calculated by per six minute
									of job total time. <br />
									Would to like to approve?
								</Alert>
							)}

							{showContinueWithMinutes && (
								<Alert variant="info" className="w-100 mt-3">
									This Job has been converted to Project. Charges will be applied on per 6 minutes basis.
								</Alert>
							)}

							{showContinueWithHours && (
								<>
									<h6 className="mt-2">Total Hours - {longJobHours}</h6>
									{props?.user?.userType === 'customer' && discountedCost != 0 && discountedCost !== longJobCost ? (
										<>
											<span className="mt-2 job-cost-style">Total Cost - </span>
											<strike>
												<span className="mt-2 job-cost-style">${longJobCost}</span>
											</strike>
											<span className="mt-2 job-cost-style"> ${discountedCost}</span>
										</>
									) : (
										<h6 className="mt-2">Total Cost - ${longJobCost}</h6>
									)}
									<h6 className="mt-2">Estimated job delivery request time - {estimatedDeliveryTime} hours</h6>
								</>
							)}
						</>
					)}

					{showApproveButtons && props.user.userType === 'customer' && (
						<>
							<Button
								className="btn app-btn w-100 mt-3 notes-submit-btn"
								onClick={approveLongJob}
								disabled={isButtonDisabled}
								title={isButtonDisabled ? isFixedJobApproved : ''}
							>
								<span />
								Approve
							</Button>

							<Button className="btn app-btn w-100 mt-3 app-btn-light-blue" onClick={rejectLongJob} disabled={isButtonDisabled}>
								<span />
								Reject
							</Button>
						</>
					)}
				</>
			)}

			{/* Condition altered to allow B2B customers to make long/job @Vinit - 11/Sep/2023 */}
			{/* {showLongJobButton && props.user.userType === 'technician' && !props.haveSubMin && ( */}
			{showLongJobButton && props.user.userType === 'technician' && (
				<Col xs={12}>
					<OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="button-tooltip">Cannot convert to project due to 100% discount coupon.</Tooltip>}
                    >
					<Button
						className="btn app-btn notes-submit-btn w-100 mt-3 p-0"
						onClick={openFillHourSection}
						disabled={isFullDiscountCoupon} // This disables the button
					>
						<span />
						Convert call to a project
					</Button>
					</OverlayTrigger>
				</Col>
			)}

			{fillHourSection && props.user.userType === 'technician' && (
				<Col xs={12}>
					<Radio.Group onChange={onRadioChange} value={radioValue} className="mt-4 w-100">
						<Radio value="fixed">Fixed hours</Radio>
						<Radio value="timely">Calculate per 6 minutes</Radio>
					</Radio.Group>

					{radioValue === 'fixed' && (
						<>
							<div className="mt-4 w-100">
								<label className="issue-list-item">
									Please provide an estimate of how long this job will take. This will help determine the price of this call.
								</label>
								<input
									type="number"
									onChange={changeHourValue}
									className="hoursClassInput form-control"
									placeholder="Please enter a number of hours."
									value={hoursValue}
								/>
							</div>
							{/* <label className="cost-cal-label">
								<i>
									Total price will be {hoursValue} x {props.job.software.hourlyRate} = ${calculatedCost}
								</i>
							</label> */}
						</>
					)}
					{radioValue === 'timely' && (
						<>
							<div className="mt-4 w-100">
								<Alert variant="info" className="w-100 mt-3">
									<FontAwesomeIcon icon={faInfoCircle} />
									&nbsp;&nbsp;
									<i>
										<em className="">
											If technician choose "Calculate per 6 minutes" option then its mandatory for the technician to{' '}
											<b>pause the meeting</b> before leaving the meeting page.
											<br />
											Otherwise meeting timer will continue for this job and it may generate a huge total cost.
										</em>
									</i>
								</Alert>
							</div>
						</>
					)}

					{radioValue && (
						<>
							<div className="mt-4 w-100">
								<label className="issue-list-item">Please provide an estimate of when job can be delivered</label>
								<input
									type="number"
									onChange={checkEstimtedTime}
									className="hoursClassInput form-control"
									placeholder="Please enter a number of hours."
									value={estimatedTime}
								/>
							</div>
							<label className="cost-cal-label">
								<i>In hours</i>
							</label>
						</>
					)}

					<Button className="btn app-btn w-100 mt-3 notes-submit-btn" onClick={sendHoursToCustomer} disabled={isButtonDisabled}>
						<span />
						Ready to continue?
					</Button>
				</Col>
			)}
		</Col>
	)
}

export default LongJobDetails
