import apiClient from './index'

/**
 * Add customer card
 * @params = data (Type: Object)
 * @response : Will add customer card
 * @author : Karan
 */

export async function addCardToCustomerStripe(data) {
	return apiClient.post('/stripe/addCustomerCard', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

/**
 * Create stripe customer
 * @params = data (Type: Object)
 * @response : Will create customer in stripe
 * @author : Karan
 */
export async function createCustomerStripe(data) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.post('/stripe/createStripeCustomer', data)
			.then((res) => {
				if (res && res.data) {
					response['success'] = res.data.error ? false : true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

/**
 * Retrieve stripe customer details
 * @params = customerId (Type: string)
 * @response : Will return stripe customer details
 * @author : Karan
 */
export async function retrieveCustomer(customerId, cuId) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.get(`/stripe/customer/${customerId}?cuId=${cuId}`)
			.then((res) => {
				if (res && res.data) {
					response['success'] = res.data.error ? false : true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

/**
 * Update stripe customer
 * @params = customerId (Type:string), data (Type: Object)
 * @response : Will update stripe customer
 * @author : Karan
 */
export async function updateCustomer(customerId, data) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.put(`/stripe/customer/${customerId}`, data)
			.then((res) => {
				if (res && res.data) {
					response['success'] = res.data.error ? false : true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

/**
 * Customer money deduction using stripe
 * @params = data (Type: Object)
 * @response : returns if the transaction was successful or not
 * @author : Manibha
 */
export async function takeChargeFromCustomer(data) {
	const response = {
		success: false,
		message: '',
		data: {},
	}
	try {
		return apiClient
			.post('/stripe/takeChargeFromCustomer', data)
			.then((res) => {
				if (res && res.data) {
					response['success'] = res.data.error ? false : true
					response['data'] = res.data
				}
				return response
			})
			.catch(function (error) {
				response['message'] = error.message
				return response
			})
	} catch (err) {
		response['message'] = err.message
		return response
	}
}

/**
 *
 * @param data
 * @returns response
 * @description  : This API will mark upcoming card as default card
 */
export async function updateDefaultCard(data) {
	return apiClient.post('/stripe/update-default-card', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
