import React from 'react'
import { Modal } from 'antd'
import PermissionImage from '../../../assets/images/permission.png'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Button from '@mui/material/Button'
import { openNotificationWithIcon } from '../../../utils'
import { MIC_PERMISSION_MODAL_CONTENT } from '../../../constants'
export const MicPermissionModal = ({ showMicPermissionModal, setShowMicPermissionModal, job, jitsiApi, userType }) => {
	const offMicPermissionModal = () => {
		setShowMicPermissionModal(false)
	}

	// This function will call to customer/technician through MicPermissionModal
	const callUser = () => {
		let finalPhoneNumber = ''
		if (userType == 'customer') {
			finalPhoneNumber = job?.technician?.profile?.confirmId?.phoneNumber
		}
		if (userType == 'technician') {
			finalPhoneNumber = job?.customer?.phoneNumber
		}
		console.log('JtisiContainer ::: Call through MicPermissionModal', finalPhoneNumber)
		// If finalPhoneNumber is availbale then we will call otherwise nothing will happened.
		if (finalPhoneNumber && finalPhoneNumber != '') {
			jitsiApi.invite([
				{
					allowed: true,
					number: finalPhoneNumber,
					originalEntry: finalPhoneNumber,
					showCountryCodeReminder: false,
					type: 'phone',
				},
			])
			openNotificationWithIcon('success', 'Successfully Invited')
			setShowMicPermissionModal(false)
		}
	}

	return (
		<Modal
			// visible={showMicPermissionModal}
			visible={false}
			onCancel={() => offMicPermissionModal()}
			width={800}
			footer={null}
			maskClosable={false}
			destroyOnClose={true}
			bodyStyle={{
				boxShadow: '-1px -1px 2px #FDFDFD, 2px 2px 6px #D2DBE2',
				background: '#FFFFFF',
				zIndex: 2,
			}}
		>
			<div className="mx-4">
				<HeaderOfModal />
				<GuildeLineForMicPermission />
				<CallUserModalSection callUser={callUser} />
			</div>
		</Modal>
	)
}

const HeaderOfModal = () => {
	const { callStartMsg, unblockMsg, continueByChatMsg } = MIC_PERMISSION_MODAL_CONTENT
	return (
		<div className="d-flex justify-content-center flex-column px-5 align-items-center">
			<p className="call-start-msg-style">{callStartMsg}</p>
			<p className="microphone-unblock-heading">{unblockMsg}</p>
			<p className="phone-chat-text-style">{continueByChatMsg}</p>
		</div>
	)
}

const GuildeLineForMicPermission = () => {
	return (
		<div className="row instruction-modal-css mb-4">
			<img src={PermissionImage} className="img-fluid col-md-5 permission-img-style" alt="Microphone Permission" />
			<div className="col-md-7 microphone-text-style">
				<p className="fst-italic fw-bold" style={{ fontSize: 16 }}>
					How do i unblock my audio?
				</p>
				<p>
					1. <span className="fw-bold">Click </span>the small <LockOpenIcon /> button{' '}
					<span className="fw-bold">located at the TOP-LEFT</span> of your screen beside the web address.
				</p>
				<p>
					2. <span className="fw-bold">Enable</span> your microphone.
				</p>
				<p>
					3. If you’re having trouble, you can <span className="fw-bold">request a regular phone call</span> from your Geek below.
				</p>
			</div>
		</div>
	)
}

const CallUserModalSection = ({ callUser }) => {
	return (
		<div className="d-flex justify-content-center flex-column px-5 align-items-center">
			<p className="microphone-unblock-heading">Need help ?</p>
			<Button variant="contained" onClick={callUser} className="phone-chat-btn-style ">
				Continue via phone / chat
			</Button>
			<p className="fst-italic phone-chat-text-style my-3" style={{ fontSize: '1.3rem' }}>
				Your Geek will call your phone in a few seconds.
			</p>
		</div>
	)
}
