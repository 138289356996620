import React, { useState } from 'react'
import { Modal as AntModal, Typography, Form, Input, Spin } from 'antd'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from './Box'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { JITSI_URL_GETGEEKER } from '../../constants'
import { DashboardTab, DashboardTabPane } from '../Dashboard/Tabs'
import * as TypeServiceApi from '../../api/typeService.api'
import { openNotificationWithIcon } from '../../utils'
import mixpanel from 'mixpanel-browser'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

const DialInOutModal = ({
	isOpen,
	onClose,
	setInvitation,
	setInvitedNumber,
	JobId,
	setIsDialInOutModalOpen,
	UserType,
	UserEmail,
	meetingPlatform,
	setIsInvitedPhone,
}) => {
	const isPhoneSelected = false
	const [editPhone, setEditPhone] = useState(false)
	const [disableButton, setDisableButton] = useState(false)
	const [dialCode, setDialCode] = useState(false)
	const [form] = Form.useForm()
	// Invite use for meeting by phone number
	const inviteWithPhoneButton = () => {
		if (editPhone) {
			setDisableButton(true)
			inviteWithPhone(editPhone)
		} else {
			setDisableButton(false)
			openNotificationWithIcon('error', 'Error', 'Please input a number.')
		}
	}

	function inviteWithPhone(editPhone) {
		// If User again open the same popup without refresh then at that time this code will add +mobileCode in number
		if (!editPhone.includes(`+${dialCode}`)) {
			editPhone = `+${dialCode}${editPhone}`
		}

		if (isNotValidNumber(editPhone)) {
			openNotificationWithIcon('error', 'Error', 'Not a valid Phone Number')
			// If Phone number is not valid then we will stop the spin(loader)
			setDisableButton(false)
			return false
		}
		let randomString = (Math.random() + 1).toString(36).substring(7)
		setIsInvitedPhone(randomString)
		setInvitation(true)
		setInvitedNumber(editPhone)
		// This will split countrycode from phone number and reassign to state setEditPhone
		const tempEditPhone = editPhone.split(`+${dialCode}`)[1]
		setEditPhone(tempEditPhone)
		setIsDialInOutModalOpen(false)
		setDisableButton(false)
		// mixpanel code
		mixpanel.identify(UserEmail)
		mixpanel.track(`${UserType} Invite someone by phone`, { JobId: JobId })
		// mixpanel code
	}

	function isNotValidNumber(editPhone) {
		if (isPossiblePhoneNumber(editPhone) === false && isValidPhoneNumber(editPhone) === false) {
			return true
		} else {
			return false
		}
	}

	// send meeting invitation link in email
	const onFinish = async (values) => {
		if (values && values['email']) {
			setDisableButton(true)
			const jobIdSplit = JobId.split('_')
			const link = JITSI_URL_GETGEEKER + jobIdSplit[1]
			await TypeServiceApi.sendJitsiInvitation({ email: values['email'], link: link })
			// mixpanel code
			mixpanel.identify(JobId)
			mixpanel.track(`${UserType} Invite someone by email`, { JobId: JobId })
			// mixpanel code
			setIsDialInOutModalOpen(false)
			openNotificationWithIcon('success', 'Success', 'Meeting link has been sent!.')
			setDisableButton(false)
			// mixpanel code
			mixpanel.identify(UserEmail)
			mixpanel.track(`${UserType} Invite someone by email ${values['email']}`, { JobId: JobId })
			// mixpanel code
		} else {
			setDisableButton(false)
			openNotificationWithIcon('error', 'Error', 'Please input a email.')
		}
	}

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo)
	}

	/**
	 *
	 * @param : value ,data,event,formattedValue
	 * @description : This function will store the phone Number with + and countrycode and country code
	 * @author : Jagroop
	 *
	 */
	function handleOnChange(value, data, event, formattedValue) {
		console.log('data of function handleOnChange', { data, value, event, formattedValue })
		const dialCode = data?.dialCode
		setDialCode(dialCode)
		setEditPhone(`+${value}`)
	}

	return (
		<div>
			<Modal
				visible={isOpen}
				onCancel={onClose}
				footer={false}
				bodyStyle={{ height: 290 }}
				maskClosable={false}
				width={400}
				title="Invite more people"
				className="invite_user_modal"
			>
				<DashboardTab>
					<DashboardTabPane tab="Phone" key="1">
						<Box>
							<Label className="steplable">Invite your contacts</Label>
							<CallDiv>
								<div className="d-flex justify-content-between w-100">
									<PhoneInput
										country="us"
										countryCodeEditable={false}
										onlyCountries={['gr', 'fr', 'us', 'in', 'ca', 'gb']}
										value={null}
										placeholder=""
										onChange={handleOnChange}
										className="h-30"
									/>
								</div>
							</CallDiv>
						</Box>
						<div style={{ marginTop: '24px' }} className="">
							<Button
								className={(isPhoneSelected ? 'd-none' : '') + ' app-btn invite-pin-btn app-btn-small'}
								onClick={() => inviteWithPhoneButton()}
								disabled={disableButton}
							>
								{disableButton ? <Spin /> : 'Invite'}
								<span></span>
							</Button>
						</div>
					</DashboardTabPane>
					<DashboardTabPane tab="Email" key="2">
						<Form
							name="basic"
							form={form}
							labelCol={{
								span: 8,
							}}
							initialValues={{
								remember: true,
							}}
							autoComplete="off"
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Box>
								<Label className="steplable">Invite user by mail</Label>
								<CallDiv>
									<Form.Item name="email">
										<Input className="h-30" />
									</Form.Item>
								</CallDiv>
							</Box>
							<div className="">
								<Button
									htmlType="submit"
									className={(isPhoneSelected ? 'd-none' : '') + ' app-btn invite-pin-btn app-btn-small'}
									onClick={() => form.submit()}
									disabled={disableButton}
								>
									<span></span>
									{disableButton ? <Spin /> : 'Invite'}
								</Button>
							</div>
						</Form>
					</DashboardTabPane>
				</DashboardTab>
			</Modal>
		</div>
	)
}

const Modal = styled(AntModal)`
	.steplable {
		font-size: 18px;
	}
	.ant-modal-content {
		border-radius: 10px;
	}
	.ant-typography {
		margin-bottom: 8px;
	}
	.pin-modal-btn {
		background: #464646 !important;
		color: #fff !important;
		border-color: #464646 !important;
		padding: 5px 30px;
		border-radius: 8px;
		cursor: pointer;
	}

	.pin-message {
		font-weight: 600;
		padding: 10px 2px;
		height: 120px;
		text-align: center;
	}
	.d-none {
		display: none;
	}
	.dial-in-options {
		margin-top: 30px;
	}
	.footer-btns {
		display: block;
		text-align: right;
		top: 15px;
	}
	.footer-btns .pin-modal-btn {
		margin-left: 15px;
	}
	.footer-btns .back-btn {
		background: none !important;
		color: #464646 !important;
		border: solid 2px;
	}
	. .pin-modal-btn.selected-btn {
		background: #464646 !important;
		color: #fff !important;
		border-color: #464646 !important;
		opacity: 1;
	}
`

const Label = styled(Typography)`
	font-size: 14px;
`

const CallDiv = styled.div`
	display: flex;
	justify-content: space-between;
`

DialInOutModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
}

export default DialInOutModal
