import React, { useEffect, useState } from 'react'
import { getPartnerWithShortName } from '../api/partner.api'
import imagelogo from '../assets/images/newLogo.png'

const GeekerLogo = (props) => {
  const [usingCoBrandingLogo, setUsingCoBrandingLogo] = useState(false)
  const [logo, setLogo] = useState(() => {
    const coBrandingLogoUrl = localStorage.getItem('coBrandingLogoUrl')
    return coBrandingLogoUrl || props?.logo || imagelogo
  })
  const job = props?.job;

  useEffect(() => {
    const fetchCoBrandingLogo = async () => {
      try {
        if (props?.user?.userType === 'customer') {
          const coBrandingLogoUrl = localStorage.getItem('coBrandingLogoUrl')
          if (coBrandingLogoUrl) {
            setUsingCoBrandingLogo(true)
          }
          if (!coBrandingLogoUrl) {
            const partnerName = job?.customerPartner

            if (partnerName) {
              const response = await getPartnerWithShortName(partnerName)
              if (response.success) {
                const newCoBrandingLogoUrl = response.data.coBrandingLogoUrl
                setLogo(newCoBrandingLogoUrl)
                localStorage.setItem('coBrandingLogoUrl', newCoBrandingLogoUrl)
                setUsingCoBrandingLogo(true)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchCoBrandingLogo()
  }, [props])

  return (
    <img
      className={props.className}
      src={logo}
      alt="GeekerLogo"
      style={
        usingCoBrandingLogo ?
          { width: props.CoBrandingWidth ? props.CoBrandingWidth : "100%", ...props.styles } :
          { width: props.width ? props.width : "", ...props.styles }
      }
    />
  );
};

export default GeekerLogo
