import apiClient from './index'

export async function getUserByParam(data) {
	return apiClient.post('/users/get_user_by_param', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
export async function updateUserVolumeSettings(id, newVolume, muteStatus) {
	try {
	  console.log('updateUserVolumeSettings api', id, newVolume,muteStatus)
	  const response = await apiClient.post(`/users/updateUserVolumeSettings/${id}`, {
		volume: newVolume,
		isMuted: muteStatus,
	  });
	  console.log('updateUserVolumeSettings',response)
	  if (response) {
		  return response.data;
	  }
  
	  return Promise.reject();
	} catch (error) {
	  console.error('Error updating volume settings:', error);
	  return Promise.reject(error);
	}
  }

