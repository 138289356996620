import '../../logrocketSetup'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import Header from '../Common/Header'
import { redirectToApp, getCookie } from '../../utils'
import { JOB_STATUS, DATE_OPTIONS } from '../../constants'
import { getJobInformation } from '../../api/meeting.api'
import { getUserByParam } from '../../api/user.api'

const Meeting = () => {
	const { jobId } = useParams()
	const { userTypeParams } = useParams()
	const [userType, setUserType] = useState(userTypeParams)
	const [user, setUser] = useState(null)
	const [userId, setUserId] = useState(null)

	useEffect(() => {
		const userId = getCookie('user_id')
		setUserId(userId)
	}, [])

	useEffect(() => {
		if (userId != null) {
			fetchUser(userId)
		}
	}, [userId])

	useEffect(() => {
		;(async () => {
			if (user) {
				/**
				 * Get job information
				 * @params = jobId (Available in url)
				 * @response : Return all the job information
				 * @author : Karan
				 */
				let jobInfo = await getJobInformation(jobId)
				jobInfo = jobInfo && jobInfo.data ? jobInfo.data : {}

				if (jobInfo && jobInfo.id) {
					if (jobInfo.status === JOB_STATUS.IN_PROGRESS) {
						window.location.href = `/meeting/${userType}/${jobInfo.id}`
					}
				} else {
					window.location.href = `/invalid-meeting/${userType}/${jobId}`
				}
			}
		})()
	}, [user])

	/**
	 * Fetch user details
	 * @params = userId (Type:string)
	 * @response : Return user details
	 * @author : Karan
	 */
	const fetchUser = async (userId) => {
		const res = await getUserByParam({ userId: userId, cuId: userId })
		if (res) {
			DATE_OPTIONS['timeZone'] = res.timezone ? res.timezone : DATE_OPTIONS['timeZone']
			setUser(res)
			setUserType(res.userType)
		}
	}

	return (
		<Row>
			<Col lg="12" style={{ height: '100vh' }}>
				<Row>
					<Col md="4" lg="4" className="m-auto text-center pt-5">
						<Header />
					</Col>

					<Col md="12" lg="12" className="text-center mt-5">
						<h2 className="">
							<span>Your meeting is over.</span>
							<span className="mt-4 d-block w-100">Thankyou for using Geeker service.</span>
							<span className="mt-4 d-block w-100">
								<Button onClick={() => redirectToApp('dashboard')}>Click here </Button> to open home page.
							</span>
						</h2>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Meeting
