import Close from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import troubleshootLens from '../../../assets/images/troubleshootLens.png'
import { startTimer, updateCustomerStartMeetingResponse } from '../../../utils'

const LetsTroubleShootModal = ({
	setShowUpdateJobModal,
	troubleShootModal,
	setModalAction,
	job,
	setLeaveTheMeeting,
	socket,
	startBilledMeeting,
	countdown,
	user,
}) => {
	const handleTransfer = () => {
		updateCustomerStartMeetingResponse(job.id, 'Transfer', user.id)
		setShowUpdateJobModal(true)
		setModalAction('resetAll')
		// console.log('handleTransfer - LetsTroubleShootModal')
	}

	const handleEndMeeting = () => {
		// console.log('handleEndMeeting - LetsTroubleShootModal')
		// updateCustomerStartMeetingResponse(job.id, 'End Meeting')
		setLeaveTheMeeting(true)
	}

	const handleClose = () => {
		setModalAction('resetAll')
		startBilledMeeting(true)
	}
	const handelStartMeeting = () => {
		startBilledMeeting(false)
		let data = { meetingStatus: 'meetingHasStarted', jobId: job.id }
		socket.emit('meeting-started-by-customer', data)
	}

	return (
		<>
			<Modal visible={troubleShootModal} width={800} closable={false} footer={null} className={'customer-meeting-confirmation-modal'}>
				{/* <div className="cross-btn-div">
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</div> */}
				<div className="customer-meeting-confirmation-modal-heading">
					<img src={troubleshootLens} className="customer-meeting-confirmation-modal-image" />
					<span className="customer-meeting-confirmation-modal-heading-text">Let's Troubleshoot!</span>
				</div>
				<div className="text-center">
					<span className="customer-meeting-confirmation-modal-text-span">
						Your issue needs further diagnosis and your Geek is ready to troubleshoot. Let's go!
					</span>
				</div>
				<div className="text-center">
					<span className="customer-meeting-confirmation-modal-text-span">
						<i>*By continuing, you understand you're paying for your Geek's time, and a solution can't be guaranteed.</i>
					</span>
				</div>
				<div className="customer-meeting-confirmation-modal-button-cluster">
					<div className="customer-meeting-confirmation-modal-button-div">
						{/* <Button variant="outlined" className="customer-meeting-confirmation-modal-button-outlined" onClick={handleTransfer}>
							<span>Transfer</span>
						</Button> */}
						<Button variant="contained" className="customer-meeting-confirmation-modal-button" onClick={handelStartMeeting}>
							<span>Start Billed Meeting ({countdown})</span>
						</Button>
					</div>
					<div className="customer-meeting-confirmation-modal-button-3" onClick={handleEndMeeting}>
						<span>End Meeting</span>
					</div>
				</div>
				{/* <div className="text-center">
					<span className="customer-meeting-confirmation-modal-text-span-2">
						Clicking "Transfer" will return you to the search queue and restart the "Get help now!" timer.
					</span>
				</div> */}
			</Modal>
		</>
	)
}

export default LetsTroubleShootModal
