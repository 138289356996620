import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import AppProviders from './context/AppProviders'

const root = createRoot(document.getElementById('root'))

if (root) {
	root.render(
		<AppProviders>
			<App />
		</AppProviders>,
	)
}
