import React from 'react'

function TechTutorial() {
	return (
		<>
			<ul>
				<li>
					<p>Make Your self active&nbsp;on the dashboard so that you can begin to receive jobs.</p>
				</li>
			</ul>
			<p>
				<br />
			</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/active_tech.png')} alt="activeTech" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						Click&nbsp;<b>Accept Job</b>&nbsp;so that you can be connected. The system will wait for the customer to start the call, so
						hang tight :-)&nbsp;
					</p>
				</li>
			</ul>
			<p>
				<br />
			</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/tech_accepts_job.png')} alt="techAccept" />
			</p>
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						Give the customer 1-2 minutes&nbsp;to connect to the call. If they do not connect, click the&nbsp;<b>Start Call</b>
						&nbsp;button and it will call the customer’s cell phone.
					</p>
				</li>
			</ul>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/start_call.png')} alt="startCall" />
			</p>
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						Instruct the Customer to click&nbsp;<b>JOIN</b>&nbsp;on their screen in order to join the meeting.
					</p>
				</li>
			</ul>
			<p>
				<br />
			</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/join_btn_customer.png')} alt="join_btn" />
			</p>
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						Customer to click&nbsp;<b>Computer Audio</b>&nbsp;button on their screen to change from phone. &nbsp;&nbsp;
					</p>
				</li>
			</ul>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/computer_audio.png')} alt="computerAudio" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						To <b>share screen</b> have customer click the <b>Share</b> button on their screen
					</p>
				</li>
			</ul>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/howto_share_screen.png')} alt="shareScreen" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						For <b>Remote Control</b> click <b>Enable Remote</b>
					</p>
				</li>
			</ul>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/howto_remote.png')} alt="HowTo" />
			</p>
			<p>
				<br />
			</p>
			{/* <ul>
				<li>
					<p>&nbsp;Summarize the issue on the dashboard</p>
					<ul>
						<li>
							<p>Time will be started automatically</p>
						</li>
						<li>
							<p>To start remote session with customer</p>
						</li>
					</ul>
				</li>
			</ul> */}
			{/* <p>
				<br />
			</p>

			<p className="center-align">
				<img className="img-fluid"
					src="https://lh6.googleusercontent.com/UGdt-xoOgJKTZNfUNiQAW-9sDDsvO5dHdRA7iuh1-qyFw9b_j0PbhHtja-gLFjcyeVyqlfpZfp-hqpXK_k2cQtVezXGdP36s9gNCp6e99t7h3s5rrztsa_h_oFbBvipqM27xfA5jNqWvODSYnA"
					"
			
				/>
			</p> */}
			<p>
				<br />
			</p>
			<ul>
				<li>
					<p>
						If it doesnt open a new window for you, look for this pop up. You must click&nbsp;<b>Always allow pop-ups and redirects</b>
					</p>
				</li>
			</ul>
			<p>
				<br />
			</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/always_allow_pop_up.png')} alt="Always" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<b>Transfers</b>
			</p>
			<p>If you can't fix it or a different skill&nbsp;</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/transfer_job.png')} alt="transferJob" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<b>Project</b>
			</p>
			<p>Click convert to project&nbsp;</p>
			<p className="center-align">Select the appropriate option </p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/howto_longjob.png')} alt="Project" />
			</p>
			<p>
				<br />
			</p>
			<p className="center-align">Have customer approve&nbsp;</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/approve.png')} alt="Approve" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p className="center-align">You can click back to Dashboard &nbsp;</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/dashboard.png')} alt="Dashboard" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<ul>
					<li>
						The technician will find their extended job on their Dashboard under <b>Available Jobs</b>
					</li>
					<li>
						On the job, click <b>Details</b>
					</li>
					<li>
						Under the Conversations tab, you can chat with the Customer. This is the <b>ONLY</b> place where you will keep contact with
						the customer. <b>Do NOT </b> send emails to them.
					</li>
				</ul>
			</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/howto_converse.png')} alt="HowConversion" />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p className="center-align">
				{' '}
				<b>What happens when you click remote?</b>
			</p>
			<p>It will open automatically a new window for the tech and customer pre filled with the session information</p>
			<p>Instruct customer to hit Join session, Then download, Then open the downloaded file, then to click join</p>
			<p>
				<br />
			</p>
			<p>
				<br />
			</p>
			<p className="center-align">
				<img className="img-fluid" src={require('../../assets/images/remote.png')} alt="Remote" />
			</p>
		</>
	)
}

export default TechTutorial
