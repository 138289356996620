import React, { useState } from 'react'
// import Loader from '../components/Loader';
// import { openNotificationWithIcon } from '../utils';

const ToolsContext = React.createContext({})

function ToolsContextProvider(props) {
	const [jobId, setJobId] = useState('')
	const [typeForDetails, setTypeForDetails] = useState('')
	const [stepDeciderForDashboard, setStepDeciderDashboard] = useState(0)
	const [hideBadge, sethideBadge] = useState(false)
	const [openTechModal, setOpenModal] = useState(false)
	const [openMobileDialogBox, setOpenMobileDialogBox] = useState(false)
	let browserNotificationShown = []

	return (
		<ToolsContext.Provider
			value={{
				jobId,
				typeForDetails,
				setJobId,
				setTypeForDetails,
				stepDeciderForDashboard,
				setStepDeciderDashboard,
				sethideBadge,
				openTechModal,
				setOpenModal,
				hideBadge,
				browserNotificationShown,
				openMobileDialogBox,
				setOpenMobileDialogBox,
			}}
			{...props}
		/>
	)
}

function useTools() {
	const context = React.useContext(ToolsContext)
	if (context === undefined) {
		throw new Error('toolsContext must be with in Tools context provider')
	}

	return context
}

export { ToolsContextProvider, useTools }
