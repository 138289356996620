import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from 'antd'
import styled from 'styled-components'
import Box from './Box'
import StepButton from '../StepButton'
import ExtensionModal from './ExtensionModal'
import DialInOutModal from './DialInOutModal'
//import mixpanel from 'mixpanel-browser'
const { Text } = Typography

const ConfirmTechMatch = ({ ...props }) => {
	const history = useHistory()
	const jobId = props.jobId
	const [isExtensionModalOpen, setIsExtensionModalOpen] = useState(false)
	const [isDialInOutModalOpen, setIsDialInOutModalOpen] = useState(false)

	useEffect(() => {
		if (props.socket) {
			props.socket.emit('join', jobId)
			props.socket.on('match-confirmed', (isMatched) => {
				if (isMatched) {
					props.setCurrentStep(1)
				} else {
					history.replace('/dashboard')
				}
			})

			props.socket.on('start-remote-desktop', (result) => {
				if (jobId === result.id) {
					window.open('https://remotedesktop.google.com/support', 'Generate Code', 'height=600,width=800')
				}
			})
		}
	}, [history, props.jobId, props.setCurrentStep, props.socket])

	/*const endPinModal = () => {
    setIsPinModalOpen(false);
  };*/

	/*const startChromeExtension = () => {
    props.socket.emit('dial-number', { id: jobId });
    setIsExtensionModalOpen(true);
  }*/

	const endChromeExtension = () => {
		setIsExtensionModalOpen(false)
		// setIsPinModalOpen(true);
		// setopenFirstModal(true);
	}
	const onSubmit = () => {
		props.setInvitation(true)
	}

	const openRemoteDesktopInstallWindow = () => {
		try {
			if (props.socket) {
				props.socket.emit('remote-desktop-triggered', jobId)
			}
		} catch {
			console.log('functions not found')
		}
	}

	const openDialInModal = () => {
		setIsDialInOutModalOpen(true)
	}
	const closeDialInModal = () => {
		setIsDialInOutModalOpen(false)
	}
	return (
		<div>
			{/* <h2>Let&apos;s confirm your tech can resolve your issue</h2>
      <div>Please demonstrate the issue you are trying to resolve</div>
      <br />
      <h2>Waiting for tech Input....</h2>*/}

			{props.invited && (
				<Box marginTop={30} className="d-none">
					<h3>&nbsp;</h3>
					<StyledButton
						ref={props.remoteDesktopRef}
						onClick={openRemoteDesktopInstallWindow}
						title="If necessary, you can offer your technician the ability to control your desktop."
					>
						Start remote desktop session
					</StyledButton>
					<Text className="text-below">
						* Please note: Remote desktop requires software to be downloaded and installed to your computer and may require guidance from
						the technician to start a session.{' '}
					</Text>
				</Box>
			)}
			<ExtensionModal onClose={endChromeExtension} isOpen={isExtensionModalOpen} />

			<Box marginTop={30} className="d-none">
				<StyledButton onClick={openDialInModal} ref={props.dialInRef} title="" className="call_options">
					Invite to call
				</StyledButton>
			</Box>
			<DialInOutModal
				onClose={closeDialInModal}
				isOpen={isDialInOutModalOpen}
				enableComputerAudio={props.enableComputerAudio}
				disableComputerAudio={props.disableComputerAudio}
				onSubmit={onSubmit}
				setInvitation={props.setInvitation}
				setInvitedNumber={props.setInvitedNumber}
				JobId={props.jobId}
				UserType={props.UserType}
				setIsDialInOutModalOpen={setIsDialInOutModalOpen}
				meetingPlatform={props.meetingPlatform}
				setIsInvitedPhone={props.setIsInvitedPhone}
			/>
		</div>
	)
}

/*const ProgressStyled = styled(Progress)`
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: #464646;
  }
  .ant-progress-text {
    color: white !important;
  }
`;*/

const StyledButton = styled(StepButton)`
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: 0px !important;
	span {
		white-space: break-spaces;
		margin-left: 15px;
	}
`

export default memo(ConfirmTechMatch)
