import { decryptStripeKey } from '../utils/decryption'

export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
export const SERVER_URL = process.env.REACT_APP_SERVER_BASE_URL + '/api'
export const MAIN_APP_URL = process.env.REACT_APP_MAIN_APP_URL
export const SERVER_BACKEND_BASE_URL = process.env.REACT_APP_SERVER_BACKEND_BASE_URL
export const FILE_SIZE_MSG = 'File should be smaller than 100 MB'

export let LONGJOB_MINPOP = 1500
export const JOB_STATUS = {
	PENDING: 'Pending',
	WAITING: 'Waiting',
	SCHEDULED: 'Scheduled',
	IN_PROGRESS: 'Inprogress',
	COMPLETED: 'Completed',
	EXPIRED: 'Expired',
	DECLINED: 'Declined',
	LONGJOB: 'long-job',
}
export const JITSI_BASE_URL_GETGEEKER = process.env.REACT_APP_JITSI_BASE_URL_GETGEEKER
export const JITSI_URL_GETGEEKER = 'https://' + JITSI_BASE_URL_GETGEEKER
export const LOGROCKET = decryptStripeKey(process.env.REACT_APP_LOGROCKET_KEY)
export const TOTAL_FREE_SECONDS = 360 //6 Minutes
export const POPUP_SHOW_AFTER_SECONDS = TOTAL_FREE_SECONDS - 120 //4 Minutes
export const WARNING_POPUP_SECONDS = 120 //2 Minutes
export const LAST_MINUTE_POPUP_SECONDS = 60 // 1 Minute
export const FREE_TIME_EXPIRING_POPUP_SECONDS = 120 //2 Minutes
export const SPARE_TIME_SECONDS = 60 //1 Minute
export const FREE_TIME_EXPIRING_SHOW_AFTER_SECONDS = TOTAL_FREE_SECONDS - 120 //4 Minutes
export const MEETING_END_TIME_SECONDS = TOTAL_FREE_SECONDS + SPARE_TIME_SECONDS //After 7 Minutes
export const ROOT_HOSTNAME = process.env.REACT_APP_ROOT_HOSTNAME
export const ROLE_STATUS = {
	OWNER: 'owner',
	USER: 'user',
}

export const JOB_CHARACTERS_ALLOWED = 500
export const PRE_AUTHORIZATION_POPUP_TIMER = 3600000
export const PAYMENT_CARD_MODAL_ON_TIME_LIMIT = 300000 // 5 minutes
export const DATE_OPTIONS = {
	weekday: 'short',
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
	timeZone: 'America/New_York',
}
export const MIXPANEL_KEY = decryptStripeKey(process.env.REACT_APP_MIXPANEL_KEY)
export const REACT_APP_ROLLBAR_TOKEN = process.env.REACT_APP_ROLLBAR_TOKEN
export const JobTags = {
	CUSTOMER_JITSI_START: 'customer_jitsi_start',
	TECHNICIAN_JITSI_START: 'technician_jitsi_start',
	PAID_TIME_START_FROM_TECH: 'paid_timer_start_from_technician',
	PAID_TIME_START_FROM_CUST: 'paid_timer_start_from_customer',
	CUSTOMER_END_MEETING: 'customer_end_meeting',
	TECHNCIIAN_END_MEETING: 'technician_end_meeting',
	TECHNICIAN_TRANSFER: 'technician_transfer_call',
	CUSTOMER_TRANSFER: 'customer_transfer_call',
	TECHINCIAN_SELECT_FIXED_LONG_JOB: 'technciian_select_fixed_project',
	TECHINCIAN_SELECT_MINUTES_LONG_JOB: 'technciian_select_minutes_project',
	CUSTOMER_APPROVE_LONG_JOB: 'customer_approve_project',
	CUSTOMER_REJECT_LONG_JOB: 'customer_reject_project',
	TECHNICIAN_JITSI_START_AFTER_TRANSFER: 'technician_jitsi_start_after_transfer',
	CUSTOMER_JITSI_START_AFTER_TRANSFER: 'customer_jitsi_start_after_transfer',
	PAID_TIME_START_AFTER_TRANSFER_FROM_TECH: 'paid_timer_start_transferred_job_from_technician',
	PAID_TIME_START_AFTER_TRANSFER_FROM_CUST: 'paid_timer_start_transferred_job_from_customer',
	CUSTOMER_END_MEETING_AFTER_TRANSFER: 'customer_end_meeting_transferred_job',
	TECHNCIIAN_END_MEETING_AFTER_TRANSFER: 'technician_end_meeting_transferred_job',
	TECH_UPDATE_NOTES: 'technician_update_notes',
	TECH_INSERT_NEW_NOTES: 'technician_create_new_notes',
	TECH_EDIT_NOTES: 'technician_edit_notes',
	TECH_DELETE_NOTES: 'technician_delete_notes',
}
export const addCardCountDownTimer = 300000

export const FULLSTORY_KEY = decryptStripeKey(process.env.REACT_APP_FULLSTORY_KEY)
export const TWILIO_CHAT_USERNAME = decryptStripeKey(process.env.REACT_APP_TWILIO_CHAT_USERNAME)
export const TWILIO_CHAT_PASSWORD = decryptStripeKey(process.env.REACT_APP_TWILIO_CHAT_PASSWORD)

export const MIC_PERMISSION_MODAL_CONTENT = {
	callStartMsg: 'Your call is starting!',
	unblockMsg: 'Unblock your microphone so that your geek can hear you.',
	continueByChatMsg: 'Or continue via regular phone / chat.',
}

/**
 *
 * @returns nothing
 * @description : This function is used to clear required local and session storage
 * @author : Jagroop
 */
export const removeLocalAndSessionStorage = (jobId) => {
	try {
		window.localStorage.removeItem('longJobPopUp')
		window.sessionStorage.removeItem('reconnected')
		window.localStorage.removeItem(`timerPaused-${jobId}`)
		window.sessionStorage.removeItem('safariBrowser')
		window.localStorage.removeItem('card-modal-open')
		window.localStorage.removeItem(`${jobId}-in-call`)
		window.localStorage.removeItem(`${jobId}-meeting-retires`)
		window.localStorage.removeItem('usingComputerAudio')
		window.localStorage.removeItem('customerCallingHimSelf')
		window.sessionStorage.removeItem('unMuteCusomter')
		window.sessionStorage.removeItem('alreadyInCallPopUpOpen')
		sessionStorage.removeItem('dontShowWelcomePartnerPopup')
		localStorage.removeItem('coBrandingLogoUrl')
		localStorage.getItem(`showZohoLink-${jobId}`);
		return
	} catch (error) {
		console.log('error while removing local and session storage')
		return
	}
}

export const MEETING_ONBOARD_MSG = {
	TAKEOFF: 'Prepare for takeoff!',
	ABOUT_TO_JOIN: ' is about to join the meeting.',
	HELP: 'Need help connecting?',
	GRAB_PHONE: 'Please wait',
	GEEKER_CALL: '📞 Your Geek will call from ',
	CONTACT_NUMBER: '907 268 6284',
	ONE_MINUTE: '(This usually takes less than a minute)',
}

export const paymentType = {
	SUBSCRIPTION_ONLY: 'subscription_only',
	CARD_ONLY: 'card_only',
	SUBSCRIPTION_AND_CARD: 'subscription_and_card',
}
export const FIRST_JOBPOP = 0;

export const TOOLTIP_MSG_FOR_REMOTE_BTN = 'Remote access will be available once billing starts.'
export const TOOLTIP_MSG_FOR_ENABLE_REMOTE_BTN = 'The remote access file was sent to the customer. Please wait 5 seconds before retrying'
export const TOOLTIP_MSG_FOR_ENABLE_REMOTE_BTN_CUSTOMER = 'The remote access file was sent to the technician. Please wait 5 seconds before retrying'
export const SESSION_EXPIRY_MSG = 'Session expired. To resume, kindly log back in and rejoin the meeting.'
export const DISCOUNT_COUPON = 100
export const PARTNER_NAME = 'positive'
export const SCREEN_CONNECT_URL = 'https://geeker.screenconnect.com/Host#Support/All%20Sessions//412d00d6-be15-402e-a43f-c2273a46cfa5/Start'