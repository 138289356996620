import React, { useState, useEffect, useRef } from 'react'
import { Container, Col, Row, Modal, Button } from 'react-bootstrap'
import { Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { BsX } from 'react-icons/bs'
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded'
import { Spin } from 'antd'
import { JobTags } from '../../constants'
import * as JobCycleApi from '../../api/jobCycle.api'
import { removeTechnicianNotesById, updateJob, getJobInformation } from '../../api/meeting.api'
import { openNotificationWithIcon } from '../../utils'

function MyVerticallyCenteredModal(props) {
	const [textArea, setTextArea] = useState('')
	const [allNotesData, setAllNotesData] = useState([])
	const [fetchAllNotes, setFetchAllNotes] = useState([])
	const [isEditNotes, setIsEditNotes] = useState(false)
	const [updateTextId, setUpdateTextId] = useState(null)
	const textareaRef = useRef(null)
	const notesContainerRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const [showErrorMess, setShowErrorMess] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [noteIdToDelete, setNoteIdToDelete] = useState(null)
	const [showDeleteLoader, setShowDeleteLoader] = useState(false)
	const [characterCount, setCharacterCount] = useState(textArea.length)
	const [charLeftColor, setCharLeftColor] = useState(false)
	const [count, setCount] = useState(500)
	const [scrollToNotes, setScrollToNote] = useState(false)

	useEffect(() => {
		;(async () => {
			try {
				if (props?.user?.id === props?.job?.technician?.user?.id) {
					let updatedNotes = []
					if (!updateTextId) {
						await JobCycleApi.create(JobTags.TECH_INSERT_NEW_NOTES, props.job.id)
						if (allNotesData && allNotesData.length > 0) {
							let jobInfoResponse = await getJobInformation(props.job.id, props?.user?.id)
							const existingJob = jobInfoResponse.success ? jobInfoResponse.data : {}
							if (existingJob && existingJob.technician_notes) {
								const newNotes = allNotesData.filter((newNote) => {
									return !existingJob.technician_notes.some((existingNote) => existingNote.textArea === newNote.textArea)
								})
								const isNotesIncluded = existingJob.technician_notes.some((noteObj) => noteObj.textArea === textArea.trim())
								if (isNotesIncluded) {
									openNotificationWithIcon('error', 'Error', 'Note already exist')
								}
								// console.log("Submit notes>>>>>>>>>>>>>>> in meeting insert")
								updatedNotes = existingJob.technician_notes.concat(newNotes)
							} else {
								// console.log("Submit notes>>>>>>>>>>>>>>> in meeting new insert")
								updatedNotes = allNotesData
							}
							const updateResponse = await updateJob(props.job.id, {
								dataToUpdate: { technician_notes: updatedNotes },
								cuId: props?.user?.id,
							})
							if (updateResponse && updateResponse.data.technician_notes) {
								// console.log('Response after submit new notes>>>>>>>>>', updateResponse.data.technician_notes)
								setLoading(false)
								setTextArea('')
								setShowErrorMess(false)
								setCharLeftColor(false)
								setCount(500)
								setCharacterCount(0)
								setAllNotesData([])
								setFetchAllNotes(updateResponse.data.technician_notes)
								setScrollToNote(true)
							}
						}
					}
				}
			} catch (error) {
				console.error('Error occurred while updating job:', error)
			}
		})()
	}, [allNotesData])

	useEffect(() => {
		if (scrollToNotes) {
			if (notesContainerRef && notesContainerRef.current) {
				const notesContainer = notesContainerRef.current
				if (notesContainer) {
					notesContainer.scrollTop = notesContainer.scrollHeight
					setScrollToNote(false)
				}
			}
		}
	}, [scrollToNotes, allNotesData])

	useEffect(() => {
		;(async () => {
			try {
				if (props?.user?.id === props?.job?.technician?.user?.id) {
					const jobInfoResponse = await getJobInformation(props.job.id, props?.user?.id)
					const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
					if (jobInfo && jobInfo?.technician_notes && jobInfo?.technician_notes.length > 0) setFetchAllNotes(jobInfo.technician_notes)
				}
			} catch (error) {
				console.error('Error occurred while fetching job:', error)
			}
		})()
	}, [props.job])

	useEffect(() => {
		focusTextarea()
	}, [props, allNotesData, isEditNotes])

	const focusTextarea = () => {
		if (textareaRef.current) {
			textareaRef.current.focus()
		}
	}

	const handleOnChange = async (e) => {
		const data = e.target.value
		if (data == '') {
			setTextArea('')
			setCharacterCount(0)
		} else if (data.length > 500) {
			setCharLeftColor(true)
		} else {
			setShowErrorMess(false)
			setCharLeftColor(false)
			setCharacterCount(data.length)
			setTextArea(data)
		}
	}

	const customButtonStyle = {
		backgroundColor: '#1bd4d5',
		color: '#fff',
		height: '40px',
		width: '100px',
		border: 'none',
	}

	const handleSubmit = async () => {
		setLoading(true)
		const enterText = textArea.trim()
		if (enterText === '') {
			setShowErrorMess(true)
			setLoading(false)
			return
		} else if (updateTextId) {
			let temp = fetchAllNotes.map((elem, index) => {
				if (elem.id === updateTextId) {
					return { ...elem, textArea: enterText }
				}
				return elem
			})
			try {
				if (props?.user?.id === props?.job?.technician?.user?.id) {
					const updateResponse = await updateJob(props.job.id, {
						dataToUpdate: { technician_notes: temp },
						cuId: props?.user?.id,
					})
					await JobCycleApi.create(JobTags.TECH_UPDATE_NOTES, props.job.id)
					if (updateResponse && updateResponse.data && updateResponse.data.technician_notes) {
						// console.log('technician update notes>>>>>>>>>', updateResponse)
						setLoading(false)
						setIsEditNotes(false)
						setTextArea('')
						setShowErrorMess(false)
						setCharLeftColor(false)
						setUpdateTextId(null)
						setCount(500)
						setCharacterCount(0)
						setFetchAllNotes(updateResponse.data.technician_notes)
						setAllNotesData([])
					}
				}
			} catch (error) {
				console.error('Error occurred while updating job:', error)
			}
		} else {
			setAllNotesData((prev) => [...prev, { id: prev.length + 1, textArea: enterText }])
		}
	}
	const closeModalIcon = () => {
		props.onHide()
		setTextArea('')
		setIsEditNotes(false)
	}
	const handleOnDelete = async (id) => {
		setNoteIdToDelete(id)
		setShowDeleteModal(true)
		setShowErrorMess(false)
	}

	const confirmDelete = async () => {
		setShowDeleteLoader(true)
		const updatedJob = await removeTechnicianNotesById(noteIdToDelete, props?.user?.id)
		if (updatedJob && updatedJob?.remainingNotes) {
			await JobCycleApi.create(JobTags.TECH_DELETE_NOTES, props.job.id)
			if (updatedJob && updatedJob?.remainingNotes) {
				setIsEditNotes(false)
				setShowDeleteModal(false)
				setShowDeleteLoader(false)
				setFetchAllNotes(updatedJob?.remainingNotes)
				setAllNotesData([])
			}
		}
	}

	const handleOnEdit = async (id) => {
		setIsEditNotes(true)
		setUpdateTextId(id)
		await JobCycleApi.create(JobTags.TECH_EDIT_NOTES, props.job.id)
		const findData = fetchAllNotes.find((prev) => prev.id === id)
		if (findData && findData.textArea !== '') {
			setTextArea(findData.textArea)
			setCharacterCount(findData.textArea.length)
			setShowErrorMess(false)
		}
	}

	const columns = [
		{
			title: 'Message Notes',
			dataIndex: 'textArea',
			width: '80%',
			key: 'textArea',
			render: (text) => (
				<ul>
					{text ? (
						<>
							{text.split().map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</>
					) : (
						''
					)}
				</ul>
			),
		},
		{
			title: 'Action',
			dataIndex: 'id',
			key: 'id',
			render: (id) => {
				if (id) {
					return (
						<>
							<div className="tech-notes-action-btn">
								<div
									className={isEditNotes ? 'notes-edit-icon-div mr-3 action-btn-disable' : 'notes-edit-icon-div mr-3'}
									onClick={isEditNotes ? null : () => handleOnEdit(id)}
									title="Edit Notes"
								>
									<FontAwesomeIcon
										className={
											isEditNotes ? 'action-btn-disable tech-notes-editIcon' : 'tech-notes-editIcon notes-cursor-allowed'
										}
										icon={faPencilAlt}
									/>
								</div>
								<div onClick={isEditNotes ? null : () => handleOnDelete(id)} title="Delete Notes">
									<FontAwesomeIcon
										className={
											isEditNotes ? 'action-btn-disable tech-notes-deleteIcon' : 'tech-notes-deleteIcon notes-cursor-allowed'
										}
										icon={faTrash}
									/>
								</div>
							</div>
						</>
					)
				}
			},
		},
	]

	return (
		<Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header>
					<Modal.Title>Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to delete this note?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)} disabled={showDeleteLoader}>
						No
					</Button>

					<Button variant="danger" onClick={confirmDelete} disabled={showDeleteLoader}>
						{showDeleteLoader ? <Spin className="spinner spinner-pos" /> : 'Yes'}
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">Internal Notes</Modal.Title>
				<Modal.Title title="Close" onClick={closeModalIcon} style={{ cursor: 'pointer' }}>
					<BsX />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Col className="ant-table-structure-outer tech-notes-list table-responsive p-0">
					<div ref={notesContainerRef} style={{ height: `calc(35rem - 110px)`, overflowY: 'scroll' }}>
						<Table
							dataSource={fetchAllNotes}
							pagination={false}
							columns={columns}
							rowKey={(record) => record.id}
							className="tech-notes-table"
							// scroll={{ y: 500 }}
						/>
					</div>
				</Col>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<span className="editor-heading-tech-note">Add Your Notes Here</span>
					<Row>
						<Col xs={12} className="mt-2">
							<textarea
								ref={textareaRef}
								className="form-control"
								value={textArea}
								onChange={handleOnChange}
								spellCheck="true"
								placeholder="Add Notes.."
							></textarea>
							<div>
								{showErrorMess && <span style={{ color: 'red' }}>Please enter description.</span>}
								<span className={`characterCounts ${charLeftColor ? 'red-character' : ' '}`}>
									{' '}
									({`${count - characterCount} character left`}){' '}
								</span>
							</div>
						</Col>
					</Row>
					<div className="mt-3 text-end">
						<Button style={customButtonStyle} className="tech-notes-submit-div" disabled={loading} onClick={handleSubmit}>
							{loading ? <Spin className="spinner spinner-pos" /> : <>{isEditNotes ? 'Update' : 'Submit'}</>}
						</Button>
					</div>
				</Container>
			</Modal.Footer>
		</Modal>
	)
}

const TechnicianManageNotes = ({ user, job }) => {
	const [modalShow, setModalShow] = useState(false)

	return (
		<>
			<div
				className="manageNotesModal"
				onClick={() => setModalShow(true)}
				title="These notes are only visible to you and Geeker admins. Customers will not see these notes."
			>
				<NewspaperRoundedIcon /> <span className="pl-3">Internal Notes</span>
			</div>
			<MyVerticallyCenteredModal user={user} job={job} show={modalShow} onHide={() => setModalShow(false)} />
		</>
	)
}

export default TechnicianManageNotes
