import React from 'react'
import { Modal } from 'antd'
import Button from '@mui/material/Button'
import remoteAccess from '../../../assets/images/remoteAccess.png'
import safariRemoteAccess from '../../../assets/images/safariRemoteAccess.png'
import firefoxRemoteAccess from '../../../assets/images/firefoxRemoteAccess.png'

export const AllowRemoteAccessModal = ({ showRemoteAccessModal, setShowRemoteAccessModal }) => {
	const offRemoteAccessModal = () => {
		setShowRemoteAccessModal(false)
	}

	const { isSafari, isFirefox, isChrome } = detectBrowsers()
	const remoteAccessImage = getRemoteAccessImage(isSafari, isFirefox, isChrome)

	const styles = isFirefox
		? { top: '10px', right: '-5rem', float: 'left' }
		: isSafari
		? { top: '10px', right: '-10%' }
		: { top: '10px', right: '-34%' }

	return (
		<Modal
			visible={showRemoteAccessModal}
			onCancel={() => offRemoteAccessModal()}
			width="26%"
			style={styles}
			footer={null}
			maskClosable={false}
			destroyOnClose={true}
			bodyStyle={{
				boxShadow: '-1px -1px 2px #FDFDFD, 2px 2px 6px #D2DBE2',
				background: '#FFFFFF',
			}}
		>
			<div className="mx-4">
				<AccessModalBody offRemoteAccessModal={offRemoteAccessModal} remoteAccessImage={remoteAccessImage} />
			</div>
		</Modal>
	)
}

const AccessModalBody = ({ offRemoteAccessModal, remoteAccessImage }) => {
	return (
		<div className="d-flex justify-content-center flex-column px-5 align-items-center">
			<img src={remoteAccessImage} className="img-fluid" alt="Remote Permission" />
			<p className="popUps-unblock-text fw-bold">Allow remote access & watch your Geek work for you! 🚀 </p>
			<Button variant="contained" className="phone-chat-btn-style " onClick={offRemoteAccessModal}>
				OK, Understood
			</Button>
		</div>
	)
}

function detectBrowsers() {
	let isSafari = false
	let isFirefox = false
	let isChrome = false

	try {
		const userAgent = navigator.userAgent.toLowerCase()
		isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent)
		isFirefox = /firefox/.test(userAgent)
		isChrome = /chrome/.test(userAgent)
	} catch (error) {
		console.error('Error detecting browser:', error)
	}

	return { isSafari, isFirefox, isChrome }
}

function getRemoteAccessImage(isSafari, isFirefox, isChrome) {
	if (isSafari) {
		return safariRemoteAccess
	} else if (isFirefox) {
		return firefoxRemoteAccess
	} else if (isChrome) {
		return remoteAccess
	} else {
		return remoteAccess
	}
}
