import axios from 'axios'
import { SECRET_KEY, SERVER_URL,SERVER_BACKEND_BASE_URL } from '../constants'

const apiClient = axios.create({
	baseURL: `${SERVER_URL}`,
})

apiClient.interceptors.request.use(async (request) => {
	const accessToken = localStorage.getItem(SECRET_KEY)

	if (accessToken) {
		request.headers.Authorization = `Bearer ${accessToken}`
	}
	if (request.url.startsWith('/jobfile')) {
		// Modify the baseURL for these requests
		request.baseURL = SERVER_BACKEND_BASE_URL;
	}

	return request
})

export default apiClient
