import './fullStorySetup'
import './logrocketSetup'
import React from 'react'
import { Container } from 'react-bootstrap'
import Router from './Router'
import { MIXPANEL_KEY, REACT_APP_ROLLBAR_TOKEN } from './constants/index'
import mixpanel from 'mixpanel-browser'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import 'antd/dist/antd.css'
import './css/style.css'
// import { Provider, ErrorBoundary } from '@rollbar/react'
// import Rollbar from 'rollbar'
var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase())
if (!isiDevice) {
	var permission = Notification.permission
	if (permission != 'granted') {
		Notification.requestPermission()
	}
}

// const rollbarConfig = {
// 	accessToken: REACT_APP_ROLLBAR_TOKEN,
// 	captureUncaught: true,
// 	captureUnhandledRejections: true,
// }

mixpanel.init(MIXPANEL_KEY, { debug: true })

const App = () => {
	// console.log("Main Entery...........");
	// check ConnectionSpeed
	const connectionSpeed = navigator.connection ? navigator.connection.downlink : null
	if (connectionSpeed < 2) {
		console.log('Internet connection slow ', connectionSpeed)
		mixpanel.track('Internet Connection - Slow', { speed: connectionSpeed })
	}
	if (connectionSpeed < 8 && connectionSpeed > 2) {
		console.log('Internet connection is average ', connectionSpeed)
		mixpanel.track('Internet Connection - Average ', { speed: connectionSpeed })
	}
	if (connectionSpeed > 8) {
		console.log('Internet Connection - Good', connectionSpeed)
	}

	return (
		// <Provider config={rollbarConfig}>
		// 	<ErrorBoundary>
		// 		<Container id="ThemeLight" fluid>
		// 			<Router />
		// 		</Container>
		// 	</ErrorBoundary>
		// </Provider>

		<Container id="ThemeLight" fluid>
			<Router />
		</Container>
	)
}

export default App
