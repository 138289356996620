import Button from '@mui/material/Button'
import { Modal, Spin } from 'antd'
import React from 'react'
import switchGeeks from '../../../assets/images/switchGeeks.png'
import { updateCustomerStartMeetingResponse } from '../../../utils'

const SwitchGeeksModal = ({
	setShowUpdateJobModal,
	switchGeekModal,
	setModalAction,
	job,
	setLeaveTheMeeting,
	showEndMeetingSpinner,
	countdown,
	socket,
	handleBackDropChange,
	setCustomerModalAction,
	initialCustomerModalAction,
	user,
}) => {
	const endMeet = () => {
		setLeaveTheMeeting(true)
		// updateCustomerStartMeetingResponse(job.id, 'end meeting')
		handleBackDropChange('jobId', '')
		handleBackDropChange('isBackdropVisible', false)
	}

	const transferCall = () => {
		socket.emit('cus-response-for-modalAction', { transferCall: true, jobId: job.id })
		setModalAction('resetAll')
		updateCustomerStartMeetingResponse(job.id, 'Transfer', user.id)
		setCustomerModalAction(initialCustomerModalAction)
		handleBackDropChange('jobId', job.id)
		handleBackDropChange('isBackdropVisible', true)
	}

	const handelClose = () => {
		setShowUpdateJobModal(true)
		setModalAction('resetAll')
		updateCustomerStartMeetingResponse(job.id, 'Transfer', user.id)
	}

	return (
		<>
			<Modal visible={switchGeekModal} width={800} closable={false} footer={null} className={'customer-meeting-confirmation-modal'}>
				{/* <div className="cross-btn-div">
					<IconButton
						onClick={handelClose}
					>
						<Close />
					</IconButton>
				</div> */}
				<div className="customer-meeting-confirmation-modal-heading">
					<img src={switchGeeks} className="customer-meeting-confirmation-modal-image" />
					<span className="customer-meeting-confirmation-modal-heading-text">Your Geek can’t help</span>
				</div>
				<div className="text-center">
					<span className="customer-meeting-confirmation-modal-text-span">
						Your Geek is not able to resolve the issue or is recommending a transfer. In case of transfer, case notes will be added and
						carry to your next meeting.
					</span>
				</div>
				<div className="customer-meeting-confirmation-modal-button-div">
					<Button variant="outlined" className="customer-meeting-confirmation-modal-button-outlined" onClick={endMeet}>
						{showEndMeetingSpinner ? <Spin /> : <span>End Meeting</span>}
					</Button>
					<Button variant="contained" className="customer-meeting-confirmation-modal-button" onClick={transferCall}>
						<span>Transfer ({countdown})</span>
					</Button>
				</div>
			</Modal>
		</>
	)
}

export default SwitchGeeksModal
