import apiClient from './index'

/**
 * Send meeting inviation link in email
 * @params = email (Type: String), link(Type:String)
 * @response : will send meeting invitation link on given email
 * @author : Ridhima
 */
export async function sendJitsiInvitation(data) {
	try {
		return apiClient
			.post('/services/invite-to-jitsi', data)
			.then((response) => {
				if (response) {
					return response.data
				}
				return Promise.reject()
			})
			.catch(function (error) {
				return error.message
			})
	} catch (err) {
		return err.message
	}
}
