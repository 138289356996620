export const CalculateDiscount = (totalCost, discountedValue, discountType) => {
	console.log('checking the promocode data is coming correctly --', { totalCost, discountedValue, discountType })
	let discountedCost = totalCost
	if (discountType === 'fixed') {
		discountedCost = totalCost - discountedValue
		return discountedCost
	} else if (discountType === 'percentage') {
		discountedCost = totalCost - (totalCost * discountedValue) / 100
		return discountedCost
	} else {
		return discountedCost
	}
}
