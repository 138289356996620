import { notification } from 'antd'
import { MAIN_APP_URL } from '../constants'
import notifySound from '../assets/sounds/notification.mp3'
import mixpanel from 'mixpanel-browser'
import Cookies from 'js-cookie'
import moment from 'moment'
import * as CustomerApi from '../api/customer.api'
import { updateJob } from '../api/meeting.api'
const audio = new Audio(notifySound)

/**
 * Remove prefix from job id
 * @params = jobId (Type:string)
 * @response : function will remove prefix 'job_' from jobId
 * @author : Karan
 */
export const getIdFromJobId = (jobId) => {
	return jobId.split('job_')[1]
}

/**
 * Notification popup with custom time duration
 * @params = type='Info/Error/Warning', header='Title of message', message='Description, duration='duration'
 * @response : function will open the notification popup
 * @author : Vinit
 */
export const openNotificationWithIconWithCustomTimeDuration = (type, header, duration, message) => {
	console.log('openNotificationWithIconWithCustomTimeDuration', message, duration)
	notification[type]({
		message: header,
		description: message,
	})
	notification.config({ duration: duration })
}

/**
 * Notification popup
 * @params = type='Info/Error/Warning', header='Title of message', message='Description'
 * @response : function will open the notification popup
 * @author : Karan
 */
export const openNotificationWithIcon = (type, header, message) => {
	notification[type]({
		message: header,
		description: message,
	})
	notification.config({ duration: 10 })
}

/**
 * Redirect to our main application
 * @params = pathName (Type:string)
 * @response : function will redirect to our main application
 * @author : Karan
 */
export const redirectToApp = (pathName = '', props) => {
	// mixpanel code
	mixpanel.identify(props.user.email)
	mixpanel.track(`${props.user.userType} - Click on logo and redirect to dashboard`, { JobId: props.job.id })
	// mixpanel code
	if(props && props.user && props.user.userType === 'technician' && pathName === 'dashboard'){
		window.location.href = `${MAIN_APP_URL}/${pathName}/technician`
	}else{
		window.location.href = `${MAIN_APP_URL}/${pathName}`
	}	
}

/**
 * Return weather a usertype is customer or not
 * @params = userType (Type:string)
 * @response : function will return weather a user is customer or not
 * @author : Karan
 */
export const checkIsCustomer = (userType) => {
	return userType === 'customer'
}

/**
 * Notification to technician about "Please tell customer to add card or decline the meeting"
 * @params = ''
 * @response : Open Notification for technician about "Please tell customer to add card or decline the meeting"
 * @author : Karan
 */
export const sendNotificationToTechnician = () => {
	audio.play()
	let body = 'Pleae ask client to continue or decline the meeting.'
	let options = {
		body: body,
	}
	let notification = new Notification('New Geeker Notification', options)
	openNotificationWithIcon('info', 'Info', 'Please ask client to continue or decline the meeting.')
}

/**
 * Notification to customer about "Your screenshare is stopped"
 * @params = ''
 * @response : Open Notification for customer about "Your screenshare is stopped"
 * @author : Karan
 */
export const sendNotificationToCustomer = () => {
	audio.play()
	openNotificationWithIcon(
		'info',
		'Info',
		'For security reasons we stopped your screen sharing. Please enter card details and start screen share again.',
	)
}

/**
 * A setInterval Function which shows popup if not notes are not confirmed everyminute
 * @params : {void}
 * @response : Shows a popup
 * @author Sahil
 */
export const notesPopup = (jobId, getJobInformation, userType) => {
	window.timerInterval = setInterval(async () => {
		const jobInfoResponse = await getJobInformation(jobId)
		if (jobInfoResponse.data.allNotes == undefined && userType == 'technician') {
			audio.play()
			notification.destroy()
			browserNotificationHandler('Please confirm issue with the customer and have the customer click confirm')
			notification.info({
				message: 'Info',
				description: 'Please confirm issue with the customer and have the customer click confirm',
				duration: 15,
			})
		}
		if (jobInfoResponse.data.allNotes != undefined && jobInfoResponse.data.customerConfirmedNotes == false && userType == 'customer') {
			audio.play()
			notification.destroy()
			browserNotificationHandler('Please confirm points to start the meeting')
			notification.info({
				message: 'Info',
				description: 'Please confirm points to start the meeting',
				duration: 15,
			})
		}
	}, 60000)
}

/**
 * Clear all intervals
 * @param :{VOID}
 * @returns  : clear at intervals or timeout
 * @author Sahil
 */
export const clearTimeOutAndIntervals = (type) => {
	if (type == 'popupTimer') {
		clearInterval(window.timerInterval)
		return
	} else if (type == 'meetingTimer') {
		clearInterval(window.meetingTimer)
		return
	} else if (type == 'all') {
		clearInterval(window.meetingTimer)
		clearInterval(window.timerInterval)
		return
	}
}

/**
 * Get Browser Cookie
 * @params = cname (Type:string)
 * @response : return specific cookie value
 * @author : Sahil
 */
export const getCookie = (cname) => {
	const name = cname + '='
	const decodedCookie = decodeURIComponent(document.cookie)
	const ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

/**
 * Sets a cookie if it is empty
 * @params = user (Type:Object)
 * @response : if cookie is not set it will set the cookie
 * @author : Jagroop
 */

export const setCookie = (userId) => {
	try {
		Cookies.set('user_id', userId, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN })
		return
	} catch (err) {
		console.log('Error in Setting cookie at Meeting Site', err)
	}
}

/**
 * Call functionty for technician and customer
 * @params : arrays with key value pairs.
 * @response : if true then pass type(Customer, Technician or Invite)
 * @author : Ridhima
 */
export const callOnPhone = (params) => {
	const finalPhoneNumber = params['phone_number']
	console.log('jitsiContainer::: utils/index.js callOnPhone Info:::', finalPhoneNumber)
	params['jitsiApi']
		.invite([
			{
				allowed: true,
				number: finalPhoneNumber,
				originalEntry: `${params['phone_number']}`,
				showCountryCodeReminder: false,
				type: 'phone',
			},
		])
		.then(() => {
			console.log('Invite successfully', {
				allowed: true,
				number: `${params['phone_number']}`,
				originalEntry: `${params['phone_number']}`,
				showCountryCodeReminder: false,
				type: 'phone',
			})
			return params['setInviteStatus'](params['type'])
		})
	return false
}
/**
 * interval that starts meeting
 * @params : job(Type:Object) , api(Type:Object) , socket(Type:Object) , totalPaidJobs(Type:Number)
 * @response : Starts the meeting timer
 * @author : Sahil
 **/

export function startTimer(job, api, socket, totalPaidJobs, userType) {
	try {
		console.log('Jitsi StartTimer::: Inside start timer function >>>>>>>>>>>>>>>')
		window.meetingTimer = setInterval(async () => {
			console.log('Jitsi StartTimer::: interval running >>>>>>>>>>>>>>>')
			// let joinedParticipentArr = []
			// getParticipantDetails function return particiapnts Available in Meeting
			// joinedParticipentArr = await getParticipantDetails(api, userType);
			// if somehow in above condition nothing works then we will again try to get getParticipantsInfo
			// if (joinedParticipentArr.length <= 0) {
			// 	joinedParticipentArr = api.getParticipantsInfo()
			// 	console.log("Jitsi StartTimer::: participantInfo inside Safetycheck condition", joinedParticipentArr);
			// }
			// const customerPhoneNumber = job.customer.phoneNumber
			// const techPhoneNumber = job.technician.profile.confirmId.phoneNumber
			// let technicianJoined = false
			// let customerJoined = false
			// for (let k in joinedParticipentArr) {
			// 	console.log('Jitsi StartTimer::: inside for loop of joinedParticipentArr with index ', k, 'and displayName is', joinedParticipentArr[k].displayName)
			// 	if (joinedParticipentArr[k].displayName.includes(customerPhoneNumber)) {
			// 		console.log('Jitsi StartTimer::: checking customer phone number ', customerPhoneNumber, 'with', joinedParticipentArr[k].displayName)
			// 		customerJoined = true
			// 		console.log('Jitsi StartTimer::: Customer joined from phone', customerJoined)
			// 	} else if (joinedParticipentArr[k]?.displayName.split('(')[1]?.split('')[0] === 'c') {
			// 		console.log('Jitsi StartTimer::: checking usertype as customer', joinedParticipentArr[k].displayName)
			// 		customerJoined = true
			// 		console.log('Jitsi StartTimer::: Customer joined from computer', customerJoined)
			// 	} else if (joinedParticipentArr[k].displayName.includes(techPhoneNumber)) {
			// 		console.log('Jitsi StartTimer::: checking technician phone number ', techPhoneNumber, 'with', joinedParticipentArr[k].displayName)
			// 		technicianJoined = true
			// 		console.log('Jitsi StartTimer::: Technician joined from phone', technicianJoined)
			// 	} else if (joinedParticipentArr[k]?.displayName.split('(')[1]?.split('')[0] === 't') {
			// 		console.log('Jitsi StartTimer::: checking usertype as technician', joinedParticipentArr[k].displayName.split('(')[1].split('')[0])
			// 		technicianJoined = true
			// 		console.log('Jitsi StartTimer::: Technician joined from computer', technicianJoined)
			// 	}
			// }
			// console.log('Jitsi StartTimer::: Customer and technician joined variables', customerJoined, technicianJoined)
			// console.log('Jitsi StartTimer::: all condition >>>>>>>', joinedParticipentArr.length > 1 && !job.meetingStarted)
			// console.log('Jitsi StartTimer::: condition 1 >>>>>>>> ', joinedParticipentArr.length > 1)
			// console.log('Jitsi StartTimer::: condition 2 >>>>>>>>>>>>', !job.meetingStarted)
			if (!job.meetingStarted) {
				const data = {}
				data['jobId'] = job.id
				data['customerConfirmedNotes'] = true
				data['confirmedNotes'] = true
				data['customer'] = job.customer.id
				data['totalPaidJobs'] = totalPaidJobs
				data['meetingStarted'] = job.meetingStarted

				try {
					mixpanel.identify(job.customer.user.email)
					mixpanel.track(`${job.customer.user.userType} - updating job for the following jobId`, { JobId: job.id })
				} catch (err) {
					console.log('Jitsi StartTimer::: error in implementing mix panel code', err)
				}
				console.log('Jitsi StartTimer::: Inside start timer function going to start emit socket to start timer>>>>>>>>>>>>>>>', data)
				socket.emit('meeting:started', data)
			}
		}, 1000)
	} catch (err) {
		console.log('Jitsi StartTimer::: error in startTimer :::: ', err)
	}
}

/**
 * Check if user is live or not
 * @params = user (Type:Object)
 * @response : return true if user is live else returns false
 * @author : Sahil
 */

export function isLiveUser(user) {
	let userType = 'live'
	try {
		if (user.userType == 'technician') {
			userType = user?.technician?.technicianType
		} else {
			userType = user?.customer?.customerType
		}
		if (userType == 'live') {
			return true
		} else {
			return false
		}
	} catch (err) {
		console.log('error in checkForTestOrLiveUser ::::', err)
		return true
	}
}

/**
 * Browser notification setup
 * @params : body(Type:String)
 * @response : Opens a browser notification
 * @author : Sahil
 */
export function browserNotificationHandler(body) {
	try {
		let options = {
			body: body,
		}
		new Notification('New Geeker Notification', options)
	} catch (err) {
		console.log('error in browserNotificationHandler ::: ', err)
	}
}

/**
 * Checks if current user is ownwer or not
 * @params : user : Object
 * @returns : {success, stripeId} : Object
 * @author : Kartar, Vinit
 */
export const checkOwnerStripeId = async (user) => {
	try {
		if (user && user?.ownerId) {
			const ownerCustomerDetails = await CustomerApi.getCustomer(user?.ownerId, user?.id)
			// console.log('ownerCustomerDetails ::::', ownerCustomerDetails)
			if (ownerCustomerDetails?.stripe_id) {
				const ownerStripeId = ownerCustomerDetails?.stripe_id
				return { success: true, stripeId: ownerStripeId }
			} else {
				return { success: false, stripeId: '' }
			}
		} else {
			return { success: false, stripeId: '' }
		}
	} catch (error) {
		console.log('error while getting the owner stripeId', error)
		return { success: false, stripeId: '' }
	}
}

/**
 * Calculates time difference
 * @params : startTime : date
 * @returns : timeDifference : date
 * @author : Vinit
 */
export const getTimeDifference = (startTime) => {
	try {
		const currentTime = new Date().getTime()
		const timeReceived = new Date(startTime).getTime()
		const timeDifference = currentTime - timeReceived
		return timeDifference
	} catch (error) {
		console.log('bug occured in getTimeDifference', { error })
	}
}

/**
 * This function return remaining seconds between current date and meeting started date
 * @param : meeting_start_time
 * @returns : seconds
 * @author : Jagroop
 */
export const subScriptionTimerDifference = async (job, user) => {
	// console.log('subScriptionTimerDifference JOB', job)
	const haveSubscription = job?.customer?.subscription && job?.customer.subscription?.invoice_id
	const remainingSubscriptionMinutes = job?.customer?.subscription?.total_seconds - job?.customer?.subscription?.time_used
	const haveOwnerId = job?.customer?.user?.ownerId
	if (haveSubscription && remainingSubscriptionMinutes > 0) {
		const subTimeRemaining = getTimeDifferenceSub(job?.meeting_start_time, remainingSubscriptionMinutes)
		console.log('subScriptionTimerDifference 1', subTimeRemaining)
		return subTimeRemaining
	}

	if (haveOwnerId) {
		const ownerCustomerResponse = await CustomerApi.getCustomer(haveOwnerId, user?.id)
		const isOwnerHaveSubscription = ownerCustomerResponse?.subscription && ownerCustomerResponse?.subscription?.invoice_id
		const remainingSubscriptionMinutes = ownerCustomerResponse?.subscription?.total_seconds - ownerCustomerResponse?.subscription?.time_used
		if (isOwnerHaveSubscription && remainingSubscriptionMinutes > 0) {
			const subTimeRemaining = getTimeDifferenceSub(job?.meeting_start_time, remainingSubscriptionMinutes)
			console.log('subScriptionTimerDifference 2', subTimeRemaining)
			return subTimeRemaining
		}
	}
	return 0
}

/**
 *
 * @param {*} start_time
 * @param {*} remainingSeconds
 * @returns : timeDifference in seconds
 * @description : This function will calculate the time difference between meetingStartTime and currentTime
 * and whatever seconds we get from it will be substracted from remainingSeconds which decided when we have to do preauthorization
 * of card
 */
const getTimeDifferenceSub = (start_time, remainingSeconds) => {
	const startDate = new Date(start_time)
	const endDate = new Date()
	let seconds = (endDate.getTime() - startDate.getTime()) / 1000
	console.log('subScriptionTimerDifference getTimeDifference', seconds, remainingSeconds - seconds, remainingSeconds)
	return remainingSeconds - seconds
}

/**
 * @param {*} date
 * @description : This function will provide us time in chat on the basis of minutes,hour, day.
 * @author : Jagroop
 */
export const formatDateOfTwilioMessage = (date) => {
	// Current date
	var currentDate = new Date()
	// Given date
	var givenDate = new Date(date)
	// Calculate the time difference in milliseconds
	var timeDiff = currentDate - givenDate

	// Convert milliseconds to different units
	var secondsDiff = Math.floor(timeDiff / 1000)
	var minutesDiff = Math.floor(secondsDiff / 60)
	var hoursDiff = Math.floor(minutesDiff / 60)
	// console.log("formatDateOfTwilioMessage",{minutesDiff,hoursDiff,date})
	if (minutesDiff < 1) {
		return 'just now'
	}
	if (minutesDiff < 60) {
		return minutesDiff + ' min ago'
	}
	if (hoursDiff < 24) {
		return hoursDiff + `${hoursDiff > 2 ? 'hours ago' : 'hour ago'}`
	}
	if (hoursDiff >= 24) {
		const formattedDate = moment(date).format('MMMM Do YY, h:mm a')
		return formattedDate
	}
}

//  this function will return particiapntInfo as per according to new hook (getRoomsInfo) if it not works then it will use previous hook :getParticipantsInfo
export const getParticipantDetails = async (api, userType) => {
	let joinedParticipentArr = []
	try {
		joinedParticipentArr = await api.getRoomsInfo()
		const participantList = joinedParticipentArr?.rooms[0]['participants']
		let formattedResult = []
		if (userType == 'customer') {
			formattedResult = participantList.map((obj) => ({
				...obj,
				formattedDisplayName: obj.displayName.includes('(c)') ? `${obj.displayName} (me)` : obj.displayName,
			}))
		} else {
			formattedResult = participantList.map((obj) => ({
				...obj,
				formattedDisplayName: obj.displayName.includes('(t)') ? `${obj.displayName} (me)` : obj.displayName,
			}))
		}
		if (formattedResult.length > 0) {
			// console.log("JitsiContainer::: participantInfo Try Block", formattedResult);
			return formattedResult
		} else {
			joinedParticipentArr = api.getParticipantsInfo()
			// console.log("JitsiContainer::: participantInfo Try Block-2", joinedParticipentArr);
			return joinedParticipentArr
		}
	} catch (error) {
		joinedParticipentArr = api.getParticipantsInfo()
		console.log('JitsiContainer::: participantInfo Catch Block', joinedParticipentArr)
		return joinedParticipentArr
	}
}

/**
 *
 * @param {*} jitsiApi
 * @description : This function will automatically off the microphone of user in the meeting
 * @author : Jagroop
 */
export const muteMicrophone = (jitsiApi) => {
	try {
		jitsiApi.isAudioMuted().then((muted) => {
			console.log('muteMicrophone', muted)
			if (!muted) {
				jitsiApi.executeCommand('toggleAudio')
			}
		})
		return
	} catch (error) {
		return
	}
}

export const updateCustomerStartMeetingResponse = (jobId, responseType, userId) => {
	try {
		console.log(`updateCustomerStartMeetingResponse`)

		let dataToUpdate = { customer_start_meeting_action: responseType }

		updateJob(jobId, {
			dataToUpdate: dataToUpdate,
			cuId: userId,
		})

		//   console.log(`Update successful`);
	} catch (error) {
		console.error(`Error updating customer start meeting response:`, error)
		// You can handle the error here, log it, or throw it again if needed
		throw error
	}
}


  // This function is used toincrease the limit of sending sockets of internet and microphone ~ Jagroop
  export function getMeetingTimeState(seconds) {
	try {
		const minutes = Math.floor(seconds / 60)
		if (minutes < 10) {
			return 5000
		} else if (minutes < 20) {
			return 10000
		} else {
			return 20000
		}
	} catch (error) {
		console.log("error while deciding meeting stat details interval time",error);
		return 5000;
	}
  };
