//import { TOTAL_FREE_SECONDS, POPUP_SHOW_AFTER_SECONDS, MEETING_END_TIME_SECONDS } from '../constants'

import { checkForCustomerCard } from '../api/customer.api'
/**
 * Function will open warning popup
 * @params = ''
 * @response : Will open warning popup
 * @author : Karan
 */
//export async function showPopupOrEndMeeting(meetingSeconds, customerHaveCard) {
export async function showPopupOrEndMeeting() {
	// console.log('TOTAL_FREE_SECONDS ',TOTAL_FREE_SECONDS)
	// console.log('meetingSeconds ',meetingSeconds)
	// console.log('MEETING_END_TIME_SECONDS ',MEETING_END_TIME_SECONDS)
	// console.log('POPUP_SHOW_AFTER_SECONDS',POPUP_SHOW_AFTER_SECONDS)

	const res = {}
	res['openPopup'] = false
	res['type'] = ''
	/*if(!customerHaveCard){

		if(meetingSeconds >= POPUP_SHOW_AFTER_SECONDS && meetingSeconds < TOTAL_FREE_SECONDS){
			setShowFreeMinutesEndPopup(true)
	    				
			if(!techNotified){
				techNotified = true;
				sendNotificationToTechnician(userType)
			}
		}else if(meetingSeconds >= TOTAL_FREE_SECONDS && meetingSeconds < MEETING_END_TIME_SECONDS){
			res['openPopup'] = true
			res['type'] = 'lastMinuteWarning'
		}
		else if(meetingSeconds >= MEETING_END_TIME_SECONDS){
			res['openPopup'] = true
			res['type'] = 'endMeeting'
		}

	}else{
		if(meetingSeconds >= POPUP_SHOW_AFTER_SECONDS && meetingSeconds < TOTAL_FREE_SECONDS){
			res['openPopup'] = true
			res['type'] = 'freeTimeExpiringWarning'
		}
	}
	return res*/
}

/**
 * Function will check if customer have card or not
 * @params = customerId,liveUser
 * @response : Will return customer have card or not
 * @author : Karan
 */
export async function checkCustomerHaveCard(customerId, cuId, liveUser) {
	const response = await checkForCustomerCard({ customerId: customerId, cuId })
	let haveCard = false
	if (response.success) {
		return response.haveCard
		// haveCard = customerInfo.data.stripe_id && customerInfo.data.stripe_id !== '' ? true : false
	}
}
