import apiClient from './index'

/**
 * api to get customer according to userId
 * @params : userId(Type:String),
 * @response : return an object from customer api
 * @author : sahil
 **/
export async function getCustomer(userId, cuId) {
	return apiClient.get(`/customers/${userId}?cuId=${cuId}`).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

export async function checkForCustomerCard(data) {
	// /check-for-card
	return apiClient.post(`/customers/check-for-card`, data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
