import { MAIN_APP_URL } from '../constants'
import { openNotificationWithIcon } from '../utils'
import mixpanel from 'mixpanel-browser'
import { JobTags, removeLocalAndSessionStorage } from '../constants'
import * as JobCycleApi from '../api/jobCycle.api'
/**
 * End meeting
 * @params = ''
 * @response : Will end meeting from both customer and technician
 * @author : Karan
 */
export async function endMeeting(jitsiApi, job, user, socket) {
	try {
		await terminateJitsi(jitsiApi)

		const data = {}
		data['jobId'] = job.id
		data['userType'] = user.userType
		data['email'] = user.email
		data['firstName'] = user.firstName
		data['lastName'] = user.lastName
		data['userId'] = user.id
		data['technicianId'] = job.technician.id
		//console.log("endMeeting>>>>",data)
		socket.emit('EndMeeting', data)
		// mixpanel code
		mixpanel.identify(user.email)
		mixpanel.track(`${user.userType} - End Meetiing Successfully`, { JobId: job.id })
		// mixpanel code
		let lifeCycleTag = ''
		if (user.userType === 'technician') {
			lifeCycleTag = JobTags.TECHNCIIAN_END_MEETING
		} else {
			lifeCycleTag = JobTags.CUSTOMER_END_MEETING
		}
		if (job.is_transferred && job.is_transferred == true) {
			if (user.userType === 'technician') {
				lifeCycleTag = JobTags.TECHNCIIAN_END_MEETING_AFTER_TRANSFER
			} else {
				lifeCycleTag = JobTags.CUSTOMER_END_MEETING_AFTER_TRANSFER
			}
		}
		await JobCycleApi.create(lifeCycleTag, job.id)
		// This will rest the localStorage for particular field  : usingComputerAudio,customerCallingHimSelf card-modal-open , in-call and meeting-retries etc.
		removeLocalAndSessionStorage(job?.id)
		setTimeout(function () {
			// console.log('Going to redirect page to main app in end meeting.')
			// window.location.href= `/meeting-over/${user.userType}/${job.id}`
			localStorage.removeItem('coBrandingLogoUrl')
			window.location.href = `${MAIN_APP_URL}/meeting-feedback/${job.id}`
		}, 1500)
	} catch (error) {
		// endMeeting(jitsiApi, job, user, socket)
		openNotificationWithIcon('error', 'Error', 'Unable to end meeting. Please reload your page and try again. ' + error)
		console.log('My console for err', error)
	}
}

/**
 * Kickout all partispant from jitsi and terminate
 * @params = ''
 * @response : Will kickout all partispants from jitsi and terminate
 * @author : Karan
 */
export async function terminateJitsi(jitsiApi) {
	if (jitsiApi) {
		jitsiApi.executeCommand('hangup')
		const participants = jitsiApi.getParticipantsInfo()
		for (const p in participants) {
			jitsiApi.executeCommand('kickParticipant', participants[p]['participantId'])
		}
	}
}
