import React from 'react'
import { Row, Col } from 'react-bootstrap'
import logo from '../../assets/images/newLogo.png'
import { redirectToApp } from '../../utils'
import GeekerLogo from '../../utils/GeekerLogo'

const Header = (props) => {
	
	return (
		<Row>
			<Col xs={12} className="pt-4">
				<div className="bar-logo-box" onClick={() => redirectToApp('dashboard', props)}>
					<GeekerLogo logo={logo} className="logo-img" user={props?.user} width={'70%'} job={props?.job} />
				</div>
			</Col>
		</Row>
	)
}

export default Header
