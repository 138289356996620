import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { notification } from 'antd'
import Timer from 'react-compound-timer'
import { TOTAL_FREE_SECONDS, WARNING_POPUP_SECONDS, LAST_MINUTE_POPUP_SECONDS, FREE_TIME_EXPIRING_POPUP_SECONDS } from '../../../constants'
import { getJobInformation } from '../../../api/meeting.api'
import { endMeeting } from '../../../services/MeetingServices'
import { browserNotificationHandler } from '../../../utils'
import { LONGJOB_MINPOP } from '../../../constants'
const warningTime = { seconds: 0, minutes: 0, hours: 0 }
let screenshareStarted = false
let longJobSuggest = localStorage.getItem('longJobPopUp')
export const SuggestLongJobConversion = (props) => {
	useEffect(() => {
		;(async () => {
			/**
			 * Open modal for technician to suggest project after 25 minurtes
			 */
			longJobSuggest = localStorage.getItem('longJobPopUp')
			if (props.runningSeconds && props.runningSeconds >= LONGJOB_MINPOP && longJobSuggest === null) {
				if (props.job && props.job.id) {
					let jobInfoUpdatedResponse = await getJobInformation(props.job.id, props?.user?.id)
					if (jobInfoUpdatedResponse && jobInfoUpdatedResponse.data && jobInfoUpdatedResponse.data.is_long_job === false) {
						localStorage.setItem('longJobPopUp', props.job?.id)
						suggestLongJobConversionPopup()
					}
				}
			}
		})()
	}, [props.runningSeconds])

	const suggestLongJobConversionPopup = () => {
		const key = 'convertLongJobSuggestion'
		const btn = (
			<Button
				// variant="danger"
				className="mt-2"
				size="small"
				onClick={() => {
					notification.close('convertLongJobSuggestion')
					localStorage.setItem('longJobPopUp', 'skipit')
				}}
			>
				Skip
			</Button>
		)

		let body = 'To save time and money Please evaluate if this is a project and can be converted'
		// browserNotificationHandler(body)
		notification.info({
			key,
			duration: null,
			btn,
			message: body,
			description: '',
		})
	}

	return <></>
}

const FreeMinutesEndPopup = (props) => {
	const [intialWarningTimeVal, setIntialWarningTimeVal] = useState(WARNING_POPUP_SECONDS * 1000)
	const [initialLastMinuteTimeVal, setInitialLastMinuteTimeVal] = useState(LAST_MINUTE_POPUP_SECONDS * 1000)
	const [initialFreeMinsExpiringTimeVal, setInitialFreeMinsExpiringTimeVal] = useState(FREE_TIME_EXPIRING_POPUP_SECONDS * 1000)
	// const [screenshareStarted, setScreenShareStarted] = useState(false);

	useEffect(() => {
		if (!props.isModalOpen) {
			/**
			 * Resume the meeting timer on Add card modal close
			 */
			if (props.job.customerConfirmedNotes && props.job.customer_paused_timer && !props.job.technician_paused_timer) {
				const data = {}
				data['jobId'] = props.job.id
				data['userType'] = props.user.userType
				props.socket.emit('Resume:timer', data)
			}
		}
	}, [props.isModalOpen])

	// useEffect(() => {
	// 	/**
	// 	 * Open modal to warn customer that free minutes are going to end (After 4 minutes)
	// 	 */
	// 	if (props.showFreeMinutesEndPopup && !props.closeWarningPopup) {
	// 		const seconds =
	// 			props.runningSeconds >= TOTAL_FREE_SECONDS - props.runningSeconds ? TOTAL_FREE_SECONDS - props.runningSeconds : WARNING_POPUP_SECONDS
	// 		setIntialWarningTimeVal(seconds * 1000)
	// 		// freeMinutesEndWarningPopup(seconds)
	// 	}
	// }, [props.showFreeMinutesEndPopup])

	useEffect(() => {
		/**
		 * Open modal to warn customer that only last minute is pending (After 6 minutes)
		 */
		if (props.showLastMinuteWarningPopup && !props.closeWarningPopup) {
			const seconds =
				props.runningSeconds > TOTAL_FREE_SECONDS
					? TOTAL_FREE_SECONDS + LAST_MINUTE_POPUP_SECONDS - props.runningSeconds
					: LAST_MINUTE_POPUP_SECONDS
			setInitialLastMinuteTimeVal(seconds * 1000)
			lastMinuteWarningPopup(seconds)
		}
	}, [props.showLastMinuteWarningPopup])

	useEffect(() => {
		/**
		 * Open modal to warn customer that your paid meeting will start after 6 minutes (After 4 minutes)
		 */
		if (props.showFreeMinutesExpiringPopup && !props.closeWarningPopup) {
			const seconds =
				props.runningSeconds >= TOTAL_FREE_SECONDS - props.runningSeconds
					? TOTAL_FREE_SECONDS - props.runningSeconds
					: FREE_TIME_EXPIRING_POPUP_SECONDS
			setInitialFreeMinsExpiringTimeVal(seconds * 1000)
			freeMinuteExpiringPopup(seconds)
		}
	}, [props.showFreeMinutesExpiringPopup])

	useEffect(() => {
		/**
		 * Close any warning popup if open
		 */
		if (props.closeWarningPopup) {
			closeWarningPopup()
		}
	}, [props.closeWarningPopup])

	useEffect(() => {
		/**
		 * Variable will use to check whether screen shared or not
		 */
		screenshareStarted = props.isScreenShared ? true : false
	}, [props.isScreenShared])

	/**
	 * Function will open the "free meeting going to end" warning popup
	 * @params = ''
	 * @response : Will open the popup with end meeting and add card buttons
	 * @author : Karan
	 */
	const freeMinutesEndWarningPopup = (seconds) => {
		const initialSeconds = seconds ? seconds * 1000 : intialWarningTimeVal
		const key = 'FreeMinutesEnd'
		const clientMinutes = 6
		const btn = (
			<>
				<div key={initialSeconds} className="timer-area-popup">
					<Timer
						initialTime={initialSeconds}
						startImmediately={false}
						ref={props.warningTimerRef}
						direction="backward"
						checkpoints={[
							{
								time: 0,
								callback: () => {
									console.log('Time finished FreeMinutesEnd')
									notification.close('FreeMinutesEnd')
								},
							},
						]}
					>
						{() => (
							<div className="f-16">
								Your meeting is going to end in &nbsp;
								<Timer.Minutes
									formatValue={(value) => {
										value = value > 9 ? value : value < 1 ? '00' : '0' + value
										warningTime.minutes = value || 0
										return value || '0'
									}}
								/>
								:
								<Timer.Seconds
									formatValue={(value) => {
										value = value > 9 ? value : value < 1 ? '00' : '0' + value
										warningTime.seconds = value || 0
										return value || '0'
									}}
								/>
							</div>
						)}
					</Timer>
				</div>
				<br />
				<Button type="primary" className="acceptCharges btn " size="small" onClick={() => props.openAddCardModal(false)}>
					Continue meeting with charges
				</Button>
				<Button
					variant="danger"
					className="mt-2"
					size="small"
					onClick={() => {
						endMeeting(props.jitsiApi, props.job, props.user, props.socket)
					}}
				>
					End Meeting
				</Button>
			</>
		)

		setTimeout(function () {
			if (props.warningTimerRef && !props.job.meeting_pause) {
				props.warningTimerRef.current.start()
			}
		}, 1000)

		let body = `Oh no! Your Free ${clientMinutes} minute session is almost over. Please add your card in geeker to continue the meeting`
		browserNotificationHandler(body)
		notification.info({
			key,
			duration: null,
			btn,
			message: `Oh no! Your Free ${clientMinutes} minute session is almost over. Would you like to continue?`,
			description: '',
		})
	}

	/**
	 * Function will open the "last minute" warning popup
	 * @params = ''
	 * @response : Will open the last minute warning popup with end meeting and add card buttons
	 * @author : Karan
	 */
	const lastMinuteWarningPopup = (seconds) => {
		notification.close('FreeMinutesEnd')
		const initialSeconds = seconds ? seconds * 1000 : initialLastMinuteTimeVal
		const key = 'LastMinuteWarning'

		const btn = (
			<>
				<div key={initialSeconds} className="timer-area-popup">
					<Timer
						initialTime={initialSeconds}
						startImmediately={false}
						ref={props.lastMinuteTimerRef}
						direction="backward"
						checkpoints={[
							{
								time: 0,
								callback: () => {
									console.log('Time finished LastMinuteWarning')
									notification.close('LastMinuteWarning')
								},
							},
						]}
					>
						{() => (
							<div className="f-16">
								Your meeting is going to end in &nbsp;
								<Timer.Minutes
									formatValue={(value) => {
										value = value > 9 ? value : value < 1 ? '00' : '0' + value
										warningTime.minutes = value || 0
										return value || '0'
									}}
								/>
								:
								<Timer.Seconds
									formatValue={(value) => {
										value = value > 9 ? value : value < 1 ? '00' : '0' + value
										warningTime.seconds = value || 0
										return value || '0'
									}}
								/>
							</div>
						)}
					</Timer>
				</div>
				<br />
				<Button type="primary" className="acceptCharges btn" size="small" onClick={() => props.openAddCardModal(false)}>
					Continue meeting with charges
				</Button>
				<Button
					variant="danger"
					className="mt-2"
					size="small"
					onClick={() => {
						endMeeting(props.jitsiApi, props.job, props.user, props.socket)
					}}
				>
					End Meeting
				</Button>
			</>
		)

		setTimeout(function () {
			if (props.lastMinuteTimerRef && !props.job.meeting_pause) {
				props.lastMinuteTimerRef.current.start()
			}
		}, 1000)
		let body = 'Last Minute Remaning'
		browserNotificationHandler(body)
		notification.info({
			key,
			duration: null,
			btn,
			message: 'Last Minute Remaning',
			description: '',
		})
	}

	/**
	 * Function will open the free minute expiring popup. Suppose if customer already have card, but still we will warn him.
	 * @params = ''
	 * @response : Will open the free minute expiring popup
	 * @author : Karan
	 */
	const freeMinuteExpiringPopup = (seconds) => {
		const initialSeconds = seconds ? seconds * 1000 : initialFreeMinsExpiringTimeVal
		const key = 'FreeMinuteExpiring'

		const btn = (
			<>
				<div key={initialSeconds} className="timer-area-popup">
					<Timer
						initialTime={initialSeconds}
						startImmediately={false}
						ref={props.freeMinuteExpireTimerRef}
						direction="backward"
						checkpoints={[
							{
								time: 0,
								callback: () => {
									console.log('Time finished FreeMinuteExpiring')
									notification.close('FreeMinuteExpiring')
								},
							},
						]}
					>
						{() => (
							<div className="f-16">
								Your paid meeting time will be start after &nbsp;
								<Timer.Minutes
									formatValue={(value) => {
										value = value > 9 ? value : value < 1 ? '00' : '0' + value
										warningTime.minutes = value || 0
										return value || '0'
									}}
								/>
								:
								<Timer.Seconds
									formatValue={(value) => {
										value = value > 9 ? value : value < 1 ? '00' : '0' + value
										warningTime.seconds = value || 0
										return value || '0'
									}}
								/>
							</div>
						)}
					</Timer>
				</div>
			</>
		)

		setTimeout(function () {
			if (props.freeMinuteExpireTimerRef && !props.job.meeting_pause) {
				props.freeMinuteExpireTimerRef.current.start()
			}
		}, 1000)
		let body = 'Your free minutes are expiring'
		// browserNotificationHandler(body)
		notification.info({
			key,
			duration: null,
			btn,
			message: 'Your free minutes are expiring',
			description: '',
		})
	}

	/**
	 * Function will close the "last minute" warning popup
	 * @params = ''
	 * @response : Will close the last minute warning popup
	 * @author : Karan
	 */
	const closeWarningPopup = () => {
		notification.close('FreeMinutesEnd')
		notification.close('LastMinuteWarning')
		notification.close('FreeMinuteExpiring')
	}

	return <></>
}

export default FreeMinutesEndPopup
