import apiClient from './index'

/* Commented by @Vinit on 21-12-2023 since it's not in use */
/**
 * update business details by id
 * @params : req,res,next
 * @response : returns a json object for the matching query
 * @author :Kartar Singh
 **/
// export async function updateBusinessDetails(id,data) {
//     return apiClient
//         .post(`/business-details/update/${id}`, data)
//         .then(response => {
//             if (response) {
//                 return response.data;
//             }
//             return Promise.reject();
//         })

// }

/**
 * Retrieve business details by id
 * @params : req,res,next
 * @response : returns a json object for the matching query
 * @author :Kartar Singh
 **/
export async function getBusinessDetails(id, cuId) {
	return apiClient.get(`/business-details/${id}?cuId=${cuId}`).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
