import React, { useState, useEffect } from 'react'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import { Modal, Checkbox } from 'antd'
import FileTable from './FileTable';
import FileUploadForm from './FileUploadForm';

const UploadFileModal = ({job,user,setUploadFiles,uploadFiles,files, setFiles,socket }) => {
	
	return (

	<Modal
	title={'List of files uploaded'}
	visible={uploadFiles}
	width="60%"
	className="rejectJobModal custom-modal"
	footer={null}
	onCancel={() => setUploadFiles(false)}
	
>	<Col>
	<Row className="transfer-call-outer">
		<FileTable user={user} files={files} job={job} setFiles={setFiles} socket={socket}/>
		
	</Row>
	<hr className='custom-hr' />
	<Row><FileUploadForm user={user} job={job} setFiles={setFiles} socket={socket}/></Row>
	</Col>
</Modal>
);
}

export default UploadFileModal
