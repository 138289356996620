import React, { useEffect, useState } from 'react'
import '../../css/style.css'
import { Modal } from 'antd'
import { Row, Col, Button, Form } from 'react-bootstrap'
let participantArr = []
const TechnicianKickOutControls = (props) => {
	const [isDisable, setIsDisable] = useState(true)
	useEffect(() => {
		if (participantArr.length > 0) {
			setIsDisable(false)
		} else {
			setIsDisable(true)
		}
	}, [props.addKickparticpant])

	const addParticipant = (evt) => {
		participantArr = [...props.addKickparticpant]
		let res = participantArr.includes(evt.target.value)

		if (res) {
			let uncheckParticipant = participantArr.indexOf(evt.target.value)
			participantArr.splice(uncheckParticipant, 1)
		} else {
			participantArr.push(evt.target.value)
		}
		props.setAddKickparticpant(participantArr)
	}

	return (
		<>
			<Modal
				title={'Kick Out Participant'}
				visible={props.showKickOut}
				width={'fit-content'}
				maskClosable={false}
				onCancel={() => {
					props.setshowKickOut(false)
					participantArr.splice(0, participantArr.length)
				}}
				footer={
					props.showParticipant.length === 0
						? null
						: [
								<Button
									className="btn app-btn app-btn-light-blue app-btn-small declne-job-btn"
									onClick={() => {
										participantArr.splice(0, participantArr.length)
										setIsDisable(true)
										props.setshowKickOut(false)
									}}
								>
									<span></span>Cancel
								</Button>,

								<Button
									disabled={isDisable}
									className={'btn app-btn app-btn-small declne-job-btn ' + (isDisable ? 'participantBtn' : '')}
									onClick={async () => {
										await props.handleParticipantKickOut(props.addKickparticpant)
										props.setshowKickOut(false)
										participantArr.splice(0, participantArr.length)
									}}
								>
									<span></span>Proceed
								</Button>,
						  ]
				}
			>
				<Row>
					{props.showParticipant.map((e, i) => {
						return (
							<>
								<>
									<Col md={8}>
										<label className="h5" htmlFor={`checkthis${i}`}>
											{e.displayName ? e.displayName : e.formattedDisplayName}
										</label>
									</Col>
									<Col md={4} className="d-flex justify-content-center align-items-center">
										<Form.Check.Input
											onChange={addParticipant}
											id={`checkthis${i}`}
											style={{ width: '25px', height: '25px' }}
											value={e.participantId}
										/>
									</Col>
								</>
							</>
						)
					})}
					{props.showParticipant.length === 0 && (
						<Col>
							<h4>No participant in meeting</h4>
						</Col>
					)}
				</Row>
			</Modal>
		</>
	)
}

export default TechnicianKickOutControls
