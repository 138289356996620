import apiClient from './index'

export async function updateTechnician(technicianId, data) {
	return apiClient.put(`/technicians/${technicianId}`, data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
