import React, { useState, useEffect } from 'react'
import { Modal, Table, Pagination } from 'antd'
import { findJobByParams } from '../../api/meeting.api'
import moment from 'moment'
import { MAIN_APP_URL } from '../../constants'

const CustomerHistoryModel = (props) => {
	const [allJobs, setAllJobs] = useState([])
	const [totalJobs, setTotalJobs] = useState(1)
	const [currentPage, setCurrentPage] = useState(1)
	const locale = {
		emptyText: 'No past job found.',
	}

	/**
	 * Get all customer completed jobs first time and when job update
	 * @param {*} filter
	 * @param {*} pagination
	 * @returns list completed job of customer
	 * @author Ridhima
	 */
	useEffect(() => {
		if (props.job && props.job.id) {
			const filter_dict = {}
			// filter_dict['customer'] = props.job.customer.id
			// filter_dict['status'] = 'Completed'
			filter_dict['jobId'] = props.job.id
			filter_dict['cuId'] = props.user?.id
			callFetchJobs(filter_dict, { page: 1, pageSize: 10 })
		}
	}, [props.job])

	/**
	 * get customer jobs with pagination
	 * @param {*} filter
	 * @param {*} pagination
	 * @returns list completed job of customer
	 * @author Ridhima
	 */
	const callFetchJobs = async (filter, pagination = { page: 1, pageSize: 10 }) => {
		const res = await findJobByParams(filter, pagination)
		if (res !== undefined) {
			const all_data = [...res.jobs.data]
			if (props.user.userType === 'technician' && all_data) {
				for (let i = 0; i <= all_data.length - 1; i++) {
					if (all_data[i]['tech_declined_ids'] && all_data[i]['tech_declined_ids'].includes(props.job.technician.id)) {
						all_data[i]['status'] = 'Declined by customer'
					}
				}
			}
			setAllJobs(all_data)
			setTotalJobs(res.totalPages)
			return res.jobs
		} else {
			return []
		}
	}

	/**
	 * job history detail page
	 * @param {*} e
	 * @returns back to winkit version one
	 * @author Ridhima
	 */
	const pushToJobDetail = (e) => {
		const currentJobId = e.currentTarget.name
		const detailJobDetail = `${MAIN_APP_URL}/job-details?jobID=${currentJobId}&type=noapply&from=customerhistory`
		window.open(detailJobDetail, '_blank').focus()
	}

	/**
	 * selected page record
	 * @param {*} page
	 * @param {*} pageSize
	 * @returns fetch job of customer by selectd page
	 * @author Ridhima
	 */
	const handlePagination = async (page, pageSize) => {
		setCurrentPage(page)
		const pagination = { page: page, pageSize: pageSize }
		// const query = { customer: props.job.customer.id, status: 'Completed', cuId : props.user.id }
		const query = { jobId: props.job.id, cuId: props.user?.id }
		callFetchJobs(query, pagination)
	}

	/**
	 * set table columns and there records
	 * @author Ridhima
	 */

	const columns = [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			render: function DateFormat({ text }) {
				return <span> {moment(text).format('MM-DD-YYYY')}</span>
			},
		},
		{
			title: 'Software',
			dataIndex: 'software',
			render: (text, record) => <>{record && record.software ? record.software.name : ''}</>,
		},

		{
			title: 'Issue Desc',
			dataIndex: 'issueDescription',
			width: '30%',
			render: (text) => (
				<p padding="10px 5px" title={text} className="issue-description">
					{text.length > 100 ? text.substring(0, 100) + '...' : text}
				</p>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
		},
		{
			title: 'Tech',
			dataIndex: 'technician',
			render: (text, record) => (
				<>
					{record && record.technician && record.technician.user
						? record.technician.user.firstName + ' ' + record.technician.user.lastName
						: 'NA'}
				</>
			),
		},
		{
			title: 'Action',
			dataIndex: 'id',
			render: (text, record) => (
				<>
					{
						<span>
							<button className="details-btn-history" onClick={pushToJobDetail} name={record.id}>
								<span></span>Details
							</button>
						</span>
					}
				</>
			),
		},
	]

	return (
		<Modal
			title="Customer history"
			centered
			visible={props.customerHistoryVisible}
			onOk={() => props.setCustomerHistoryVisible(false)}
			onCancel={() => props.setCustomerHistoryVisible(false)}
			width={1000}
			className="rejectJobModal"
			footer={null}
		>
			<Table
				locale={locale}
				dataSource={allJobs}
				columns={columns}
				rowKey="id"
				className="jobsdetail-table"
				pagination={false}
				id="ThemeLight"
			/>
			{totalJobs !== 0 && (
				<Pagination
					style={{ textAlign: 'right', ',marginRight': '40px', marginTop: '20px' }}
					current={currentPage}
					onChange={handlePagination}
					total={totalJobs}
				/>
			)}
		</Modal>
	)
}
export default CustomerHistoryModel
