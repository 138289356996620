var CryptoJS = require('crypto-js')

const formattedPrivateKey = () => {
	const key = process.env.REACT_APP_KKJ10
	// console.log("formattedPrivateKey", key);
	const startIndex = key.indexOf('EQ')
	const endIndex = key.lastIndexOf('iA')

	const result = key.substring(startIndex, endIndex + 2)
	// console.log("formattedPrivateKey result", result);
	return result
}

export const decryptStripeKey = (data) => {
	const key = formattedPrivateKey()
	const bytes = CryptoJS.AES.decrypt(String(data), key)
	const decryptedRes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	return decryptedRes
}
