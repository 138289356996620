import { Tabs } from 'antd'
import Styled from 'styled-components'

const { TabPane } = Tabs
const DashboardTab = Styled(Tabs)`
   
`
const DashboardTabPane = Styled(TabPane)`
  
`

export { DashboardTab, DashboardTabPane }
