import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Modal } from 'antd'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { updateJob } from '../../api/meeting.api'
import Loader from './Loader'
import { openNotificationWithIcon } from '../../utils'
import LongJobDetails from './LongJobDetails'
import mixpanel from 'mixpanel-browser'

const CustomerSidebar = (props) => {
	const showSidebarLoader = false
	const [waitingForTechNotes, setWaitingForTechNotes] = useState(true)
	const [alertMessageShow, setAlertMessageShow] = useState(false)
	const [declineMessageShow, setDeclineMessageShow] = useState(false)
	const [emptyNotesAlert, setEmptyNotesAlert] = useState(false)
	const [allNotesSelected, setAllNotesSelected] = useState(false)
	const [notesSaveLoader, setNotesSaveLoader] = useState(false)
	const [notesDeclineLoader, setNotesDeclineLoader] = useState(false)
	const [notesConfirmed, setNotesConfirmed] = useState(false)
	const [issuesList, setIssuesList] = useState([])
	const [confirmedIssuesList, setConfirmedIssuesList] = useState([])
	//const [meetingJob, setMeetingJob] = useState({})
	const [notesDeclined, setNotesDeclined] = useState(false)

	useEffect(() => {
		if (props.job && props.job.id) {
			let issuesListArr = []
			if (props.job.allNotes && props.job.allNotes.trim().length > 0 && props.job.technicianSubmitNotes) {
				setWaitingForTechNotes(false)
				issuesListArr = props.job.allNotes.split('|SEP|')
				setIssuesList(issuesListArr)
			}
			if (props.job.customerConfirmedNotes) {
				setNotesConfirmed(true)
			}
			if (props.job.customerDeclinedNotes) {
				setDeclineMessageShow(true)
				setNotesDeclined(true)
			}
			if (props.job.confirmedNotes && props.job.confirmedNotes.trim().length > 0 && props.job.customerConfirmedNotes) {
				const confirmedIssuesListArr = props.job.confirmedNotes.split('|SEP|')
				setConfirmedIssuesList(confirmedIssuesListArr)
				if (issuesListArr.length === confirmedIssuesListArr.length) {
					setAllNotesSelected(true)
				}
			}
		}
	}, [props.job])

	useEffect(() => {
		if (props.socket) {
			props.socket.on('JobNotes:transfer', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id && socketData.allNotes.trim().length > 0) {
					const issuesListArr = socketData.allNotes.split('|SEP|')
					setIssuesList(issuesListArr)
					setWaitingForTechNotes(false)
					setNotesConfirmed(false)
					setNotesDeclined(false)
					setDeclineMessageShow(false)
					setEmptyNotesAlert(false)
					setConfirmedIssuesList([])
				}
			})
		}
	}, [props.socket])

	/**
	 * Check/Uncheck note handler
	 * @params = e (checkbox element)
	 * @response : Will set note as Checked/Unchecked
	 * @author : Karan
	 */
	const issueChecked = (e) => {
		if (e.target.value === 'all') {
			if (e.target.checked) {
				setAllNotesSelected(true)
				setConfirmedIssuesList(issuesList)
			} else {
				setAllNotesSelected(false)
				setConfirmedIssuesList([])
			}
		} else {
			setAllNotesSelected(false)
			const confirmedIssuesListArr = [...confirmedIssuesList]
			const idx = e.target.value
			if (e.target.checked) {
				confirmedIssuesListArr.push(issuesList[idx])
			} else {
				const findIdx = confirmedIssuesListArr.indexOf(issuesList[idx])
				confirmedIssuesListArr.splice(findIdx, 1)
			}
			setConfirmedIssuesList(confirmedIssuesListArr)

			if (confirmedIssuesListArr.length === issuesList.length) {
				setAllNotesSelected(true)
			}
		}
	}

	/**
	 * Open confirm notes modal
	 * @params = ''
	 * @response : Will open modal with notes confirmation message
	 * @author : Karan
	 */
	const confirmNotes = async () => {
		setEmptyNotesAlert(false)
		if (confirmedIssuesList.length === 0) {
			setEmptyNotesAlert(true)
		} else {
			Modal.confirm({
				title: 'By selecting “continue” you confirm we’ve understood and summarized your issue correctly. You will not be able to request support for additional issues during this session.',
				okText: 'Continue',
				cancelText: 'Go back',
				className: 'app-confirm-modal',
				onOk() {
					confirmNotesSubmit()
				},
			})
		}
	}

	/**
	 * Submit notes confirmation
	 * @params = ''
	 * @response : Will confirmed the selected notes
	 * @author : Karan
	 */
	const confirmNotesSubmit = async () => {
		setNotesSaveLoader(true)
		confirmedIssuesList.sort(function (a, b) {
			return issuesList.indexOf(a) - issuesList.indexOf(b)
		})

		window.confirmNotesSubmit = setTimeout(async function () {
			const confirmedNotes = confirmedIssuesList.join('|SEP|')
			const dataToUpdate = {
				confirmedNotes: confirmedNotes,
				customerConfirmedNotes: true,
			}
			const updateStatus = await updateJob(props.job.id, {
				dataToUpdate: dataToUpdate,
				cuId: props?.user?.id,
			})

			if (updateStatus && updateStatus.success) {
				setAlertMessageShow(true)
				setNotesConfirmed(true)
				const data = {}
				data['jobId'] = props.job.id
				data['customerConfirmedNotes'] = true
				data['confirmedNotes'] = confirmedNotes
				data['customer'] = props.job.customer.id
				data['totalPaidJobs'] = props.totalPaidJobs
				props.socket.emit('JobNotes:confirmed', data)

				props.job.customerConfirmedNotes = true
				// mixpanel code
				mixpanel.identify(props.user.email)
				mixpanel.track('Customer - Confirmed the notes', { JobId: props.job.id })
				// mixpanel code
				//Start the meeting time
				// updateJobTime()

				window.noteSaveLoaderTimeOut = setTimeout(function () {
					setNotesSaveLoader(false)
					setAlertMessageShow(false)
				}, 1500)
				/*window.alertMessageTimeOut = setTimeout(function(){
                    setAlertMessageShow(false)
                },4000)*/
			} else {
				setNotesSaveLoader(false)
				openNotificationWithIcon('error', 'Error', 'Notes submission failed. Please try again.')
			}
		}, 1000)
	}

	/**
	 * Open decline notes modal
	 * @params = ''
	 * @response : Will confirmed the selected notes
	 * @author : Karan
	 */
	const declineNotes = async () => {
		Modal.confirm({
			title: 'We`ll send these notes back to technician to review it again. Do you want to proceed?',
			okText: 'Continue',
			cancelText: 'Cancel',
			className: 'app-confirm-modal',
			onOk() {
				declineNotesSubmit()
			},
		})
	}

	/**
	 * Decline notes
	 * @params = ''
	 * @response : Will decline the notes submitted by technician
	 * @author : Karan
	 */
	const declineNotesSubmit = async () => {
		setNotesDeclineLoader(true)
		const dataToUpdate = {
			customerDeclinedNotes: true,
			technicianSubmitNotes: false,
		}
		const updateStatus = await updateJob(props.job.id, {
			dataToUpdate: dataToUpdate,
			cuId: props?.user?.id,
		})
		if (updateStatus && updateStatus.success) {
			setDeclineMessageShow(true)
			setNotesDeclined(true)

			const data = {}
			data['jobId'] = props.job.id
			data['customerDeclinedNotes'] = true
			props.socket.emit('JobNotes:declined', data)
			// mixpanel code
			mixpanel.identify(props.user.email)
			mixpanel.track('Customer - declined the notes', { JobId: props.job.id })
			// mixpanel code
			setNotesDeclineLoader(false)
		} else {
			setNotesDeclineLoader(false)
			openNotificationWithIcon('error', 'Error', 'Decline notes failed. Please try again.')
		}
	}

	/**
	 * Start timer
	 * @params = ''
	 * @response : Will start the meeting timer
	 * @author : Karan
	 */
	/*async function updateJobTime(){
        const res = await JobApi.retrieveJob(jobId);
        if(!res.meeting_start_time){
            tiRef.current.start()
            let secremain = 120
            let className = `d-block label-total-value `
            var countDownTarget = new Date().getTime() + 1* 60 * 1000;
            let filter_dict = {}
            let lastInterval = false
            let additionalMinutes = minutesFreeForClient *60000 
            let customerId;
            if(job && job.customer && job.customer.id){
                customerId = job.customer.id
                
            }
            if(user && user.customer && user.customer.id){
                customerId = user.customer.id
                
            }

            setDisableContinueBtn(true)
            filter_dict['customer'] = customerId
            filter_dict['customerConfirmedNotes'] = true
            let startTimer = false
            const res = await JobService.findJobByParams(filter_dict,{ page:1,pageSize:1000 })
            
            if(res.jobs.totalCount <= 1)
            {
                const customer_info = await CustomerApi.retrieveCustomer(customerId);
                let haveCard = customer_info.stripe_id && customer_info.stripe_id != "" ? true : false
                console.log("haveCard ::::::::::: ",haveCard)
            }
            socket.emit('add-start-time-in-job', job);
        }
    }*/
	return (
		<Row>
			<Col xs={12} className="mt-3 side-menu-bar px-3">
				<Row>
					<Loader height="100%" className={showSidebarLoader ? 'loader-outer' : 'd-none'} />

					{/*{waitingForTechNotes && (
						<Col xs={12}>
							<h5 className="font-weight-bold">First, let’s confirm your issue</h5>
							<Progress percent={40} showInfo={false} />
						</Col>
					)}
					{jobDetailsUpdated && (
						<Alert variant="success" className="w-100">
							Technician updated the job details. You can see the updated information at bottom of meeting block.
						</Alert>
					)}
					{!waitingForTechNotes && (
						<>
							{alertMessageShow && (
								<Alert variant="success" className="w-100">
									Thanks for confirmation.
								</Alert>
							)}
							{declineMessageShow && (
								<Alert variant="danger" className="w-100">
									Notes are declined by you. Technician will review the notes again. Please wait for technician input.
								</Alert>
							)}
							{emptyNotesAlert && (
								<Alert variant="danger" className="w-100">
									Please select the issues you want to solve before confirm.
								</Alert>
							)}
							<Col xs={12}>
								{notesConfirmed ? (
									<h5 className="font-weight-bold">Confirmed Points :</h5>
								) : (
									<h5 className="font-weight-bold">Please confirm the issues listed :</h5>
								)}
							</Col>
							<Col xs={12} className="notes-outer">
								{issuesList.length > 0 && (
									<ul className="small-text p-0 m-0">
										{!notesConfirmed && (
											<li className="issue-list-item-with-check">
												<label className="m-0">
													<span className="issue-checkbox-outer">
														<input
															type="checkbox"
															value="all"
															className="issue-checkbox"
															onChange={issueChecked}
															disabled={props.job.customerConfirmedNotes}
															checked={allNotesSelected}
															id={'checkbox-issue-all'}
														/>
													</span>
													<span htmlFor={'checkbox-issue-all'} className="issue-text">
														<b>Select all</b>
													</span>
												</label>
											</li>
										)}
										{issuesList.map((i, idx) => {
											return (
												<li className="issue-list-item-with-check" key={'issue_item_' + idx}>
													<label>
														<span className="issue-checkbox-outer">
															<input
																type="checkbox"
																value={idx}
																className="issue-checkbox"
																onChange={issueChecked}
																disabled={props.job.customerConfirmedNotes}
																checked={confirmedIssuesList.indexOf(i) === -1 ? false : true}
																id={'checkbox-issue-' + idx}
															/>
														</span>
														<span htmlFor={'checkbox-issue-' + idx} className="issue-text">
															{i}
														</span>
													</label>
												</li>
											)
										})}
									</ul>
								)}
							</Col>
							{!notesConfirmed && !notesDeclined && (
								<>
									<Col xs={12}>
										<Button
											className={(notesSaveLoader ? 'disabled-btn' : '') + ' btn app-btn w-100 mt-3 notes-submit-btn'}
											onClick={confirmNotes}
											disabled={notesSaveLoader}
										>
											<span />
											{notesSaveLoader ? <Spin /> : <>Confirm</>}
										</Button>
									</Col>

									<Col xs={12}>
										<Button
											className={
												(notesDeclineLoader ? 'disabled-btn' : '') +
												' btn app-btn app-btn-transparent w-100 mt-3 notes-submit-btn'
											}
											onClick={declineNotes}
											disabled={notesDeclineLoader}
										>
											<span />
											{notesDeclineLoader ? <Spin /> : <>Decline</>}
										</Button>
									</Col>
								</>
							)}
						</>
					)}*/}

					<LongJobDetails
						job={props.job}
						socket={props.socket}
						user={props.user}
						setShowEndmeetingButton={props.setShowEndmeetingButton}
						newCardAdded={props.newCardAdded}
						openAddCardModal={props.openAddCardModal}
						setApproveClicked={props.setApproveClicked}
						approveClicked={props.approveClicked}
						setIntialTimeVal={props.setIntialTimeVal}
						haveSubMin={props.haveSubMin}
					/>
				</Row>
			</Col>
		</Row>
	)
}

export default CustomerSidebar
