import apiClient from './index'
import axios from 'axios'
import { TWILIO_CHAT_USERNAME, TWILIO_CHAT_PASSWORD } from '../constants/index'

/**
 *
 * @param {*} conversationId
 * @returns array of objects
 * @description : This API will get conversation if available returned it else create.
 * @author : Kartar Singh
 */
export async function fetchTwilioConversation(twilioData) {
	try {
		console.log('conversation ::::::id ::::', twilioData)
		let response = await apiClient.post(`/twilio-chat/fetchConversation`, twilioData)
		if (response) {
			console.log('responce from backend ::', response)
			const twilioData = response?.data
			if (twilioData != '') {
				return { twilioData: twilioData }
			} else {
				return { twilioData: response }
			}
		}
	} catch (err) {
		console.log('error in getTwilioAuthToken ::: ', err)
		return false
	}
}

/* Commented by @Vinit on 21-12-2023 since it's not in use */
/**
 *
 * @param {*} conversationId
 * @returns array of objects
 * @description : This API will update conversation if available  .
 * @author : Kartar Singh
 */
// export async function createTwilioConversation(jobId) {
//     try {
//         let response = await apiClient.post(`/twilio-chat/createConversation/${jobId}`);

//         if (response) {
//             console.log('responce from backend createTwilioConversation ::', response)
//             const twilioData = response?.data
//             if (twilioData != '') {
//                 return { twilioData: twilioData }
//             } else {
//                 return { twilioData: response }
//             }
//         }
//     }
//     catch (err) {
//         console.log("error in createTwilioConversation ::: ", err)
//         return false
//     }
// }

/**
 *
 * @param {*} data
 * @returns array of objects
 * @description : This API will add participant in the conversation  .
 * @author : Kartar Singh
 */
export async function addTwilioParticiants(data) {
	try {
		let response = await apiClient.post(`/twilio-chat/addParticipat`, data)
		if (response) {
			console.log('responce from backend 1::', response)
			const twilioData = response?.data
			if (twilioData != '') {
				return { twilioData: twilioData }
			} else {
				return { twilioData: response }
			}
		}
	} catch (err) {
		console.log('error in getTwilioAuthToken ::: ', err)
		return false
	}
}

/**
 *
 * @param {*} data
 * @returns image url
 * @description : This API will return image url if any media file is uploaded.
 * @author : Jagroop
 */
export async function geTwilioChatMediaDetails(data) {
	try {
		let response = await apiClient.post(`/twilio-chat/get-media-link`, data)

		// console.log("geTwilioChatMediaDetails", response?.data?.response?.links?.content_direct_temporary)
		if (response) {
			const twilioData = response?.data
			const temporaryImage = twilioData?.response?.links?.content_direct_temporary
			if (twilioData != '' && temporaryImage) {
				return temporaryImage
			} else {
				return ''
			}
		}
	} catch (err) {
		console.log('error in getTwilioAuthToken ::: ', err)
		return false
	}
}

/**
 *
 * @param {*} data
 * @returns array of objects
 * @description : This API will return the list of all participant in conversation   .
 * @author : Kartar Singh
 */
export async function twilioParticiantsList(data) {
	try {
		let response = await apiClient.post(`/twilio-chat/ParticipatList`, data)
		if (response) {
			console.log('responce from backend 1::', response)
			const twilioData = response?.data
			if (twilioData != '') {
				return { twilioData: twilioData }
			} else {
				return { twilioData: response }
			}
		}
	} catch (err) {
		console.log('error in getTwilioAuthToken ::: ', err)
		return false
	}
}

// /**
//  *
//  * @param {*} chatId
//  * @returns array of objects
//  * @description : This API will get all the messages if available returned it in the required format.
//  * @author : Jagroop
//  */
// export async function getTwilioChat(chatId, chatServiceSid) {
//     const URL = `https://conversations.twilio.com/v1/Conversations/${chatId}/Messages`;
//     const basicAuth = 'Basic ' + btoa(TWILIO_CHAT_USERNAME + ':' + TWILIO_CHAT_PASSWORD);
//     try {
//         const twilioChatResponse = await axios.get(URL, {
//             headers: { 'Authorization': basicAuth },
//         });
//         const twilioHistoryMessages = twilioChatResponse?.data && twilioChatResponse?.data?.messages?.length > 0
//         if (twilioHistoryMessages) {
//             const formattedResponse = await formatMessages(twilioChatResponse?.data?.messages, chatServiceSid);
//             return formattedResponse;
//         } else {
//             return [];
//         }
//     } catch (error) {
//         console.error("Error retrieving Twilio chat:", error);
//         return [];
//     }
// }

/**
 *
 * @param {*} chatId
 * @returns array of objects
 * @description : This API will update the conversation.
 * @author : Kartar Singh
 */
export async function updateTwilioConversation(sid) {
	const URL = `https://conversations.twilio.com/v1/Conversations/${sid}`
	const basicAuth = 'Basic ' + btoa(TWILIO_CHAT_USERNAME + ':' + TWILIO_CHAT_PASSWORD)
	try {
		const twilioChatResponse = await axios.delete(URL, {
			headers: { Authorization: basicAuth },
		})
		console.log('twilioChatResponse ::::::::', twilioChatResponse)
		// const twilioHistoryMessages = twilioChatResponse?.data && twilioChatResponse?.data?.messages?.length > 0
		// if (twilioHistoryMessages) {
		//     const formattedResponse = await formatMessages(twilioChatResponse?.data?.messages, chatServiceSid);
		//     return formattedResponse;
		// } else {
		//     return [];
		// }
	} catch (error) {
		console.error('Error retrieving Twilio chat:', error)
		return []
	}
}

// This function will format raw data of messages in the required format to show previous chat on screen
async function formatMessages(response, chatServiceSid) {
	const formattedMessages = []
	for (const message of response) {
		const { body, author, attributes, media } = message
		const { timeStamp, userName } = JSON.parse(attributes)
		let mediaImage = ''
		let content_type = ''
		let mediaName = ''
		let mediaDetails = {}
		if (media && media.length >= 0) {
			mediaDetails['chatServiceSid'] = chatServiceSid
			mediaDetails['mediaSid'] = media[0].sid
			mediaImage = await geTwilioChatMediaDetails({ chatServiceSid, mediaSid: media[0].sid })
			content_type = media[0].content_type
			mediaName = media[0].filename
		}
		formattedMessages.push({
			text: body,
			author,
			timeStamp,
			senderName: userName,
			imageUrl: mediaImage,
			content_type: content_type,
			mediaName: mediaName,
			mediaDetails: mediaDetails,
		})
	}

	return formattedMessages
}

// This function will convert urls to clickable if available in message
function urlifyTheMessage(text) {
	var urlRegex = /(https?:\/\/[^\s]+)/g
	return text.replace(urlRegex, function (url) {
		return '<a href="' + url + '">' + url + '</a>'
	})
}

export async function getTwilioChat(chatId, chatServiceSid, nextPageUrl) {
	console.log('chatId, chatServiceSid,nextPageUrl :::', chatId, chatServiceSid, nextPageUrl)
	const URL = nextPageUrl ? nextPageUrl : `https://conversations.twilio.com/v1/Conversations/${chatId}/Messages?Order=desc&PageSize=5`
	// const URL =  `https://conversations.twilio.com/v1/Conversations/${chatId}/Messages`;
	const basicAuth = 'Basic ' + btoa(TWILIO_CHAT_USERNAME + ':' + TWILIO_CHAT_PASSWORD)
	try {
		console.log('twilioChatResponse ::::1', URL)

		const twilioChatResponse = await axios.get(URL, {
			headers: { Authorization: basicAuth },
		})
		console.log('twilioChatResponse ::::2', twilioChatResponse)
		const twilioHistoryMessages = twilioChatResponse?.data && twilioChatResponse?.data?.messages?.length > 0
		const nextPageUrl = twilioChatResponse.data.meta.next_page_url
		if (twilioHistoryMessages) {
			const formattedResponse = await formatMessages(twilioChatResponse?.data?.messages, chatServiceSid)
			return { formattedResponse: formattedResponse, nextPageUrl: nextPageUrl }
		} else {
			return { formattedResponse: [], nextPageUrl: null }
		}
	} catch (error) {
		console.error('Error retrieving Twilio chat:', error)
		return { formattedResponse: [], nextPageUrl: null }
	}
}

export async function getTwilioChatDetails(params) {
	return apiClient.post(`/twilio-chat/getTwilioChatDetails/`, params).then((response) => {
		if (response) {
			// console.log('response ::::::::::getTwilioChatDetails:',response)
			return response.data
		}
		return Promise.reject()
	})
}
