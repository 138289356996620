import React, { useEffect, useState } from 'react'
import * as UserApi from '../../../api/user.api'
import * as businessApi from '../../../api/businessDetails.api'
import Loader from '../Loader'
import { Collapse } from 'antd'

const BusinessInfo = ({ job, user }) => {
	const [businessName, setBusinessName] = useState('')
	const [showLoader, setShowLoader] = useState(true)
	const [managerName, setManagerName] = useState('')
	const [managerEmail, setManagerEmail] = useState('')
	const [isBusinessInfoMessage, setIsBusinessInfoMessage] = useState(false)
	const [businessInfoMessage, setBusinessInfoMessage] = useState('')

	useEffect(() => {
		;(async () => {
			// console.log("My conosle for job in business info ", job, (job.customer.user.ownerId && job.customer.user.ownerId !== null))
			let simpleBusinessInfo
			if (job) {
				if (job.customer.user.ownerId && job.customer.user.ownerId !== null) {
					const ownerUserInfo = await UserApi.getUserByParam({ userId: job.customer.user.ownerId, cuId: user?.id })
					setBusinessName(ownerUserInfo.user.businessName)
					setManagerName(ownerUserInfo.user.firstName + ' ' + ownerUserInfo.user.lastName)
					setManagerEmail(ownerUserInfo.user.email)
					const businessData = await businessApi.getBusinessDetails(ownerUserInfo.user?.business_details, user?.id)
					if (businessData?.businessInfoMessageForTech) {
						simpleBusinessInfo = removeTags(businessData.businessInfoMessageForTech)
						console.log('Filtered business info', simpleBusinessInfo)
					}
					if (simpleBusinessInfo && simpleBusinessInfo !== '') {
						setIsBusinessInfoMessage(true)
						setBusinessInfoMessage(simpleBusinessInfo)
					}
					setShowLoader(false)
				} else {
					if (job?.customer?.user?.business_details) {
						const businessData = await businessApi.getBusinessDetails(job.customer.user.business_details, user?.id)
						setBusinessName(job.customer.user.businessName)
						setManagerName(job.customer.user.firstName + ' ' + job.customer.user.lastName)
						setManagerEmail(job.customer.user.email)
						if (businessData?.businessInfoMessageForTech) {
							simpleBusinessInfo = removeTags(businessData?.businessInfoMessageForTech)
							console.log('Filtered business info', simpleBusinessInfo)
						}
						if (simpleBusinessInfo && simpleBusinessInfo !== '') {
							setIsBusinessInfoMessage(true)
							setBusinessInfoMessage(simpleBusinessInfo)
						}
					} else {
						setBusinessName(job.customer.user.businessName)
						setManagerName(job.customer.user.firstName + ' ' + job.customer.user.lastName)
						setManagerEmail(job.customer.user.email)
					}
					setShowLoader(false)
				}
			}
		})()
	}, [])

	function removeTags(str) {
		if (str === null || str === '') {
			return false
		} else {
			str = str.toString()
			return str.replace(/(<([^>]+)>)/gi, '')
		}
	}

	const { Panel } = Collapse

	if (showLoader) return <Loader height="40%" />

	return (
		<Collapse className="business-info-class" defaultActiveKey={['1']}>
			<Panel header="Business Info" key="1" className="grey-background-imp py-2 px-2">
				<table className="business-info-table table">
					<tbody>
						<tr className="">
							<td colSpan="2">
								<div className="d-flex justify-content-center business-info-name">{businessName}</div>
							</td>
						</tr>
						<tr>
							<td>Manager </td>
							<td>
								{/* <a
									title="In order to generate issue of  disconnect meeting please click on this.[ For testing purpose only ]"
									href={`mailto:${managerEmail}`}
									className="admin-name"
								>
									{' '}
									{managerName}
								</a> */}
								{managerName}
							</td>
						</tr>

						{isBusinessInfoMessage && (
							<tr>
								<td>Business Info</td>
								<td>
									<div className="d-flex justify-content-center business-info-name">{businessInfoMessage}</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</Panel>
		</Collapse>
	)
}

export default BusinessInfo
