import socketClient from 'socket.io-client'
import { SERVER_BASE_URL } from '../constants'

/**
 * Function will create socketclient object
 * @params = ''
 * @response : Return socketclient object
 * @author : Karan
 */
export async function getSocketClient() {
	const socket = socketClient(`${SERVER_BASE_URL}`, {
		autoConnect: true,
		forceNew: true,
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 10000,
		reconnectionAttempts: 50,
	})

	return socket
}
