import apiClient from './index'

export async function getSoftwareList(params) {
	return apiClient.get('/software', { params }).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

/* Commented by @Vinit on 21-12-2023 since it's not in use */
// export async function getOtherSoftwareList(params) {
// 	return apiClient.get('/other-software', { params }).then((response) => {
// 		if (response) {
// 			return response.data
// 		}
// 		return Promise.reject()
// 	})
// }
